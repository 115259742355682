import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { CitiesEffects } from './+state/cities/cities.effects';
import { StoreModule } from '@ngrx/store';
import * as fromCities from './+state/cities/cities.reducer';
import { CitiesService } from './services/cities.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromCities.CITIES_FEATURE_KEY,
      fromCities.citiesReducer
    ),
    EffectsModule.forFeature([CitiesEffects]),
  ],
  providers: [CitiesService],
})
export class CitiesStoreModule {}
