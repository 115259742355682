import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsService } from '../permissions.service';

@Pipe({
  name: 'continentsByPermission',
})
export class ContinentsByPermissionPipe implements PipeTransform {
  constructor(private readonly permissionsService: PermissionsService) {}

  transform(value: any, continent: string): any[] {
    const permissions = this.permissionsService.getPermissions();
    const view = (permissions as Record<string, unknown>)?.['View'];
    if (view && typeof view === 'object') {
      const permittedContinents = view[continent as keyof typeof view];
      return value.filter(({ id }: any) =>
        Object.keys(permittedContinents).includes(id)
      );
    }
    return [];
  }
}
