<div class="location-panel">
  <div class="location-header">
    <ng-container *ngIf="list?.id; else createNew">
      <div class="header-title">
        <div translate="edit"></div>
        &nbsp;
        {{ list.listName }}
      </div>

      <firebird-web-settings-action-buttons
        [list]="$any(list)"
        (deleteList)="onListDelete($event)"
        (copyList)="onListCopy($event)"
      ></firebird-web-settings-action-buttons>
    </ng-container>
    <div class="main-buttons-container">
      <firebird-web-button
        [style]="'basic'"
        (click)="onClose()"
        labelKey="xclose"
      ></firebird-web-button>
      <button
        class="hidden-menu-trigger"
        #closeTrigger="matMenuTrigger"
        [matMenuTriggerFor]="closeMenu"
      ></button>
      <firebird-web-button
        iconName="save"
        [iconFirst]="true"
        [style]="'additional'"
        [labelKey]="'saveList' | translate"
        [disabled]="isSaving"
        (click)="onSave()"
      ></firebird-web-button>
    </div>
  </div>
  <div class="location-body">
    <!-- TODO: rename classes -->
    <div *ngIf="isErrorMsgVisible" class="error-msg-container">
      <firebird-web-icon
        name="alert-circle"
        width="20px"
        height="20px"
      ></firebird-web-icon>
      <div [translate]="getErrorMsg()"></div>
    </div>
    <div class="fields">
      <div class="label" translate="listName"></div>
      <div class="input">
        <input
          type="text"
          (keyup)="isNameAlreadyExists = false; isErrorMsgVisible = false"
          [(ngModel)]="list.listName"
          [placeholder]="'enterListName' | translate"
        />
      </div>
    </div>
    <div
      class="description"
      [innerHTML]="'createListDescription' | translate"
    ></div>
    <div class="locations-fields">
      <div class="available-locations">
        <div class="heading" translate="availableLocations"></div>
        <div [matMenuTriggerFor]="contMenu" class="continent-menu">
          {{ selectedContinent?.text || '' | titlecase }}
          <mat-icon>expand_more</mat-icon>
        </div>
      </div>
      <div class="selected-locations">
        <div class="heading" translate="selectedLocations"></div>
      </div>
    </div>
    <div class="drag-drop-fields">
      <div
        #regionsList="cdkDropList"
        cdkDropList
        class="box locations-grid available"
        (cdkDropListDropped)="dropBack($event)"
      >
        <mat-accordion
          *ngIf="regions$ | async as regionsData"
          togglePosition="before"
          multi
        >
          <mat-expansion-panel
            class="expansion-panel"
            *ngFor="let region of regionsData"
            (opened)="toggleExpansionPanelState(region.regionName)"
            (closed)="toggleExpansionPanelState(region.regionName)"
          >
            <mat-expansion-panel-header class="expansion-panel-header">
              <div>
                {{ region.regionName }}
              </div>
            </mat-expansion-panel-header>
            <div
              class="drag-drop-list"
              cdkDropList
              [cdkDropListConnectedTo]="[favoriteCitiesList]"
              #citiesList="cdkDropList"
              [cdkDropListData]="region.cities"
            >
              <ng-container *ngFor="let city of region.cities">
                <div
                  cdkDrag
                  class="city-item"
                  (cdkDragStarted)="dragStart(city)"
                  [cdkDragDisabled]="isFavorite(city)"
                  [ngClass]="{
                    'is-favorite': isFavorite(city),
                    'is-highlighted': isHighlighted(city)
                  }"
                  (click)="selectCity($event, city)"
                  (dblclick)="addToFavorites(city)"
                >
                  {{ city.displayName }}

                  <div *cdkDragPreview>
                    <ng-container
                      *ngIf="listsState.isSingleDrop; else multiDrop"
                    >
                      {{ city.displayName }}
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="add-remove">
        <div>
          <firebird-web-button
            [style]="'additional'"
            [labelKey]="'add' | translate"
            [ignoreHover]="true"
            (clickEvent)="addToFavorites()"
          ></firebird-web-button>
        </div>
        <div>
          <firebird-web-button
            [style]="'additional'"
            [ignoreHover]="true"
            [labelKey]="'remove' | translate"
            (clickEvent)="removeFromFavorites()"
          ></firebird-web-button>
        </div>
      </div>
      <div class="box locations-grid selected">
        <!-- <div class="heading" translate="selectedLocations"></div> -->
        <div
          #favoriteCitiesList="cdkDropList"
          cdkDropList
          [cdkDropListData]="favorite"
          [cdkDropListConnectedTo]="[regionsList]"
          class="drag-drop-list"
          (cdkDropListDropped)="drop($event)"
        >
          <div
            *ngFor="let city of favorite"
            cdkDrag
            class="favorite-city-item"
            [ngClass]="{
              'is-highlighted': isFavoriteHighlighted(city)
            }"
            (cdkDragStarted)="dragStart(city)"
            (click)="selectFavoriteCity($event, city)"
            (dblclick)="removeFromFavorites(city)"
          >
            {{ city.displayName }}

            <div *cdkDragPreview>
              <ng-container
                *ngIf="listsState.isSingleDropFavorite; else multiDropFavorite"
              >
                {{ city.displayName }}
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="drag-text" translate="dragText"></div>
    </div>
  </div>
</div>

<mat-menu #contMenu="matMenu">
  <ng-container *ngFor="let continent of continents$ | async">
    <div mat-menu-item>
      <div
        [hasViewPermission]="[continent.id || '']"
        (click)="changeContinent(continent)"
        [alignRight]="true"
        key="PoolSet"
      >
        {{ continent.text | titlecase }}
      </div>
    </div>
  </ng-container>
</mat-menu>

<mat-menu #closeMenu="matMenu" class="close-menu">
  <div class="close-menu-text-wrapper">
    You have unsaved changes. Would you like to save now?
  </div>
  <div class="close-menu-buttons-wrapper">
    <firebird-web-button
      [style]="'basic'"
      labelKey="xcancel"
      (click)="closeTrigger.closeMenu()"
    ></firebird-web-button>
    <firebird-web-button
      [style]="'basic'"
      labelKey="doNotSave"
      mat-dialog-close
    ></firebird-web-button>
    <firebird-web-button
      [disabled]="isSaving"
      [style]="'additional'"
      labelKey="saveChanges"
      (click)="onSave()"
    ></firebird-web-button>
  </div>
</mat-menu>

<ng-template #createNew>
  <div class="header-title" translate="createList"></div>
</ng-template>

<ng-template #multiDrop>
  <div
    *ngFor="let selectedCity of this.listsState.selectedCityList"
    class="drag-preview"
  >
    <div>
      {{ selectedCity?.displayName }}
    </div>
  </div>
</ng-template>

<ng-template #multiDropFavorite>
  <div
    *ngFor="let selectedCity of this.listsState.selectedFavoriteCityList"
    class="drag-preview"
  >
    <div>
      {{ selectedCity?.displayName }}
    </div>
  </div>
</ng-template>
