<div
  class="load-cell component"
  *ngIf="hasPopup; else withoutPopover"
  [class]="tableCellBgColor"
  [style]="style"
  [firebirdWebPopover]="{
    templateRef: popoverRef,
    data: { type: 'variation-one', trigger: 'mouseover' }
  }"
>
  {{ displayText | number }}
</div>

<ng-template #withoutPopover>
  <div class="load-cell component" [class]="tableCellBgColor" [style]="style">
    {{ displayText }}
  </div>
</ng-template>

<ng-template #popoverRef>
  <ng-container
    *ngIf="selectedForecastType === forecastTypeDiff; else forecastDiffMess"
  >
    <div class="popover-wrapper">% Forecast Differences: {{ capacity }} %</div>
  </ng-container>
  <ng-template #forecastDiffMess>
    <div class="popover-wrapper">
      Percentage of max demand: {{ capacity }} %
    </div>
  </ng-template>
</ng-template>
