import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'firebird-web-pagination-control',
  templateUrl: './pagination-control.component.html',
  styleUrls: ['./pagination-control.component.scss'],
})
export class PaginationControlComponent {
  /* INPUTS */
  @Input()
  public prevDisabled = false;

  @Input()
  public prevLabelKey!: string;

  @Input()
  public prevIconName!: string;

  @Input()
  public nextDisabled = false;

  @Input()
  public nextLabelKey!: string;

  @Input()
  public nextIconName!: string;

  /* OUTPUTS */
  @Output()
  public prevClickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  public nextClickEvent: EventEmitter<Event> = new EventEmitter<Event>();

  /* METHODS */
  public prevClicked($event: Event) {
    $event?.preventDefault();
    if (!this.prevDisabled) {
      this.prevClickEvent.emit($event);
    }
  }
  public nextClicked($event: Event) {
    $event?.preventDefault();
    if (!this.prevDisabled) {
      this.nextClickEvent.emit($event);
    }
  }
}
