import { Injectable } from '@angular/core';
import { LocationService } from '../../../../../services/src/lib/location.service';
import { map, Observable, of, tap } from 'rxjs';
import { isUndefined } from 'lodash';
import { CommonUtils } from '@firebird-web/shared-utils';
import { City, IContinent, IRegion } from '../location.interface';

@Injectable()
export class CityLocationSelectionService {
  private readonly _allCitiesOptionKey = 'All Cities';
  private cache = new Map<string, IRegion[] | City[][]>();

  constructor(private readonly locService: LocationService) {}

  public get allCitiesOptionKey(): string {
    return this._allCitiesOptionKey;
  }

  public getRegionList(continent: IContinent): Observable<IRegion[]> {
    const id = continent.id ?? '';
    const cachedRegionList = this.cache.get(id) as IRegion[] | undefined;

    if (!isUndefined(cachedRegionList)) {
      return of(cachedRegionList);
    }

    return this.locService.getRegionsList(id).pipe(
      map((regionList) => [
        {
          siteId: '',
          isEia: false,
          regionName: this.allCitiesOptionKey,
          regionCode: '',
        },
        ...regionList,
      ]),
      tap((regionList) => {
        this.cache.set(id, regionList);
      })
    );
  }

  public getCityList(
    continent: IContinent,
    region: string
  ): Observable<City[][]> {
    const id = continent.id ?? '';
    const cachedCityList = this.cache.get(
      this.generateCityListCompositeKey(id, region)
    ) as City[][] | undefined;

    if (!isUndefined(cachedCityList)) {
      return of(cachedCityList);
    }

    return this.locService.getCitiesByRegionAndContinent(region, id).pipe(
      map((cityList) =>
        CommonUtils.toChunksByColumnsQty(this.buildSortedCityList(cityList), 3)
      ),
      tap((cityList) => {
        this.cache.set(this.generateCityListCompositeKey(id, region), cityList);
      })
    );
  }

  public isCityListInCache(continent: string, region: string): boolean {
    return this.cache.has(this.generateCityListCompositeKey(continent, region));
  }

  public clearCache(): void {
    this.cache.clear();
  }

  private buildSortedCityList(cities: City[]): City[] {
    const aggregated = cities.filter(({ city }) => city.includes('agg'));
    const defaultCities = cities.filter(({ city }) => !city.includes('agg'));

    return [
      ...CommonUtils.sortCityByName(aggregated, 'city'),
      ...CommonUtils.sortCityByName(defaultCities, 'city'),
    ];
  }

  private generateCityListCompositeKey(
    continent: string,
    region: string
  ): string {
    return `${continent}:${region}`;
  }
}
