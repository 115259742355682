import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'units',
})
export class UnitsPipe implements PipeTransform {
  transform(value: any, args: any) {
    // Dont append the unit symbol if there is no definite value
    if (value === 'n/a' || value === null || value === undefined) {
      return value;
    }
    switch (args) {
      case '°':
        return `${value}°`;
      case '%':
        return `${value}%`;
      case 'in':
        return `${value}in`;
      case 'mm':
        return `${value}mm`;
      default:
        return `${value}`;
    }
  }
}
