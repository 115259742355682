import { Component, Input } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'firebird-web-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
  @Input()
  public name!: string;

  @Input()
  public width!: string;

  @Input()
  public height!: string;

  @Input()
  public fillColor!: string;

  @Input()
  public strokeColor!: string;

  @Input()
  public isActive!: boolean;

  /**
   * alphabetically ordered list of icons
   */
  private icons: string[] = [
    '1to15',
    'add',
    'add-circle',
    'affordance',
    'alarm-bell',
    'alert-triangle',
    'alert-circle',
    'alert-circle-filled',
    'analytics-graph',
    'analytics-graph-inactive',
    'app-window-graph',
    'arrow-down',
    'arrow-up',
    'arrow-down-black',
    'export-round-button',
    'arrow-left',
    'arrow-right',
    'ASIA',
    'AUS',
    'bookmarks',
    'back-arrow',
    'buildings',
    'bulb',
    'button-refresh-arrows-1',
    'button-refresh-arrows-2',
    'drag-handle',
    'changes-saved',
    'check',
    'chevron-down',
    'check-circled',
    'chevron-left',
    'chevron-left-black',
    'chevron-right',
    'cog',
    'close',
    'content-paper-edit',
    'controls-play-empty',
    'controls-play-filled',
    'cross',
    'data-transfer-circle',
    'data-transfer-circle-white',
    'download-bottom',
    'ellipsis',
    'EUR',
    'edit',
    'file-csv',
    'filter',
    'gauge-dashboard',
    'graph-stats-square',
    'grey-cog',
    'graph-stats-square-active',
    'group',
    'group-green',
    'home',
    'hurricane',
    'information-circle',
    'information-circle-no-color',
    'information-circle-red',
    'keyboard-arrow-right',
    'keyboard-arrow-right-active',
    'launchnew-window',
    'layers-back',
    'layers-grid-add-1',
    'layers-grid-add-1-inactive',
    'layers-grid-add-1-active',
    'layers-grid-add-2',
    'layers-grid-add-2-active',
    'layout-module',
    'layout-module-active',
    'legend-hover',
    'left-legend',
    'right-legend',
    'light-bulb-shine',
    'logo',
    'maps-pin',
    'maps-pin-black',
    'MEX',
    'natural-disaster-hurricane-map',
    'navigation-arrows-left-empty',
    'navigation-arrows-left-filled',
    'navigation-arrows-right-empty',
    'navigation-arrows-right-filled',
    'network-search',
    'network-search-active',
    'NA',
    'notes-book-text',
    'office-file-xls',
    'onboarding-analyst',
    'onboarding-gas-trader',
    'onboarding-power-trader',
    'onboarding-weather-expert',
    'onboarding-welcome',
    'optimization-rocket-growth',
    'optimization-rocket-growth-active',
    'pause-empty',
    'pause-filled',
    'pin',
    'search',
    'share',
    'single-man',
    'subtract',
    'synchronize-arrow-clock',
    'synchronize-arrow-clock-active',
    'tampa-fl',
    'tick',
    'twitter',
    'start-model',
    'delete',
    'copy',
    'save',
    'send',
    'view-off',
    'maps',
    'external-button',
    'weather-blast',
    'vector-arrow',
    'menu',
    'vector',
    'thick-line',
    'line',
    'lock',
    'two-thick-dashed-line',
    'two-dashed-line',
    'thick-dashed-line',
    'large-dashed-line',
    'vertical-dashed-line',
    'model-pause',
    'short-dashed-line',
    'circle-line',
    'seasonal',
    'subseasonal',
    'model-rewind',
    'tropical',
    'square-line',
    'question-mark',
    'twitter-filled',
    'linkedin',
    'run-dates-info',
    'copy-text',
    'medium-line',
    'three-dashes-line',
    'printer-outline',
    'spread-line',
    'small-lock',
    'small-dash-line',
    'dashed-line-medium',
    'dashed-line-thin',
    'dashed-line-wide',
    'circle-alert-icon',
    'check-circle',
    'copy-key',
    'map',
    'table-rows',
  ];

  public constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    for (const icon of this.icons) {
      this.iconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${icon}.svg`
        )
      );
    }
  }
}
