<span class="input-pwd">
  <label class="label" [for]="id" *ngIf="id && labelKey" translate>{{
    labelKey
  }}</label>
  <span class="field-wrapper">
    <input
      class="form-field"
      [type]="showPassword ? 'text' : 'password'"
      [attr.formControlName]="formControlName"
      [attr.maxlength]="maxlength"
      [attr.id]="id"
      [attr.name]="name"
      [attr.placeholder]="placeholderKey | translate"
      [attr.disabled]="disabled"
    />
    <span class="icon" (click)="togglePasswordDisplay()">
      <firebird-web-icon
        name="view-off"
        width="14px"
        height="14px"
      ></firebird-web-icon>
    </span>
  </span>
</span>
