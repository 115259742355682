<div class="pagination-control component">
  <!-- PREV BUTTON -->
  <button
    mat-button
    [disabled]="prevDisabled"
    class="prev-btn"
    (click)="prevClicked($event)"
  >
    <span class="icon" *ngIf="prevIconName">
      <firebird-web-icon [name]="prevIconName" width="7px"></firebird-web-icon>
    </span>
    <span *ngIf="prevLabelKey" class="label" [translate]="prevLabelKey"></span>
  </button>

  <!-- NEXT BUTTON -->
  <button
    mat-button
    [disabled]="nextDisabled"
    class="next-btn"
    (click)="nextClicked($event)"
  >
    <span *ngIf="nextLabelKey" class="label" [translate]="nextLabelKey"></span>
    <span class="icon" *ngIf="nextIconName"
      ><firebird-web-icon [name]="nextIconName" width="7px"></firebird-web-icon
    ></span>
  </button>
</div>
