import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  RUN_DATES_FEATURE_KEY,
  RunDatesState,
  runDatesAdapter,
} from './run-dates.reducer';
import { RunDatesEntity } from './run-dates.models';
import { first } from 'lodash';

// Lookup the 'RunDates' feature state managed by NgRx
export const getRunDatesState = createFeatureSelector<RunDatesState>(
  RUN_DATES_FEATURE_KEY
);

const { selectAll, selectEntities } = runDatesAdapter.getSelectors();

export const getRunDatesLoaded = createSelector(
  getRunDatesState,
  (state: RunDatesState) => state.loaded
);

export const getRunDatesError = createSelector(
  getRunDatesState,
  (state: RunDatesState) => state.error
);

export const getAllRunDates = createSelector(
  getRunDatesState,
  (state: RunDatesState) => selectAll(state)
);

export const getFirstPrimaryRunDate = createSelector(
  getAllRunDates,
  (state: RunDatesEntity[]) =>
    state?.find((obj) => obj.type === 'PRIMARY')?.label
);

export const getRunDatesEntities = createSelector(
  getRunDatesState,
  (state: RunDatesState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getRunDatesState,
  (state: RunDatesState) => state.selectedId
);

export const getFirstRunDate = createSelector(
  getAllRunDates,
  (state: RunDatesEntity[]) => first(state)
);

export const getSelected = createSelector(
  getRunDatesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
