import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  ChartRange,
  IDemandPanelState,
  ILoadWidgetLegend,
  IRangeSelectorOption,
  LoadRegion,
} from '../../interfaces';
import {
  defaultDemandLocation,
  IWidgetSummaryPanelData,
  WidgetTypes,
  RANGE_SELECTOR_OPTIONS,
} from '../../constants';
import { BehaviorSubject, filter, first, first as rxjsFirst } from 'rxjs';
import { first as lodashFirst, tap } from 'lodash';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import { MatSelectChange } from '@angular/material/select';
import { LoadDataService } from 'libs/load/src/lib/services/load-data.service';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { dateFormat } from 'highcharts';
import {
  ErcotLoadLegendsToKeep,
  LoadLegendsToKeep,
} from '../../containers/load-graph-widget/load-graph-widget.constants';
@Component({
  selector: 'firebird-web-demand-panel',
  templateUrl: './demand-panel.component.html',
  styleUrls: ['./demand-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemandPanelComponent implements OnInit {
  public demandPanelState: IDemandPanelState = {
    selectedRegion: defaultDemandLocation,
    selectedSubzone: defaultDemandLocation.subZones[0],
    regions: [],
    selectedSubZones: defaultDemandLocation.subZones,
  };
  private chartOptions: Highcharts.Options;
  public modifiedLegends$ = new BehaviorSubject<ILoadWidgetLegend[]>([]);
  public rangeOptions = RANGE_SELECTOR_OPTIONS;
  public currentRangeOption = this.rangeOptions[1];
  public legends: ILoadWidgetLegend[];
  public isTriggeredByRegionChange = false;
  public hasNetLoadPermission = false;
  public isLoading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private dataService: LoadDataService,
    private dialogRef: MatDialogRef<DemandPanelComponent>,
    private dashboardService: DashboardService,
    private cdr: ChangeDetectorRef,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.populateRegions();
    if (this.data.isNew) {
      this.generateChartOptions();
    } else {
      this.modifiedLegends$.next(this.data.widgetConfig.loadWidgetLegends);
    }
  }
  public get isErcot(): boolean {
    return this.demandPanelState.selectedRegion.locationId === 'ERCOT';
  }
  public regionChange(region: MatSelectChange): void {
    const selectedRegion = this.findSelectedRegion(region.value);
    const { subZones: selectedSubZones } = selectedRegion;

    this.demandPanelState = {
      ...this.demandPanelState,
      selectedRegion,
      selectedSubZones,
      selectedSubzone: lodashFirst(selectedSubZones) as LoadRegion,
    };
    this.isTriggeredByRegionChange = true;
    this.modifiedLegends$.next([]);
    this.generateChartOptions();
  }

  public legendToggled(event: { legends: ILoadWidgetLegend[] }): void {
    this.modifiedLegends$.next(event.legends);
  }
  public subZoneChange(subzone: MatSelectChange): void {
    this.demandPanelState.selectedSubzone =
      this.demandPanelState.selectedSubZones.find(
        (subZone) => subZone.locationId === subzone.value
      ) ?? (lodashFirst(this.demandPanelState.selectedSubZones) as LoadRegion);
    this.isTriggeredByRegionChange = true;
    this.modifiedLegends$.next([]);
    this.generateChartOptions();
  }

  public onSave(): void {
    this.dialogRef.close({
      region: this.demandPanelState.selectedRegion,
      subzone: this.demandPanelState.selectedSubzone,
      legends: this.modifiedLegends$.getValue(),
    });
    if (this.data.isNew) {
      this.dashboardService.createWidget(
        {
          region: this.demandPanelState.selectedRegion,
          subzone: this.demandPanelState.selectedSubzone,
          loadWidgetLegends: this.modifiedLegends$.getValue(),
          w: 8,
          h: 30,
          isNew: this.data.isNew,
        },
        WidgetTypes.demand
      );
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public getPermission(locationId: string): string[] | undefined {
    return ['Load_Forecasts', locationId.split('_')[0]];
  }

  private populateRegions(): void {
    this.dataService
      .getLocations$()
      .pipe(rxjsFirst())
      .subscribe((data: LoadRegion[]) => {
        this.demandPanelState.regions = data;
        this.assignProperties(data);
        this.cdr.markForCheck();
      });
  }

  private getFirstAllowedRegion(regions: LoadRegion[]): LoadRegion {
    const loadPermissions =
      this.permissionService.getPermissions().View.Load_Forecasts;

    const firstRegion = loadPermissions.ERCOT
      ? 'ERCOT'
      : this.getFirstValidPermissions(loadPermissions);

    return (
      regions.find(
        (region) => region.locationId.split('_')[0] === firstRegion
      ) ?? (lodashFirst(regions) as LoadRegion)
    );
  }

  public generateChartOptions() {
    const range = this.getRange(
      this.currentRangeOption,
      this.getRangeStartDate()
    );
    const locationId = this.demandPanelState.selectedSubzone.locationId;
    this.dataService
      .getLoadHourlyGraph$(locationId)
      .pipe(
        first(),
        filter((data) => !!data)
      )
      .subscribe((data) => {
        this.hasNetLoadPermission = data?.hasNetLoadPermission;
        this.isLoading = true;
        this.chartOptions = this.dataService.buildChartOptions(data, {}, range);
        const tempLegends = this.buildLegend(this.chartOptions); // get data pertinent to legends
        const legendsToLookFor =
          locationId === 'ERCOT_TOTAL'
            ? ErcotLoadLegendsToKeep
            : LoadLegendsToKeep; // determine which legends to keep

        const appendedLegends = legendsToLookFor.concat(
          this.getWidgetLegendsLabels(locationId.split('_')[0])
        ); // appending legends based on location

        const legends = tempLegends.filter((legend) =>
          appendedLegends.includes(legend.id)
        );
        this.modifiedLegends$.next(legends);
        this.isLoading = false;
        this.cdr.markForCheck();
      });
  }

  public getFullRangeSelection(): IRangeSelectorOption {
    const foundOption = this.rangeOptions.filter(
      (option: IRangeSelectorOption) => {
        return (
          option.value.min === this.currentRangeOption.value.min &&
          option.value.max === this.currentRangeOption.value.max
        );
      }
    );
    return foundOption[0] || this.rangeOptions[1];
  }

  private getRange(
    rangeOption: IRangeSelectorOption | null,
    startDay: Date
  ): ChartRange {
    const minValue = rangeOption?.value?.min ?? this.rangeOptions[1].value.min;
    const maxValue = rangeOption?.value?.max ?? this.rangeOptions[1].value.max;
    return {
      min: startDay.valueOf() + minValue * 24 * 60 * 60 * 1000,
      max: startDay.valueOf() + maxValue * 24 * 60 * 60 * 1000,
    };
  }

  private getRangeStartDate(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayStart = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    );
    return todayStart;
  }

  private buildLegend(chartOptions: Highcharts.Options) {
    const legendVisibility = this.data.isNew || this.isTriggeredByRegionChange;
    const legendItems =
      chartOptions['series']?.map((item: any) => {
        return {
          id: item.id,
          label: item.name,
          icon: item.lineIcon,
          fillColor: item.color,
          strokeColor: item.color,
          visible: legendVisibility ? true : item.visible,
          modelRunOrder: item.modelRunOrder,
          isPermitted: this.isPermitted(item.id, this.hasNetLoadPermission),
        };
      }) ?? [];
    return legendItems;
  }

  isPermitted(locationId: string, isPermitted: boolean): boolean {
    if (!locationId.includes('AG2 Net')) {
      return true;
    }

    return isPermitted;
  }

  private assignProperties(data: LoadRegion[]): void {
    const firstRegion = this.getFirstAllowedRegion(data);
    this.demandPanelState.selectedRegion = this.data.isNew
      ? firstRegion
      : (this.data.widgetConfig.region as unknown as LoadRegion);
    this.demandPanelState.selectedSubZones = this.data.isNew
      ? firstRegion.subZones
      : this.demandPanelState.selectedRegion.subZones;
    this.demandPanelState.selectedSubzone = this.data.isNew
      ? firstRegion.subZones[0]
      : this.data.widgetConfig.subzone;
  }

  private getFirstValidPermissions(permissions: {
    [key: string]: boolean;
  }): string {
    const firstEntry = Object.entries(permissions).find(([, value]) => !!value);

    return firstEntry ? firstEntry[0] : '';
  }

  private findSelectedRegion(regionId: string): LoadRegion {
    return (
      this.demandPanelState.regions.find(
        (region: LoadRegion) => region.locationId === regionId
      ) ?? this.demandPanelState.regions[0]
    );
  }

  private getWidgetLegendsLabels(subzoneAbbrv: string): string[] {
    switch (subzoneAbbrv) {
      case 'ERCOT':
      case 'PJM':
      case 'SPP': {
        return [
          `${subzoneAbbrv} Latest`,
          `${subzoneAbbrv} Morning`,
          `${subzoneAbbrv} Previous Morning`,
        ];
      }

      case 'CAISO': {
        return [
          `${subzoneAbbrv} Day Ahead`,
          `${subzoneAbbrv} Previous Day Ahead`,
        ];
      }

      case 'MISO': {
        return [`${subzoneAbbrv} Latest`, `${subzoneAbbrv} Previous`];
      }

      case 'NYISO': {
        return [`${subzoneAbbrv} Morning`, `${subzoneAbbrv} Previous Morning`];
      }
      case 'ISONE': {
        return [
          `${subzoneAbbrv} Latest`,
          `${subzoneAbbrv} Previous`,
          `${subzoneAbbrv} Day Ahead`,
        ];
      }
      default:
        return [];
    }
  }
}
