import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from '@firebird-web/shared-ui';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { Observable } from 'rxjs';
import { IAlert } from '../../interfaces/alert.interface';
import { INotification } from '../../interfaces/notification.interface';
import { RouteHelperService } from '@firebird-web/shared-services';
import { MatDialogRef } from '@angular/material/dialog';
import { isNull, isUndefined } from 'lodash';
import { NotificationsFacade } from '@firebird-web/notifications-store';

@Component({
  selector: 'firebird-web-alerts-notifications',
  templateUrl: './alerts-notifications.component.html',
  styleUrls: ['./alerts-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsNotificationsComponent implements OnInit {
  alertList$: Observable<IAlert[]>;
  notificationList$: Observable<INotification[]>;

  constructor(
    private readonly dialogService: DialogService,
    private readonly routerHelper: RouteHelperService,
    public readonly dialogRef: MatDialogRef<DialogComponent>,
    private readonly notificationsFacade: NotificationsFacade
  ) {}

  ngOnInit(): void {
    this.alertList$ = this.notificationsFacade.alerts$;
    this.notificationList$ = this.notificationsFacade.notifications$;
  }

  public openAlert(alert: IAlert): void {
    this.dialogService.openDialog({
      component: AlertDialogComponent,
      title: alert.displayName,
      config: {
        width: '32vw',
        minHeight: '400px',
        maxHeight: '800px',
      },
      showPreviousButton: true,
      context: alert,
    });
  }

  public openPage(linkUrl: string | null | undefined): void {
    if (isNull(linkUrl) || isUndefined(linkUrl)) {
      return;
    }
    if (linkUrl.startsWith('http')) {
      window.open(linkUrl, '_blank');
    }
    this.routerHelper.goToLinkUrl(linkUrl);
    this.dialogRef.close();
  }
}
