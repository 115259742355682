import { Permissions } from '@firebird-web/acl';

export type TabData = {
  labelKey: string;
  disabledForContinents?: string[];
  permissions?: Permissions;
  disabled?: boolean;
  subTabs: {
    labelKey: string;
    value: string;
    disabledForContinents?: string[];
    permissions?: Permissions;
  }[];
};

export type Tab = {
  label: string;
  key: string;
  disabled?: boolean;
  permissions?: Permissions;
};
