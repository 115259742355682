import { Component, Input, Output, EventEmitter } from '@angular/core';
export interface IMenu {
  labelKey: string;
  isActive: boolean;
}
@Component({
  selector: 'firebird-web-vertical-tabs',
  templateUrl: './vertical-tabs.component.html',
  styleUrls: ['./vertical-tabs.component.scss'],
})
export class VerticalTabsComponent {
  @Input() menus!: IMenu[];
  @Input() type: 'primary' | 'secondary' = 'primary';
  @Input() width!: string;
  @Output()
  public changeTab: EventEmitter<IMenu> = new EventEmitter<IMenu>();
  trackByFnGroup(index: number) {
    return index; // or item.id
  }

  onChangeTab(index: number): void {
    this.menus.forEach((item) => (item.isActive = false));
    this.menus[index].isActive = true;
    this.changeTab.emit(this.menus[index]);
  }
}
