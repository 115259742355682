export interface IPopOverPosition {
  top: number;
  left: number;
  bottom: number;
  right: number;
}

export interface WindowSpaceList {
  topSpace: WindowSpaceItem;
  rightSpace: WindowSpaceItem;
  leftSpace: WindowSpaceItem;
  bottomSpace: WindowSpaceItem;
}

export interface WindowSpaceItem {
  startPosition: number;
  endPosition: number;
}
