/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { TemplateRef } from '@angular/core';

interface IPopoverConfig {
  width?: string;
  height?: string;
}

export interface IPopoverInput {
  component?: ComponentType<any>;
  templateRef?: TemplateRef<any>;
  config?: IPopoverConfig;
  data?: any;
}
