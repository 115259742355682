export interface Units {
  tempUnit: string;
  precipUnit: string;
  windUnit: string;
}

export type SaveFn = (
  data: any,
  isQueryParams?: boolean,
  clearPrevState?: boolean
) => void;

export interface ShareRouterPersistencePayload {
  path: string | string[];
  data: Record<string, unknown>;
}
