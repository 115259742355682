import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromUserSettings from './+state/user-settings/user-settings.reducer';
import * as UserSettingsActions from './+state/user-settings/user-settings.actions';
import { UserSettingsEffects } from './+state/user-settings/user-settings.effects';
import { UserSettingApiService } from './user-settings-api.service';
import { first } from 'rxjs';
import { debounce } from 'lodash';

export function saveToLocalStorage(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    const nextState = reducer(state, action);
    if (
      Object.values(UserSettingsActions)
        .map(({ type }) => type as string)
        .filter((type) => type.includes('SaveToStore111'))
        .includes(action.type) &&
      state.loaded
    ) {
      const { error: _, loaded: __, user_id, ...rest } = nextState;

      localStorage.setItem(
        'user_settings_' + user_id || '',
        JSON.stringify(rest)
      );
    }
    return nextState;
  };
}

export function saveToServerWithDebounce(service: UserSettingApiService) {
  const saveWithDebounce = debounce(function (arg) {
    service.saveUserSettings(arg).pipe(first()).subscribe();
  }, 500);
  return function (reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
      const nextState = reducer(state, action);
      if (
        Object.values(UserSettingsActions)
          .map(({ type }) => type as string)
          .filter((type) => type.includes('SaveToStore'))
          .includes(action.type)
      ) {
        const { error: _, loaded: __, ...rest } = nextState;
        const { userSettings } = rest;
        const { routerPersistance, homePage, newsFeed } = userSettings;

        saveWithDebounce({ routerPersistance, homePage, newsFeed });
      }
      return nextState;
    };
  };
}

export function saveToServer(service: UserSettingApiService) {
  return function (reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
      const nextState = reducer(state, action);
      if (
        Object.values(UserSettingsActions)
          .map(({ type }) => type as string)
          .filter((type) => type.includes('SaveToServer'))
          .includes(action.type)
      ) {
        const { error: _, loaded: __, ...rest } = nextState;
        const { userSettings } = rest;
        service.saveUnitSettings(userSettings.units).pipe(first()).subscribe();
      }
      return nextState;
    };
  };
}

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromUserSettings.USERSETTINGS_FEATURE_KEY,
      fromUserSettings.userSettingsReducer
    ),
    EffectsModule.forFeature([UserSettingsEffects]),
  ],
  // why it fails for settings module??
  // providers: [UserSettingService],
  // providers: [UserSettingsFacade],
})
export class UserSettingsStoreModule {}
