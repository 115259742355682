import { isEmpty } from 'lodash';

export const htmlToPlainText = (html: string): string => {
  const temporaryElement = document.createElement('div');

  temporaryElement.innerHTML = html;

  return temporaryElement.textContent || temporaryElement.innerText || '';
};

export const isInsightMatchingKeyword = (
  blurbText: string,
  keyword: string
): boolean => {
  const parsedBlurbText = htmlToPlainText(blurbText);

  return (
    !isEmpty(parsedBlurbText) &&
    parsedBlurbText.toLowerCase().includes(keyword.trim().toLowerCase())
  );
};
