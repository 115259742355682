import { IBlogFilter } from '../interfaces/blog.interface';

export const DATE_SELECTION_ALL = 'all';
export const DATE_SELECTION_MONTH_YEAR = 'month';
export const DATE_SELECTION_SPECIFIC_DATE = 'specific';

export const INITIAL_BLOG_FILTER_STATE: IBlogFilter = {
  dateSelection: 'all',
  specificDate: new Date(),
  monthYear: new Date(),
  categories: [],
  authors: [],
  keyWord: '',
};

export const DEFAULT_BLOG_PAGE_NUMBER = 1;

export const DEFAULT_BLOG_PAGE_SIZE = 5;
