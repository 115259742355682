import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as HourlyForecastRunDatesActions from './hourly-forecast-run-dates.actions';
import { IHourlyForecastRunDatesEntity } from './hourly-forecast-run-dates.models';

export const HOURLY_FORECAST_RUN_DATES_FEATURE_KEY = 'hourlyForecastRunDates';

export interface HourlyForecastRunDatesState
  extends EntityState<IHourlyForecastRunDatesEntity> {
  selectedId?: string | number; // which HourlyForecastRunDates record has been selected
  loaded: boolean; // has the HourlyForecastRunDates list been loaded
  error?: string | null; // last known error (if any)
}

export interface HourlyForecastRunDatesPartialState {
  readonly [HOURLY_FORECAST_RUN_DATES_FEATURE_KEY]: HourlyForecastRunDatesState;
}

export const hourlyForecastRunDatesAdapter: EntityAdapter<IHourlyForecastRunDatesEntity> =
  createEntityAdapter<IHourlyForecastRunDatesEntity>();

export const initialHourlyForecastRunDatesState: HourlyForecastRunDatesState =
  hourlyForecastRunDatesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialHourlyForecastRunDatesState,
  on(
    HourlyForecastRunDatesActions.loadHourlyForecastRunDatesSuccess,
    (state, { hourlyForecastRunDates }) =>
      hourlyForecastRunDatesAdapter.setAll(hourlyForecastRunDates, {
        ...state,
        loaded: true,
      })
  ),
  on(
    HourlyForecastRunDatesActions.loadHourlyForecastRunDatesFailure,
    (state, { error }) => ({ ...state, error })
  )
);

export function hourlyForecastRunDatesReducer(
  state: HourlyForecastRunDatesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
