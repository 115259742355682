import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map } from 'rxjs';
import { SettingsApiService } from '../../services/settings-api.service';
import { createEnhancedDataServicesEntityConfigList } from '../../utils';
import {
  EnhancedDataServicesApiKeyEntity,
  EnhancedDataServicesEntityData,
} from '../../types';

@Component({
  selector: 'firebird-web-enhanced-data-services',
  templateUrl: './enhanced-data-services.component.html',
  styleUrls: ['./enhanced-data-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SettingsApiService],
})
export class EnhancedDataServicesComponent {
  public entityConfigList$ = this.settingsApiService
    .getEnhancedDataServicesApiKeys()
    .pipe(
      map((apiKeys) => createEnhancedDataServicesEntityConfigList(apiKeys))
    );

  constructor(private readonly settingsApiService: SettingsApiService) {}

  public trackByEntity(
    _: number,
    { entity }: EnhancedDataServicesEntityData
  ): EnhancedDataServicesApiKeyEntity {
    return entity;
  }
}
