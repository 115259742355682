import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigatedAction } from '@ngrx/router-store';
import {
  catchError,
  of,
  switchMap,
  map,
  filter,
  withLatestFrom,
  distinctUntilChanged,
  observeOn,
  asyncScheduler,
  iif,
} from 'rxjs';
import { isNull } from 'lodash';
import { RunDatesService } from '@firebird-web/run-dates-store';
import * as RunDatesActions from './run-dates.actions';
import { Router } from '@angular/router';
import { newDataAvailable } from '@firebird-web/ws';
import { LocationPickerFacade } from '@firebird-web/location-picker-store';
import { IRouteState } from '@firebird-web/route-store';

@Injectable()
export class RunDatesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      switchMap(
        ({
          payload: { event: routerState },
        }: RouterNavigatedAction<IRouteState>) =>
          iif(
            () => this.isRegionView(routerState.url),
            this.locationPicker.getRegionData().pipe(
              map(({ continent, customList = undefined }) => ({
                continent,
                ...(customList && {
                  continent: this.customListContinent,
                  customListContinent: customList.continent,
                }),
              }))
            ),
            this.locationPicker.getCityData().pipe(
              map(({ continent }) => ({
                continent,
              }))
            )
          )
      ),
      observeOn(asyncScheduler),
      filter(
        (
          payload
        ): payload is { continent: string; customListContinent?: string } =>
          !!payload?.continent
      ),
      distinctUntilChanged(),
      switchMap((payload) => [
        RunDatesActions.clearRunDate(),
        RunDatesActions.loadRunDatesStart(payload),
      ])
    )
  );

  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RunDatesActions.loadRunDatesStart),
      switchMap(({ continent, customListContinent }) => {
        // TODO: (refactoring) do no trigger for all application
        return this.runDatesService.getRunDatesList(
          continent,
          customListContinent
        );
      }),
      map((runDates) =>
        runDates.map((runDate) => ({
          ...runDate,
          id: runDate.initTime,
        }))
      ),
      switchMap((runDates) => [
        RunDatesActions.loadRunDatesSuccess({ runDates }),
      ]),
      catchError((error) => of(RunDatesActions.loadRunDatesFailure({ error })))
    )
  );

  fetchOnNewDataExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newDataAvailable),
      withLatestFrom(
        this.locationPicker.getRegionData(),
        this.locationPicker.getCityData()
      ),
      map(([{ data }, region, city]) => {
        const isRegionView = this.isRegionView(this.router.url);
        const location = isRegionView ? region : city;

        return { data, location, isRegionView };
      }),
      filter(({ data, location, isRegionView }) => {
        if (isNull(data)) {
          return true;
        }

        const { continent, region, customList = undefined } = location;
        const isRegionViewCustomList = !!customList && isRegionView;

        return data.some(({ model, view, dataTable, parameter }) => {
          const additionalCondition = this.router.url.includes('frisk')
            ? parameter === 'FRISK'
            : dataTable === 'hourly_fcst';

          // when new hourly obs available refresh run dates to update summary table
          const obsCondition = dataTable === 'hourly_obs';

          return (
            (isRegionViewCustomList
              ? customList?.continents?.includes(view)
              : view === continent || view === region) &&
            ((model === 'GFS' && additionalCondition) ||
              (model === 'OBS' && obsCondition))
          );
        });
      }),
      map(
        ({
          location: { continent, customList = undefined },
          isRegionView,
        }) => ({
          continent,
          ...(customList &&
            isRegionView && {
              continent: this.customListContinent,
              customListContinent: customList.continent,
            }),
        })
      ),
      filter(
        (
          payload
        ): payload is { continent: string; customListContinent?: string } =>
          !!payload?.continent
      ),
      switchMap((payload) => [RunDatesActions.loadRunDatesStart(payload)])
    )
  );

  private readonly customListContinent = 'NA';

  constructor(
    private readonly actions$: Actions,
    private readonly runDatesService: RunDatesService,
    private readonly router: Router,
    private readonly locationPicker: LocationPickerFacade
  ) {}

  private isRegionView(url: string): boolean {
    return url.includes('region-view');
  }
}
