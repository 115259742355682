import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as CitiesActions from './cities.actions';
import { CitiesEntity } from './cities.models';

export const CITIES_FEATURE_KEY = 'cities';

export interface CitiesState extends EntityState<CitiesEntity> {
  loaded: boolean; // has the Cities list been loaded
  error?: string | null; // last known error (if any)
}

export interface CitiesPartialState {
  readonly [CITIES_FEATURE_KEY]: CitiesState;
}

export const citiesAdapter: EntityAdapter<CitiesEntity> =
  createEntityAdapter<CitiesEntity>({
    selectId: (item: CitiesEntity) => item.city,
  });

export const initialCitiesState: CitiesState = citiesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

const reducer = createReducer(
  initialCitiesState,
  on(CitiesActions.loadCitiesSuccess, (state, { cities }) =>
    citiesAdapter.setAll(cities, { ...state, loaded: true })
  ),
  on(CitiesActions.loadCitiesFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function citiesReducer(state: CitiesState | undefined, action: Action) {
  return reducer(state, action);
}
