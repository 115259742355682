import { Injectable } from '@angular/core';
import { GridStackOptions } from 'gridstack';
import { UUID } from 'angular2-uuid';
import { WidgetService } from './widget.service';
import { WidgetTypes } from '../constants';
import { DashboardDataService } from './data/dashboard/dashboard-data.service';
import { BehaviorSubject } from 'rxjs';
import { CommonWidgetPosition, WidgetCardData } from '../constants';
import { WidgetModel } from '../interfaces';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { WidgetConfigPanelComponent } from '../components/widget-config-panel/widget-config-panel.component';
import { WidgetPanelDialogData, WidgetPanelDialogResult } from '../types';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from '../../../../shared/ui/src/lib/components/toast/toast.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root', // Or provide it in the appropriate module
})
export class DashboardService {
  public dashboardSize$ = new BehaviorSubject<number>(0);
  public isWidgetResizing$ = new BehaviorSubject<boolean>(false);
  public dashboard: WidgetModel[] = [];
  public widgetCardData = WidgetCardData;
  public gsOptions: GridStackOptions = {
    float: false,
    cellHeight: 15,
    margin: 3,
    handle: '.widget-drag-handle',

    resizable: { handles: 'se,sw' },
  };

  constructor(
    private widgetService: WidgetService,
    private dashDataService: DashboardDataService,
    private readonly dialog: MatDialog,
    private readonly snackbar: MatSnackBar,
    private readonly translateService: TranslateService
  ) {}

  public openWidgetConfigDialog<
    D = WidgetPanelDialogData,
    R = WidgetPanelDialogResult
  >({
    width = '375px',
    height = 'auto',
    panelClass = 'firebird-dialog-panel',
    ...rest
  }: MatDialogConfig<D>): MatDialogRef<WidgetConfigPanelComponent, R> {
    return this.dialog.open<WidgetConfigPanelComponent, D, R>(
      WidgetConfigPanelComponent,
      {
        ...rest,
        width,
        height,
        panelClass,
      }
    );
  }

  public showDashboardActionsToast(message: string): void {
    this.snackbar.openFromComponent(ToastComponent, {
      duration: 5000,
      data: {
        type: 'success',
        message,
      },
    });
  }

  public createWidgetToastMessage({
    action,
    startIndex = 1,
    deleteCount = 0,
    extraText = '',
  }: {
    action: 'delete';
    startIndex?: number;
    deleteCount?: number;
    extraText?: string;
  }): string {
    if (action === 'delete') {
      const message = this.translateService.instant(
        'widgetDeleteMessage'
      ) as string;

      if (!extraText) {
        return message;
      }

      const messageArray = message.split(' ');

      messageArray.splice(startIndex, deleteCount, extraText);

      return messageArray.join(' ');
    }

    return '';
  }

  public deleteItem(id: string): void {
    const item = this.dashboard.find((d) => d['id'] === id);

    if (item) {
      this.dashboard.splice(this.dashboard.indexOf(item), 1);
    }

    this.dashboardSize$.next(this.dashboard.length);
    this.saveDashboard();
    this.showDashboardActionsToast(
      this.createWidgetToastMessage({
        action: 'delete',
        extraText: this.widgetCardData.find(({ type }) => type === item?.type)
          ?.title,
      })
    );
  }

  updateWidgetType(id: string, widgetType: WidgetTypes) {
    const item = this.dashboard.find((d) => d['id'] === id);
    if (item) {
      item.type = widgetType;
      this.saveDashboard();
    }
  }

  public saveDashboard() {
    const widgets = this.getDashboard();
    const dashId = this.dashDataService.getSingleDashData().value?.id;
    const saveObject = this.createDashboardSaveObject(widgets, dashId);
    this.dashDataService.updateDashboard(
      dashId,
      this.dashDataService.getSingleDashData().value.title,
      this.dashDataService.getSingleDashData().value.description,
      saveObject?.widgets
    );
  }
  public addWidgetToDashFromConfig(widgetData: any): void {
    if (!widgetData.type) {
      return;
    }

    this.drawWidgets(widgetData);
  }

  public getDashboard(): WidgetModel[] {
    return this.dashboard;
  }

  public createWidget(widgetData: any, widgetType: WidgetTypes): void {
    const widget = {
      ...CommonWidgetPosition,
      type: widgetType,
      ...this.generateID(),
    };
    widget.h = 20;
    widget.w = 4;
    widget.x = 0;
    widget.y = this.dashboard.length === 0 ? 0 : this.getFarthestY() + 30;
    if (widgetData.h && widgetData.w) {
      widget.h = widgetData.h;
      widget.w = widgetData.w;
    }
    this.dashboard.push(widget);
    this.dashboardSize$.next(this.dashboard.length);
    this.widgetService.updateWidgetById(widget.id, widgetData);
    this.saveDashboard();
  }
  private createDashboardSaveObject(arrayOfWidgets: any, dashId: any) {
    const dashSave = {
      dashboard_id: dashId,
      widgets: arrayOfWidgets.map((item: any) => ({
        id: item?.id,
        w: item?.w,
        h: item?.h,
        x: item?.x,
        y: item?.y,
        type: item?.type,
        name: item?.name,
        config: this.getWidgetConfiguration(item.id),
      })),
    };
    return dashSave;
  }
  getFarthestY(): number {
    let bigY = 0;
    this.dashboard.forEach((item) => {
      if (item.y > bigY) {
        bigY = item.y;
      }
    });
    return bigY;
  }
  scrollToBottom(): void {
    window.scrollTo({
      top: 10000,
      behavior: 'smooth',
    });
  }
  private getWidgetConfiguration(id: any) {
    if (this.widgetService.getCurrentWidgetParmaeter().has(id)) {
      const widgetBehaviorSubject = this.widgetService
        .getCurrentWidgetParmaeter()
        .get(id);
      return widgetBehaviorSubject?.value || {};
    }
    return {};
  }

  private generateID(): { id: string } {
    return {
      id: UUID.UUID(),
    };
  }

  private drawWidgets(widgetObj: any): void {
    this.dashboard.unshift({
      x: widgetObj?.x,
      y: widgetObj?.y,
      h: widgetObj?.h,
      w: widgetObj?.w,
      type: widgetObj?.type,
      id: widgetObj?.id,
    });
    this.widgetService.updateWidgetById(widgetObj?.id, widgetObj?.config);
    this.dashboardSize$.next(this.dashboard?.length);
  }
  public toggleWidgetResize(value: boolean) {
    this.isWidgetResizing$.next(value);
  }
}
