import { Params } from '@angular/router';

/**
 * Provides State Route Interface.
 */
export interface IRouteState {
  url: string;
  params: Params;
  queryParams: Params;
}
