import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { PermissionsService } from '../../../../../acl/src/lib/permissions.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { BlurbService } from '../../../../../news/src/lib/components/blurbs/services/blurb.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BlurbLocationSource } from '../../constants';
import { BlurbLocationOption, WidgetBlurbFilterConfig } from '../../interfaces';

@Component({
  selector: 'firebird-web-blurb-panel',
  templateUrl: './blurb-panel.component.html',
  styleUrls: ['./blurb-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlurbPanelComponent implements OnInit {
  public locationDataSource: BlurbLocationOption[];
  public expertDataSource$: Observable<ServerResponse<string[]>>;
  public keywordSearchLabelText = this.i18nService.instant('keywordSearch');
  public blurbFilterForm: FormGroup;

  get showClearFiltersButton(): boolean {
    const { locations, keyWord, experts } = this.blurbFilterForm.value;
    return !!(locations?.length || keyWord || experts?.length);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private widgetData: { widgetConfig: WidgetBlurbFilterConfig },
    public readonly i18nService: TranslateService,
    private readonly fb: FormBuilder,
    private readonly permissionsService: PermissionsService,
    private readonly blurbService: BlurbService,
    private readonly dialogRef: MatDialogRef<BlurbPanelComponent>
  ) {
    this.initFilterForm();
  }

  ngOnInit(): void {
    this.initLocationDataSource();
    this.getInsightsExperts();
  }

  public trackByLocation(_: number, location: BlurbLocationOption): string {
    return location.name;
  }

  public trackByAuthor(_: number, author: string): string {
    return author;
  }

  public savePanel(): void {
    this.dialogRef.close(this.blurbFilterForm.value);
  }

  public closePanel(): void {
    this.dialogRef.close();
  }

  public clearFilters(): void {
    this.blurbFilterForm.reset();
  }

  private initFilterForm(): void {
    const { experts, locations, keyWord } = this.widgetData.widgetConfig;
    this.blurbFilterForm = this.fb.group({
      locations: [locations],
      keyWord: [keyWord],
      experts: [experts],
    });
  }

  private initLocationDataSource(): void {
    this.locationDataSource = BlurbLocationSource.filter((location) =>
      this.permissionsService.isPermitted(location.permission)
    );
  }

  private getInsightsExperts(): void {
    this.expertDataSource$ = this.blurbService.getExperts();
  }
}
