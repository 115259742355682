/* eslint-disable @typescript-eslint/no-explicit-any */
import { MatDialogConfig } from '@angular/material/dialog';

export interface IDialogInput<T = any> {
  title?: HTMLElement | string;
  params?: T;
  component: any;
  context?: T;
  config?: MatDialogConfig<any>;
  showPreviousButton?: boolean;
  showManage?: any;
  headerPaddingX?: number;
}
