import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { CommonUtils } from '@firebird-web/shared-utils';
import { IHourlyForecastRunDatesEntity } from '../+state/hourly-forecast-run-dates/hourly-forecast-run-dates.models';

@Injectable({
  providedIn: 'root',
})
export class HourlyForecastRunDatesService {
  public constructor(private readonly http: HttpClient) {}

  /**
   * getRunDatesList
   * @param continentId
   * @returns
   */
  public getRunDatesList(
    continentId: string
  ): Observable<IHourlyForecastRunDatesEntity[]> {
    const encodedContinentId = <string>encodeURIComponent(continentId);
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getHourlyForecastRunDates}`,
      {
        continentId: encodedContinentId,
      }
    );
    return this.http.get(endPointUrl).pipe(map(({ data }: any) => data));
  }
}
