<span class="input-text">
  <label class="label" [for]="id" *ngIf="id && labelKey" translate>{{
    labelKey
  }}</label>
  <span class="field-wrapper">
    <input
      class="form-field"
      type="text"
      [attr.formControlName]="formControlName"
      [attr.maxlength]="maxlength"
      [attr.id]="id"
      [attr.name]="name"
      [attr.placeholder]="placeholderKey | translate"
      [attr.disabled]="disabled"
    />
  </span>
</span>
