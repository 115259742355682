import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  RunDatesEntity,
  RunDatesService,
  getAllRunDates,
  loadRunDatesSuccess,
} from '@firebird-web/run-dates-store';
import {
  CitiesEntity,
  CitiesService,
  getAllCities,
  loadCitiesSuccess,
} from '@firebird-web/cities-store';
import * as fromCompLocationActions from './comp-location.actions';
import { continents } from '@firebird-web/shared-constants';

@Injectable()
export class CompareLocationEffects {
  initCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCitiesSuccess),
      withLatestFrom(this.store.select(getAllCities)),
      switchMap(([, cities]) => {
        return [fromCompLocationActions.updateCities({ cities })];
      }),
      catchError((error) =>
        of(fromCompLocationActions.updateCitiesFailure(error))
      )
    )
  );

  initRunDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadRunDatesSuccess),
      withLatestFrom(this.store.select(getAllRunDates)),
      switchMap(([, runDates]) => {
        return [fromCompLocationActions.updateRunDates({ runDates })];
      }),
      catchError((error) =>
        of(fromCompLocationActions.updateRunDatesFailure(error))
      )
    )
  );

  fetchRunDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompLocationActions.changeLocationAction),
      switchMap((action) => {
        return this.runDatesService.getRunDatesList(
          action.payload.continent === 'Custom List'
            ? continents.EUROPE
            : action.payload.continent
        );
      }),
      map((apiResponse: any) => {
        return apiResponse.map(
          (item: { initTime: string; type: string; storageTime: string }) => {
            return {
              id: item.initTime,
              label: item.initTime,
              type: item.type,
              storageTime: item.storageTime,
            };
          }
        );
      }),
      switchMap((runDates: RunDatesEntity[]) => [
        fromCompLocationActions.updateRunDates({ runDates }),
      ]),
      catchError((error) =>
        of(fromCompLocationActions.updateRunDatesFailure(error))
      )
    )
  );

  fetchCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompLocationActions.changeLocationAction),
      filter((action) => action.payload.continent !== 'Custom List'),
      switchMap((action) =>
        this.citiesService.getCitiesList(
          action.payload.continent,
          action.payload.region
        )
      ),
      map((cities) => fromCompLocationActions.updateCities({ cities }))
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompLocationActions.resetCitiesAndRunDates),
      withLatestFrom(
        this.store.select(getAllCities),
        this.store.select(getAllRunDates)
      ),
      switchMap(
        ([_, cities, runDates]: [object, CitiesEntity[], RunDatesEntity[]]) => {
          return [
            fromCompLocationActions.updateCities({ cities }),
            fromCompLocationActions.updateRunDates({ runDates }),
          ];
        }
      ),
      catchError((error) =>
        of(
          fromCompLocationActions.updateCitiesFailure(error),
          fromCompLocationActions.updateRunDatesFailure(error)
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly runDatesService: RunDatesService,
    private readonly citiesService: CitiesService,
    private readonly store: Store
  ) {}
}
