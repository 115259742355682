import { Injectable } from '@angular/core';
import { IRouteState } from '@firebird-web/route-store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import {
  catchError,
  of,
  switchMap,
  map,
  filter,
  withLatestFrom,
  debounceTime,
  tap,
} from 'rxjs';
import { isNull } from 'lodash';
import { HourlyForecastRunDatesService } from '../../services/hourly-forecast-run-dates.service';

import * as HourlyForecastRunDatesActions from './hourly-forecast-run-dates.actions';
import * as fromCompLocationActions from '@firebird-web/comp-location-store';

import { IHourlyForecastRunDatesEntity } from './hourly-forecast-run-dates.models';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { LocationPickerFacade } from 'libs/location-picker-store/src';
import { newDataAvailable } from '@firebird-web/ws';

// TODO: distinctUntilChanged works in unexpected way so this is temporary solution. It needs to be investigated and fixed in scope of refactoring.
let continent: string | null = '';
@Injectable()
export class HourlyForecastRunDatesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter(
        ({
          payload: { event: routerState },
        }: RouterNavigatedAction<IRouteState>) => {
          return routerState.url.includes('hourly'); // continue if continentId is present in the query params
        }
      ),
      switchMap(() => this.locationPicker.getCityData()),
      filter((location) => {
        return !!location.continent && location.continent !== continent;
      }),
      tap((location) => {
        continent = location.continent;
      }),
      switchMap((location) =>
        this.hourlyForecastRunDatesService.getRunDatesList(
          location.continent || ''
        )
      ),
      map((apiRresponse: any) => {
        return apiRresponse.map(
          ({ initTime: id, label, storageTime, type }: RunDatesEntity) => ({
            id,
            label,
            storageTime,
            type,
          })
        );
      }),
      switchMap((hourlyForecastRunDates: IHourlyForecastRunDatesEntity[]) => [
        HourlyForecastRunDatesActions.loadHourlyForecastRunDatesSuccess({
          hourlyForecastRunDates,
        }),
        fromCompLocationActions.updateRunDates({
          runDates: hourlyForecastRunDates as RunDatesEntity[],
        }),
      ]),
      catchError((error) =>
        of(
          HourlyForecastRunDatesActions.loadHourlyForecastRunDatesFailure({
            error,
          })
        )
      )
    )
  );

  fetchOnNewDataExist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(newDataAvailable),
      withLatestFrom(this.locationPicker.getCityData()),
      filter(([{ data }, location]) => {
        if (isNull(data)) {
          return true;
        }
        const continent = location.continent;
        const region = location.region;
        return data.some(({ model, view, dataTable }) => {
          const additionalCondition = dataTable === 'hourly_fcst';

          return (
            (view === continent || view === region) &&
            model === 'GFS' &&
            additionalCondition
          );
        });
      }),
      debounceTime(10000),
      map(([, location]) => location),
      switchMap((location) =>
        this.hourlyForecastRunDatesService.getRunDatesList(
          location.continent || ''
        )
      ),
      map((apiRresponse: any) => {
        return apiRresponse.map(
          ({ initTime: id, label, storageTime, type }: RunDatesEntity) => ({
            id,
            label,
            storageTime,
            type,
          })
        );
      }),
      switchMap((hourlyForecastRunDates: IHourlyForecastRunDatesEntity[]) => [
        HourlyForecastRunDatesActions.loadHourlyForecastRunDatesSuccess({
          hourlyForecastRunDates,
        }),
        fromCompLocationActions.updateRunDates({
          runDates: hourlyForecastRunDates as RunDatesEntity[],
        }),
      ]),
      catchError((error) =>
        of(
          HourlyForecastRunDatesActions.loadHourlyForecastRunDatesFailure({
            error,
          })
        )
      )
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly hourlyForecastRunDatesService: HourlyForecastRunDatesService,
    private readonly locationPicker: LocationPickerFacade
  ) {}
}
