import { PrintPreviewFrameConfig, PrintPreviewFrameType } from '../types';
import { Blurb } from '../../../interfaces/news.interface';
import { IBlog } from '../interfaces/blog.interface';

export const createPrintPreviewFrameConfig = (
  type: PrintPreviewFrameType,
  data: Blurb | IBlog,
  extraConfig?: {
    isViewsCounterPermitted?: boolean;
    isPopup?: boolean;
  }
): PrintPreviewFrameConfig => {
  if (type === 'blog') {
    const {
      id,
      author,
      submissionTime,
      readCount,
      postText: content,
      title,
      hasRead,
    } = data as IBlog;

    return {
      type: 'blog',
      id,
      title,
      author,
      content,
      readCount,
      submissionTime,
      ...(extraConfig && {
        ...(extraConfig?.isViewsCounterPermitted && {
          isViewsCounterPermitted: extraConfig?.isViewsCounterPermitted,
        }),
        ...(extraConfig?.isPopup && {
          readCount: hasRead ? readCount : readCount + 1,
        }),
      }),
    };
  }

  const {
    id,
    expert: author,
    submissionTime,
    blurbText: content,
    location,
  } = data as Blurb;

  return {
    type: 'blurb',
    id,
    author,
    content,
    location,
    submissionTime,
  };
};

export const createPrintPreviewFrameTemplate = ({
  title,
  author,
  formatedTime,
  sanitizedContent,
  location,
  type,
}: Omit<PrintPreviewFrameConfig, 'submissionTime' | 'content'> & {
  sanitizedContent: string | null;
  formatedTime: string | null;
}): string => `
      <html lang="en">
        <head>
          <title>AG2 Trader</title>
          <style>
            * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
            }
            html {
              font-size: 16px;
            }
            html, body {
              max-width: 100vw;
              overflow: hidden;
            }
            body {
              -webkit-print-color-adjust: exact;
              display: flex;
              flex-direction: column;
              gap: 1rem;
              margin: 1rem 0;
              font-family: Plus Jakarta Sans, sans-serif;
              background-color: #ffffff;
              color: #000000;
            }
            h3 {
              display: inline-flex;
              font-size: 1rem;
              font-weight: 700;
              line-height: 20px;
            }
            .info {
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 1rem;
            }
            .left, .right {
              display: flex;
              align-items: center;
              gap: 0.75rem;
            }
            .author,
            .date,
            .views,
            .location {
              display: inline-flex;
              font-size: 0.875rem;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: 0;
              color: #525252;
            }
            .views[data-is-views-counter-permitted="false"] {
              display: none;
            }
            .content {
              overflow: hidden;
            }
            .content p {
              margin-bottom: 0.75rem;
            }
            img {
              width: 100%;
              height: auto;
              object-fit: fill;
              object-position: center;
              page-break-before: always;
              page-break-after: always;
              page-break-inside: avoid;
            }
            @page {
              size: auto;
              overflow: visible !important;
            }
          </style>
        </head>
        <body>
          <h3 style="display: ${
            type === 'blurb' ? 'none' : 'inline-flex'
          }">${title}</h3>
          <section class="info">
            <div class="left">
              <p class="author">Published by ${author}</p>
              <time class="date">${formatedTime}</time>
            </div>
            <div class="right">
              ${location ? `<p class="location">${location}</p>` : ''}
            </div>
          </section>
          <section class="content">
            ${sanitizedContent}
          </section>
        </body>
      </html>
    `;
