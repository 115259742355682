import { BlurbLocationOption } from '../interfaces';

export const NaISORegions: string[] = [
  'MISO',
  'ERCOT',
  'SPP',
  'NEISO',
  'NYSIO',
  'PJM',
  'IESO',
  'Southeast',
  'NWPP',
  'AESO',
  'CAISO',
  'SOUTHWEST',
];

export const NaEIARegions: string[] = [
  'CONSUM. EAST',
  'PRODUCING',
  'CONSUM. WEST',
  'EAST',
  'MIDWEST',
  'MOUNTAIN',
  'PACIFIC',
  'SOUTH CENTRAL',
];

export const EurRegions: string[] = [
  'AUSTRIA',
  'SWITZERLAND',
  'GERMANY',
  'DENMARK',
  'FINLAND',
  'FRANCE',
  'GREECE',
  'ITALY',
  'NORWAY',
  'POLAND',
  'SWEDEN',
  'TURKEY',
  'UKRAINE',
  'BRITISH ISLES',
  'CZECH REPUBLIC/SLOVAKIA',
  'BENELUX',
  'IBERIA',
  'SE EUROPE',
  'BALTIC/RUSSIA',
];

export const NaMexISORegions: string[] = [
  'MISO',
  'ERCOT',
  'SPP',
  'NEISO',
  'NYSIO',
  'PJM',
  'LESO',
  'SOUTHEAST',
  'NWPP',
  'AESO',
  'CAISO',
  'SOUTHWEST',
  'MEXICO',
  'Norte/Noreste',
  'Baja/noreste',
  'Central/occidental',
  'Oriental',
  'Peninsular',
];

export const AsiaRegions: string[] = ['CHINA', 'JAPAN', 'SOUTH KOREA'];

export const AustRegions: string[] = [
  'QUEENSLAND',
  'NEW SOUTH WALES',
  'VICTORIA/TASMANIA',
  'SOUTH AUSTRALIA',
];

export const CustomList: string[] = [
  'Custom list A',
  'CustomList B',
  'Custome List c',
  'Custome List d',
  'Custom List E',
  'Custom List F',
];

export const BlurbLocationSource: BlurbLocationOption[] = [
  { name: 'North America', permission: ['Forecaster_Blurbs', 'NA'] },
  { name: 'Global', permission: ['Forecaster_Blurbs', 'Global'] },
  { name: 'Europe', permission: ['Forecaster_Blurbs', 'EUR'] },
  { name: 'Asia', permission: ['Forecaster_Blurbs', 'ASIA'] },
  { name: 'North America/Mexico', permission: ['Forecaster_Blurbs', 'NA'] },
];
