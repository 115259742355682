import { ChangeDetectionStrategy, Component } from '@angular/core';
import { isNull, isString } from 'lodash';
import { isHourRenderType } from '../../utils';
import {
  NA_RENEWABLES_HEAT_MAP_CELL_NO_COLOR_CODE,
  NA_RENEWABLES_HEAT_MAP_CELL_NOT_AVAILABLE,
} from '../../constants';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  NaRenewablesHeatMapCellParams,
  NaRenewablesHeatMapCellValue,
  NaRenewablesHeatMapColorCode,
} from '../../types';
import {
  NaRenewablesHeatMapCellRenderType,
  NaRenewablesPrimaryLens,
} from '../../enums';

@Component({
  templateUrl: './na-renewables-heat-map-cell.component.html',
  styleUrls: ['./na-renewables-heat-map-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NaRenewablesHeatMapCellComponent
  implements ICellRendererAngularComp
{
  public readonly forecastPrimaryLens = NaRenewablesPrimaryLens.FORECAST;

  public cellText!: string;
  public renderType!: NaRenewablesHeatMapCellRenderType;
  public cellBgColorCode!: NaRenewablesHeatMapColorCode | string | null;
  public isTotalRow!: boolean;
  public isHourlyRow!: boolean;
  public isLastRow!: boolean;
  public isWeekend!: boolean;
  public cellValue: string | number | null;
  public primaryLens: string;
  public percentForecastDiff: string | null;

  public get isHourRenderType(): boolean {
    return isHourRenderType(this.renderType);
  }

  public agInit(params: NaRenewablesHeatMapCellParams): void {
    const { renderType, value, primaryLens, colDef } = params;
    const { isTotalRow, isLastRow, isHourlyRow, percentForecastDiff } =
      value ?? ({} as NaRenewablesHeatMapCellValue);

    if (colDef?.headerName) {
      const day = new Date(colDef.headerName).getDay();
      this.isWeekend = day === 0 || day === 6;
    }

    this.percentForecastDiff = percentForecastDiff ?? null;
    this.primaryLens = primaryLens;
    this.cellValue = value.value;
    this.renderType = renderType;
    this.cellText = this.formatCellText(value);
    this.cellBgColorCode = this.defineColorCode(value);
    this.isTotalRow = isTotalRow ?? false;
    this.isHourlyRow = isHourlyRow ?? false;
    this.isLastRow = isLastRow ?? false;
  }

  public refresh(): boolean {
    return true;
  }

  private formatCellText(
    { value } = { value: null } as NaRenewablesHeatMapCellValue
  ): string {
    if (isNull(value)) {
      return NA_RENEWABLES_HEAT_MAP_CELL_NOT_AVAILABLE;
    }

    return isString(value) ? value : value.toString();
  }

  private defineColorCode(
    { colorCode } = { colorCode: null } as NaRenewablesHeatMapCellValue
  ): NaRenewablesHeatMapColorCode | string | null {
    if (this.isHourRenderType) {
      return null;
    }

    return colorCode ?? NA_RENEWABLES_HEAT_MAP_CELL_NO_COLOR_CODE;
  }
}
