import { ColDef } from 'ag-grid-community';
import { ForecastDemandLegendConfig } from '../models/load.interfaces';
import { LoadHourlyCellComponent } from '../components/load-hourly-cell/load-hourly-cell.component';

export const DEFAULT_REGION = 'CAISO_TOTAL';

export const COMMON_MODELS = [
  'ECMWF OP',
  'ECMWF ENS',
  'GFS OP',
  'OBS',
  'GFS ENS',
];

export const ErcotTotalOption = 'ERCOT_TOTAL';
export const DropdownWidth: Record<string, string> = {
  default: '290px',
  custom: '320px',
};

export const HourlyEndingColumn: Partial<ColDef> = {
  field: 'hourlyEnding',
  headerName: 'Hour Ending',
  suppressSizeToFit: false,
  minWidth: 116,
  cellRenderer: LoadHourlyCellComponent,
};

export const ForecastDifferenceLegendConfig: ForecastDemandLegendConfig = {
  unit: 'Forecast Difference',
  leftSubtitle: 'Less Demand',
  rightSubtitle: 'More Demand',
  title: 'Demand Difference (%)',
  colorBottomLabel: 'Less Demand',
  colorTopLabel: 'More Demand',
  supTitle: 'Compared to prior forecast',
  width: '176px',
  height: '149px',
  colorCodes: [
    { color: '#3464A7', label: '-5 % or less' },
    { color: '#B1CDE3', label: '-5 to -1%' },
    { color: '#D9D9D9', label: '-1% to 1%' },
    { color: '#D9B8BA', label: '1 to 5%' },
    { color: '#A32A31', label: '5% or more' },
  ],
};
