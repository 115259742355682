import { ParamMap, Router } from '@angular/router';
import { constants } from '@firebird-web/shared-constants';
import { isEmpty, isArray, every, isNull, isObject } from 'lodash';
import { City } from '../../../components/src/lib/location-selection/location.interface';
import { SortableLocationProperty } from '../../../interfaces/src/lib/location.interface';
import { SimpleChanges } from '@angular/core';
import moment from 'moment';

export class CommonUtils {
  /**
   * Method to replace URL Template params with object values
   * Example
   * url=/med/ecom/soldtos/{soldtos}/shiptos/{shiptos}/v1/productavailabilities
   * tempObject={'soldtos':'123','shiptos':'456'}
   * @param urlTemplate
   * @param tempObject
   */
  public static populateURLTemplate(
    urlTemplate: string = '',
    tempObject: any = {}
  ) {
    let tempHTML = '';
    tempHTML += urlTemplate.replace(/{[^{}]+}/g, function (key: string) {
      return tempObject[key.replace(/[{}]+/g, '')];
    });
    return tempHTML;
  }

  /**
   * Method to search string in the param path
   * Example
   * path='/city-view'
   * @param path
   */
  public static pathContain(path: string) {
    return location.pathname.includes(path);
  }

  /**
   * Compare two arrays
   * @param a
   * @param b
   * @returns
   */
  public static isEqualArray(a: any[], b: any[]): boolean {
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; i++) {
      const objA = a[i];
      const objB = b[i];

      // Compare keys
      const keysA = Object.keys(objA);
      const keysB = Object.keys(objB);

      if (keysA.length !== keysB.length) {
        return false;
      }

      // Compare values for each key
      for (const key of keysA) {
        if (objA[key] !== objB[key]) {
          return false;
        }
      }
    }

    return true;
  }

  /**
   * checkNaOrZeroValue
   * @param value
   * @returns
   */
  public static checkNaOrZeroValue(value: any) {
    switch (value) {
      case null:
        return constants.NOT_AVAILABLE;
      case undefined:
        return null;
      case 0:
        return '0';
      default:
        return value;
    }
  }
  /**
   * Divides array to chunks by the number of columns
   * @param arr
   * @param length
   * @returns array of chunks
   */
  public static toChunksByColumnLength<T>(arr: T[], length: number): T[][] {
    const chunkQty = Math.ceil(arr.length / length);
    return Array.from({ length: chunkQty }, (_, i) =>
      arr.slice(i * length, i * length + length)
    );
  }
  /**
   * Divides array to chunks by columnLength
   * @param arr
   * @param length
   * @returns array of chunks
   */
  public static toChunksByColumnsQty<T>(arr: T[], qty: number): T[][] {
    const length = Math.ceil(arr.length / qty);
    return Array.from({ length: qty }, (_, i) =>
      arr.slice(i * length, i * length + length)
    );
  }

  public static distinctUntilQueryParamsChanged(params: string[]) {
    return (prev: ParamMap, curr: ParamMap) => {
      return params.every((key) => prev.get(key) === curr.get(key));
    };
  }

  public static filterQueryParamsIsPresent(params: string[]) {
    return (curr: ParamMap) => {
      return params.every((key) => !!curr.get(key));
    };
  }

  public static getMappedRegionName(region: string | null): string {
    if (region === 'All Regions') {
      return 'All Aggregates';
    }

    return region || '';
  }

  public static transformToDecimalValue(
    isTransform: boolean,
    value: number
  ): number | string {
    if (isTransform) {
      return value;
    }

    if (value || value === 0) {
      return Math.round(value);
    }

    return '';
  }

  public static getLastUrlPart<T = string>(url: string, numParts = 1): T {
    if (!url.length) {
      return '' as T;
    }

    const parts = url.split('?')[0].split('/');

    if (isEmpty(parts)) {
      return '' as T;
    }

    if (numParts > 1) {
      return parts.slice(-numParts) as T;
    }

    return parts.pop() as T;
  }

  public static getMappedQueryParameters<
    K extends string,
    E extends readonly K[]
  >(
    paramsMap: ParamMap,
    extractParams: E,
    defaultParams: Partial<{
      [key in typeof extractParams[number]]: string;
    }> = {}
  ): { [key in typeof extractParams[number]]: string } {
    return extractParams.reduce(
      (acc, param) => ({
        ...acc,
        [param]:
          paramsMap.get(param) ??
          (defaultParams[param] ? defaultParams[param] : ''),
      }),
      {} as { [key in typeof extractParams[number]]: string }
    );
  }

  public static isBooleanTrue(str: string): boolean {
    return /true/.test(str);
  }

  public static getActiveLink(
    router: Router,
    tabConfig: any[],
    defaultTab: string
  ): string {
    const currentPage = router.url.split('?')[0].split('/').pop();
    const foundTab = tabConfig.find((item) => {
      if (item.value === currentPage) return true;
      return item.subTabs?.some((subTab: any) => subTab.value === currentPage);
    })?.value;

    return foundTab || defaultTab;
  }

  public static range(begin: number, endExclusive: number, step = 1): number[] {
    return Array.from(
      { length: (endExclusive - begin) / step },
      (_, index) => begin + index * step
    );
  }

  public static getLastCompareDate(
    dates: Array<Record<'id', string>>,
    runDate: string,
    runType: string,
    isAsiaRegion = false,
    isEuropeRegion = false
  ): string {
    // for Asia region, we are using Sunday to Thursday dates
    const mondayDateIndex = 1;
    const fridayDateIndex = isAsiaRegion ? 4 : 5;
    const dateIds = dates.map(({ id }) => id.toString());
    const runDateWithoutTimeZone = new Date(
      runDate.toString().replace(/z/i, '')
    );
    if (
      runDateWithoutTimeZone.getDay() === mondayDateIndex &&
      runType !== 'UPDATE' &&
      !isEuropeRegion
    ) {
      const fridayIndex = dates
        .map(({ id }) => id.toString().replace(/z/i, ''))
        .findIndex((date) => {
          return new Date(date).getDay() === fridayDateIndex;
        });
      return fridayIndex !== -1 ? dateIds[fridayIndex] : dateIds[0];
    }
    return dateIds[0];
  }

  public static sortCityByName(
    list: City[],
    sortProperty: SortableLocationProperty
  ): City[] {
    return list.sort((a: City, b: City) => {
      if (a[sortProperty] < b[sortProperty]) {
        return -1;
      }

      if (a[sortProperty] > b[sortProperty]) {
        return 1;
      }

      return 0;
    });
  }

  public static isMatrix<T extends Array<unknown | unknown[]>>(
    array: T
  ): boolean {
    return isArray(array) && !isEmpty(array) && every(array, isArray);
  }

  public static isLettersOnly(str: string): boolean {
    return /^[a-zA-Z]+$/.test(str.trim());
  }

  public static getWeekendCellClass(
    date: string,
    colIndex: number,
    columnsLength: number
  ): string {
    const day = new Date(date).getDay();

    if (day === 0 && colIndex === 0) {
      return 'weekend-border-right weekend-border-left';
    }

    if (day === 6 && colIndex === columnsLength - 1) {
      return 'weekend-border-right weekend-border-left';
    }

    const classesMap: Record<number, string> = {
      0: 'weekend-border-right',
      6: 'weekend-border-left',
    };

    return classesMap[day] || '';
  }

  public static extractChanges<T>(
    changes: SimpleChanges,
    inputName: string
  ): {
    previous: T | undefined;
    current: T | undefined;
    isFirstChange: boolean;
  } {
    if (changes[inputName]) {
      const {
        previousValue: previous = undefined,
        currentValue: current = undefined,
        firstChange,
      } = changes[inputName];

      return {
        previous,
        current,
        isFirstChange: firstChange,
      };
    }

    return { previous: undefined, current: undefined, isFirstChange: false };
  }

  public static isWeekend(date: string): boolean {
    return [0, 6].includes(moment(date, ['MM-DD-YYYY', 'YYYY-MM-DD']).day());
  }

  public static calculateAgGridTableHeight({
    rowCount,
    headerHeight,
    rowHeight,
    extraHeight = 0,
  }: {
    rowCount: number;
    headerHeight: number;
    rowHeight: number;
    extraHeight?: number;
  }): number {
    return headerHeight + rowCount * rowHeight + extraHeight;
  }

  public static addSpacesBetweenWords(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  public static pxToNumber(pxValue: string): number {
    return parseInt(pxValue, 10);
  }

  public static getStyleString(
    styles: Record<string, string | number>
  ): string {
    if (!isObject(styles) || isNull(styles)) {
      throw new Error('Input must be a style object.');
    }

    return Object.entries(styles)
      .map(([key, value]) => {
        const kebabKey = key.replace(/([A-Z])/g, '-$1').toLowerCase();

        return `${kebabKey}: ${value};`;
      })
      .join(' ');
  }
}
