<span class="affordance component">
  <!-- this is the menu trigger -->
  <button class="kebab-icon" mat-icon-button [matMenuTriggerFor]="menu">
    <span>
      <firebird-web-icon
        class="affordance-icons"
        [name]="name"
        [width]="'30px'"
        [height]="'30px'"
      >
      </firebird-web-icon>
    </span>
  </button>

  <!-- Below panel will display -->
  <mat-menu
    #menu="matMenu"
    backdropClass="affordance-backdrop"
    class="affordance-menu-panel"
  >
    <button mat-menu-item (click)="exportData()">
      <firebird-web-icon
        class="affordance-icons"
        [name]="'download-bottom'"
        [width]="'30px'"
        [height]="'30px'"
      >
      </firebird-web-icon>
      <span translate="exportData"></span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="addToMonitor">
      <firebird-web-icon
        class="affordance-icons"
        [name]="'graph-stats-square'"
        [width]="'30px'"
        [height]="'30px'"
      >
      </firebird-web-icon>
      <span translate="addToMonitor"></span>
    </button>
  </mat-menu>

  <mat-menu #addToMonitor="matMenu">
    <button mat-menu-item translate>Link 1</button>
    <button mat-menu-item translate>Link 2</button>
  </mat-menu>
</span>
