import { ComponentType } from '@angular/cdk/portal';
import { WidgetTypes } from './widget-types';
import { HeatMapPanelComponent } from '../components/heat-map-panel/heat-map-panel.component';
import { ImapPanelComponent } from '../components/imap-panel/imap-panel.component';
import { RenewablesPanelComponent } from '../components/renewables-panel/renewables-panel.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { ModelPanelComponent } from '../components/model-panel/model-panel.component';
import { WeightedGraphPanelComponent } from '../components/weighted-graph-panel/weighted-graph-panel.component';
import { AnalisysPanelComponent } from '../components/analisys-panel/analisys-panel.component';
import {
  LoadRegion,
  WidgetCard,
  WidgetItemsConfig,
  WidgetPositionConfig,
} from '../interfaces';
import { WeightedPanelComponent } from '../components/weighted-panel/weighted-panel.component';
import { DemandPanelComponent } from '../components/demand-panel/demand-panel.component';
import { BlurbPanelComponent } from '../components/blurb-panel/blurb-panel.component';

const defaultConfigs = {
  width: '601px',
  height: '428px',
  panelClass: 'custom-dialog-styling',
  autoFocus: false,
};

export const widgetConfigs: Record<
  WidgetTypes,
  { component?: ComponentType<unknown> } & MatDialogConfig
> = {
  [WidgetTypes.heatMap]: {
    ...defaultConfigs,
    width: '584px',
    height: '526px',
    component: HeatMapPanelComponent,
  },
  [WidgetTypes.interactiveMap]: {
    ...defaultConfigs,
    component: ImapPanelComponent,
  },
  [WidgetTypes.demand]: {
    ...defaultConfigs,
    width: 'auto',
    component: DemandPanelComponent,
  },
  [WidgetTypes.renewables]: {
    ...defaultConfigs,
    component: RenewablesPanelComponent,
    height: '250px',
  },
  [WidgetTypes.naRenewables]: {
    ...defaultConfigs,
    component: RenewablesPanelComponent,
    height: '250px',
  },
  [WidgetTypes.weatherTimeline]: {
    ...defaultConfigs,
  },
  [WidgetTypes.compMatrix]: {
    ...defaultConfigs,
  },
  [WidgetTypes.model]: {
    component: ModelPanelComponent,
    ...defaultConfigs,
    width: '571px',
    height: '350px',
  },
  [WidgetTypes.weightedGraph]: {
    component: WeightedGraphPanelComponent,
    ...defaultConfigs,
    width: '571px',
    height: '350px',
  },
  [WidgetTypes.analysis]: {
    component: AnalisysPanelComponent,
    ...defaultConfigs,
    width: '479px',
    height: '285px',
  },
  [WidgetTypes.weighted]: {
    component: WeightedPanelComponent,
    ...defaultConfigs,
    width: '479x',
    height: '288px',
  },
  [WidgetTypes.blurb]: {
    ...defaultConfigs,
    component: BlurbPanelComponent,
  },
};

export const WidgetCardData: WidgetCard[] = [
  {
    title: 'Summary Table',
    description:
      'Bring the classic AG2 Home Page forecast table into a widget. Same functionality available but in widget form',
    icon: 'grid_on',
    type: WidgetTypes.heatMap,
  },
  {
    title: 'Interactive Map',
    description:
      'Monitor real-time weather around the globe.  Same layers as offered on the full-size map are also available in widget form',
    icon: 'map',
    type: WidgetTypes.interactiveMap,
  },
  {
    title: 'Renewables',
    description:
      'Visualize Solar and Wind Power forecasts in graphical format for countries across Europe based on data from the ECMWF and GFS models',
    icon: 'grid_on',
    permission: ['Renewables'],
    type: WidgetTypes.renewables,
  },
  {
    title: 'Model Graphics',
    description:
      'Visualize and monitor in real-time, graphics from the key weather models impacting the markets',
    icon: 'grid_on',
    type: WidgetTypes.model,
  },
  {
    title: 'Forecast Analysis',
    description:
      'Visualize and Monitor in real-time the weather model forecasts alongside the AG2 forecast for individual locations in tabular format',
    icon: 'grid_on',
    permission: ['Location_Page', 'Forecast_Analysis'],
    type: WidgetTypes.analysis,
  },
  {
    title: 'Weighted Forecast Graph',
    description:
      'Visualize and monitor in real-time, the latest AG2 and weather model forecasts in graphical format for a specified region/country',
    icon: 'grid_on',
    permission: ['Weighted_Forecasts'],
    type: WidgetTypes.weightedGraph,
  },
  {
    title: 'Weighted Forecast Table',
    description:
      'Visualize and monitor in real-time, the latest AG2 and weather model forecasts in tabular format for a specified region/country',
    icon: 'grid_on',
    permission: ['Weighted_Forecasts'],
    type: WidgetTypes.weighted,
  },
  {
    title: 'Power Demand (Load)',
    description:
      'Visualize and monitor in real-time, the latest AG2 and RTO power demand forecasts alongside the latest observations',
    icon: 'grid_on',
    type: WidgetTypes.demand,
    permission: ['Load_Forecasts'],
  },
  {
    title: 'Blurb',
    description:
      'Monitor the latest short status insights (Blurbs) from the AG2 Meteorology Team to ensure you don’t miss any key information',
    icon: 'grid_on',
    type: WidgetTypes.blurb,
    permission: ['Forecaster_Blurbs'],
  },
];

export const CommonWidgetPosition: WidgetPositionConfig = {
  x: 0,
  y: 0,
  h: 0,
  w: 0,
};

export const CommonWidgetItems: WidgetItemsConfig = {
  minItemCols: 2,
  maxItemCols: 5,
};

export const defaultDemandLocation: LoadRegion = {
  locationId: 'ERCOT_TOTAL',
  displayName: 'ERCOT RTO',
  subZones: [
    { locationId: 'ERCOT_TOTAL', displayName: 'ERCOT RTO', subZones: null },
    { locationId: 'ERCOT_COAST', displayName: 'Coast', subZones: null },
    {
      locationId: 'ERCOT_SOUTHCENTRAL',
      displayName: 'South Central',
      subZones: null,
    },
    { locationId: 'ERCOT_EAST', displayName: 'East', subZones: null },
    { locationId: 'ERCOT_NORTH', displayName: 'North', subZones: null },
    {
      locationId: 'ERCOT_NORTHCENTRAL',
      displayName: 'North Central',
      subZones: null,
    },
    { locationId: 'ERCOT_WEST', displayName: 'West', subZones: null },
    { locationId: 'ERCOT_SOUTH', displayName: 'South', subZones: null },
    { locationId: 'ERCOT_FARWEST', displayName: 'Far West', subZones: null },
  ],
};
