import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { EnhancedDataServicesApiKeyAccess } from '../../types';

@Component({
  selector: 'firebird-web-enhanced-data-services-access-label',
  templateUrl: './enhanced-data-services-access-label.component.html',
  styleUrls: ['./enhanced-data-services-access-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhancedDataServicesAccessLabelComponent {
  @Input() public access!: EnhancedDataServicesApiKeyAccess;
  @Input() public subEntitlements!: string[];

  public get allowed(): boolean {
    return this.access === 'allowed';
  }

  public get isSubEntitlements(): boolean {
    return !!this.subEntitlements.length;
  }

  @HostBinding('class')
  private get hostClasses(): string {
    return [this.access, this.isSubEntitlements ? 'with-dataset' : '']
      .filter(Boolean)
      .join(' ');
  }

  public trackBySubEntitlement(_: number, subEntitlement: string): string {
    return subEntitlement;
  }
}
