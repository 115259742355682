import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  switchMap,
  combineLatest,
  of,
} from 'rxjs';
import { DateWithTimezonePipe } from '@firebird-web/shared/pipes';
import {
  ForecastValuesState,
  ServerResponse,
} from '@firebird-web/shared-interfaces';
import {
  isEqual,
  isEmpty,
  isNull,
  concat,
  findIndex,
  find,
  pullAt,
} from 'lodash';
import { HttpClient } from '@angular/common/http';
import { environment } from '@firebird-web/shared-config';

type ContinentDates = {
  region: string;
  type: string;
  initTime: string;
};

type WidgetForecastValue = {
  widgetId: string;
  runDate: string;
  compareRunDate: string;
};

type WidgetContinents = {
  widgetId: string;
  continents: string[];
};

@Injectable()
export class SelectForecastService {
  private readonly selectedForecastValue: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private readonly obsForecastValue: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private readonly comparisonForecastValue: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);
  private readonly forecastValuesState: BehaviorSubject<ForecastValuesState> =
    new BehaviorSubject<ForecastValuesState>({
      showObsForecast: false,
      showComparison: false,
    });
  private readonly selectedContinentsStream = new BehaviorSubject<string[]>([]);
  private readonly widgetsContinentsStream = new BehaviorSubject<
    WidgetContinents[]
  >([]);
  private readonly widgetsForecastValue = new BehaviorSubject<
    WidgetForecastValue[]
  >([]);
  private readonly selectedForecastLabel: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(
    private readonly timeZonePipe: DateWithTimezonePipe,
    private readonly http: HttpClient
  ) {}

  public setForecastValuesState(value: { [key: string]: boolean }): void {
    this.forecastValuesState.next({
      ...this.forecastValuesState.value,
      ...value,
    });
  }

  public setSelectedContinentsStream(continents: string[]): void {
    this.selectedContinentsStream.next(continents);
  }

  public getSelectedContinentsDates(
    continent: string,
    isComparisonForecast = false,
    isCustomList = false
  ): Observable<ContinentDates[]> {
    return this.selectedContinentsStream.pipe(
      filter((continents) => continents.length > 1 || isCustomList),
      combineLatestWith(
        this.selectedForecastValue,
        this.comparisonForecastValue
      ),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      filter(([, runDate, compareDate]) => !!runDate && !!compareDate),
      switchMap(([continents, runDate, compareRunDate]) =>
        this.fetchContinentDates(
          this.createContinentsDateFetchUrl({
            runDate,
            continent,
            compareRunDate,
            isComparisonForecast,
          }),
          continents
        )
      ),
      map((continentsDates) => {
        if (continentsDates.length === 2 && !isCustomList) {
          const [{ initTime: fcstInitTime }, { initTime: obsInitTime }] =
            continentsDates;
          this.setForecastValue(fcstInitTime);
          this.setObsForecastValue(obsInitTime);
        }
        return this.setInitTimeForContinentsDates(continentsDates);
      })
    );
  }

  public getWidgetsContinentsDates({
    widgetId,
    continent,
    isComparisonForecast,
  }: {
    widgetId: string;
    continent: string;
    isComparisonForecast: boolean;
  }): Observable<ContinentDates[]> {
    return combineLatest([
      this.widgetsContinentsStream,
      this.widgetsForecastValue,
    ]).pipe(
      filter(
        ([continents, forecastValue]) =>
          !isEmpty(continents) || !isEmpty(forecastValue)
      ),
      distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
      map(([continents, forecastValue]) => {
        const { runDate, compareRunDate } = this.getWidgetsForecastValueById(
          forecastValue,
          widgetId
        );

        return {
          runDate,
          compareRunDate,
          continents: (
            find(continents, ({ widgetId: id }) => id === widgetId)
              ?.continents as string[]
          ).map((continent) => (continent === 'MEX' ? 'NA' : continent)),
        };
      }),
      filter(({ runDate, compareRunDate }) => !!runDate && !!compareRunDate),
      switchMap(({ continents, runDate, compareRunDate }) =>
        this.fetchContinentDates(
          this.createContinentsDateFetchUrl({
            runDate,
            continent,
            compareRunDate,
            isComparisonForecast,
          }),
          continents
        )
      ),
      map((continentsDates) =>
        this.setInitTimeForContinentsDates(continentsDates)
      )
    );
  }

  public setWidgetsContinentsStream(continents: WidgetContinents): void {
    const widgetsContinents = this.widgetsContinentsStream.value;
    const idx = findIndex(
      widgetsContinents,
      ({ widgetId }) => widgetId === continents.widgetId
    );

    if (idx !== -1) {
      pullAt(widgetsContinents, [idx]);
    }

    this.widgetsContinentsStream.next(concat(widgetsContinents, continents));
  }

  public setWidgetForecastValue(forecastValue: WidgetForecastValue): void {
    const widgetsForecastValue = this.widgetsForecastValue.value;
    const idx = findIndex(
      widgetsForecastValue,
      ({ widgetId }) => widgetId === forecastValue.widgetId
    );

    if (idx !== -1) {
      pullAt(widgetsForecastValue, [idx]);
    }

    this.widgetsForecastValue.next(concat(widgetsForecastValue, forecastValue));
  }

  public getWidgetsContinentsStream(): Observable<WidgetContinents[]> {
    return this.widgetsContinentsStream;
  }

  public getSelectedContinentsStream(): Observable<string[]> {
    return this.selectedContinentsStream;
  }

  public getSelectedForecastLabelStream(): Observable<string | null> {
    return this.selectedForecastLabel.asObservable();
  }

  public setObsForecastValue(value: string): void {
    this.obsForecastValue.next(value);
  }

  public setComparisonForecastValue(value: string): void {
    this.comparisonForecastValue.next(value);
  }

  public setForecastValue(value: string): void {
    this.selectedForecastValue.next(value);
  }

  public setForecastLabel(value: string): void {
    this.selectedForecastLabel.next(value);
  }

  public getWidgetsForecastValueStream(
    widgetId: string | null | undefined
  ): Observable<string | null> {
    if (!widgetId) {
      return of(null);
    }

    return this.widgetsForecastValue.pipe(
      map((forecastValue) => {
        const { runDate } = this.getWidgetsForecastValueById(
          forecastValue,
          widgetId
        );

        return runDate ? this.timeZonePipe.transform(runDate) : '';
      }),
      distinctUntilChanged()
    );
  }

  public getForecastValueStream(): Observable<string | null> {
    return this.selectedForecastValue.pipe(
      map((value: string | null) =>
        value ? this.timeZonePipe.transform(value) : ''
      ),
      distinctUntilChanged()
    );
  }

  public getComparisonForecastValueStream(): Observable<string | null> {
    return this.comparisonForecastValue.pipe(
      map((value: string | null) =>
        value ? this.timeZonePipe.transform(value) : ''
      ),
      distinctUntilChanged()
    );
  }

  public getObsForecastValueStream(): Observable<string | null> {
    return this.obsForecastValue.pipe(
      map((value: string | null) =>
        value ? this.timeZonePipe.transform(value) : ''
      ),
      distinctUntilChanged()
    );
  }

  public getForecastValuesStateStream(): Observable<ForecastValuesState> {
    return this.forecastValuesState.pipe(
      distinctUntilChanged(
        (previous: ForecastValuesState, current: ForecastValuesState) =>
          isEqual(previous, current)
      )
    );
  }

  private fetchContinentDates(
    url: string,
    continents: string[]
  ): Observable<ContinentDates[]> {
    return this.http
      .get<ServerResponse<ContinentDates[]>>(url)
      .pipe(
        map(({ data }) =>
          data.filter(({ region }) => continents.includes(region))
        )
      );
  }

  private createContinentsDateFetchUrl({
    runDate,
    continent,
    compareRunDate,
    isComparisonForecast,
  }: {
    continent: string;
    runDate: string | null;
    compareRunDate: string | null;
    isComparisonForecast: boolean;
  }): string {
    if (isNull(runDate)) {
      return '';
    }

    const baseUrl = `api/v1/RunDate/${
      !isComparisonForecast ? 'forecast-run' : 'comparison'
    }-date-lists/custom-lists/${continent}`;
    const encodedForecastRunDate = encodeURIComponent(runDate);
    const encodedComparisonForecastValue = compareRunDate
      ? encodeURIComponent(compareRunDate)
      : '';

    return isComparisonForecast && compareRunDate
      ? `${environment.apiDomain}/${baseUrl}/${encodedForecastRunDate}/${encodedComparisonForecastValue}`
      : `${environment.apiDomain}/${baseUrl}/${encodedForecastRunDate}`;
  }

  private setInitTimeForContinentsDates(
    continentsDates: ContinentDates[]
  ): ContinentDates[] {
    return continentsDates.map(({ initTime, ...rest }) => ({
      ...rest,
      initTime: this.timeZonePipe.transform(initTime),
    }));
  }

  private getWidgetsForecastValueById(
    forecastValue: WidgetForecastValue[],
    widgetId: string
  ): WidgetForecastValue {
    return (
      find(forecastValue, ({ widgetId: id }) => id === widgetId) ??
      ({} as WidgetForecastValue)
    );
  }
}
