import {
  DemandIssueDates,
  LoadRunDateDropdownOptions,
  RunDateItem,
} from '../models/load.interfaces';
import { DropdownOption } from '../../../../shared/interfaces/src/lib';
import { ErcotTotalOption, FORECAST_OPTIONS } from '../models/load.models';
import { ForecastValues } from '../enums';
import moment from 'moment/moment';
import { ColDef } from 'ag-grid-community';
import { LoadHourlyCellComponent } from '../components/load-hourly-cell/load-hourly-cell.component';
import { first } from 'lodash';

export const getMappedRunDates = (
  runDates: RunDateItem[]
): LoadRunDateDropdownOptions[] => {
  return runDates.map((runDate) => ({
    value: runDate.runType,
    labelKey: runDate.label,
    isPermitted: runDate.hasPermission,
    localInitTime: runDate.localInitTime,
  }));
};

export const getRunDatesOptionsWithoutOldest = (
  runDates: LoadRunDateDropdownOptions[]
): LoadRunDateDropdownOptions[] => {
  const isAllOptionsPermitted = runDates.every((item) => item.isPermitted);

  if (!isAllOptionsPermitted) {
    const permittedRunDates = runDates.filter((value) => value.isPermitted);
    const oldestValueWithPermission = calculateOldestRunDate(permittedRunDates);

    return runDates.filter(
      (item) => item.value !== oldestValueWithPermission.value
    );
  }

  const oldestValue = calculateOldestRunDate(runDates);

  const oldestForecastsLength = runDates.filter((item) => {
    return item.localInitTime === oldestValue.localInitTime;
  });

  return oldestForecastsLength.length > 1
    ? runDates
    : runDates.filter((item) => item.value !== oldestValue.value);
};

export const calculateOldestRunDate = (
  runDates: LoadRunDateDropdownOptions[]
): LoadRunDateDropdownOptions => {
  return runDates.reduce((oldest, current) => {
    return moment(current.localInitTime).isBefore(moment(oldest.localInitTime))
      ? current
      : oldest;
  }, runDates[0]);
};

export const getCompareRunDates = (
  runDates: LoadRunDateDropdownOptions[],
  forecastRunDate: string
): LoadRunDateDropdownOptions[] => {
  const forecastRunDateOption = runDates.find(
    (item) => item.value === forecastRunDate
  );

  return runDates.filter((item) => {
    return (
      moment(item.localInitTime) <=
        moment(forecastRunDateOption?.localInitTime) &&
      item.value !== forecastRunDate
    );
  });
};

export const getForecastDataSource = (subRegion: string): DropdownOption[] => {
  if (ErcotTotalOption !== subRegion) {
    return FORECAST_OPTIONS.filter(
      (option) => option.value !== ForecastValues.NET_LOAD
    );
  }

  return FORECAST_OPTIONS;
};

export const getForecastColumn = (validDate: string): Partial<ColDef> => {
  return {
    field: validDate,
    headerName: validDate,
    suppressSizeToFit: false,
    minWidth: 58,
    headerComponentParams: {
      renderType: 'dayDate',
    },
    cellRenderer: LoadHourlyCellComponent,
  };
};

export const getIssuedDateValues = (
  runDates: RunDateItem[],
  forecastRun: string,
  compareRun: string
): DemandIssueDates => {
  const forecast = runDates.find((item) => item.runType === forecastRun);
  const compare = runDates.find((item) => item.runType === compareRun);

  return {
    forecastRunDate: forecast?.storageTime || '',
    forecastLabel: forecast?.sourceLabel || '',
    compareRunDate: compare?.storageTime || '',
    compareLabel: compare?.sourceLabel || '',
  };
};

export const checkIsValueExistInDataSource = (
  dataSource: LoadRunDateDropdownOptions[],
  value: string
): boolean => {
  const values = dataSource.filter(
    (item) => item.value === value && item.isPermitted
  );

  return !!values?.length;
};

export const getPermittedOptions = (
  source: LoadRunDateDropdownOptions[]
): LoadRunDateDropdownOptions[] => {
  return source.filter((item) => item.isPermitted);
};

export const getRunDateSelectedOptions = (
  runDates: RunDateItem[],
  forecastValue: string,
  compareValue: string
): Record<string, RunDateItem> => {
  const forecastOption = runDates.find(
    (item) => item.runType === forecastValue
  ) as RunDateItem;
  const comparisonOption = runDates.find(
    (item) => item.runType === compareValue
  ) as RunDateItem;

  return { forecastOption, comparisonOption };
};

export const getFirstValueByModel = (
  runDate: string,
  options: LoadRunDateDropdownOptions[]
): string => {
  const [model, _] = runDate.split('_');

  const availableOptions = getPermittedOptions(options);

  if (!model) {
    return first(availableOptions)?.value || '';
  }

  const optionByModel = availableOptions.filter((item) => {
    const [itemModel, _] = item.value.split('_');
    return itemModel === model;
  });

  return optionByModel?.length
    ? first(optionByModel)?.value || ''
    : first(availableOptions)?.value || '';
};
