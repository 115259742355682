<ng-container *ngIf="!isTraderEntity; else traderFollowInstructions">
  <ng-container *ngIf="allowed; else denied">
    <p class="header">
      {{ 'enhancedDataServicesApiKey' | translate }}
    </p>
    <ul>
      <li *ngFor="let apiKey of apiKeys; trackBy: trackByApiKey">
        <p>{{ apiKey }}</p>
        <button
          mat-icon-button
          [matTooltip]="'enhancedDataServicesCopyApiKeyTooltip' | translate"
          [attr.aria-label]="'enhancedDataServicesCopyApiKey' | translate"
          (click)="handleCopyToClipboardButtonClick(apiKey)"
        >
          <firebird-web-icon
            class="icon"
            name="copy-key"
            width="1.125rem"
            height="1.125rem"
          ></firebird-web-icon>
        </button>
      </li>
    </ul>
  </ng-container>
</ng-container>
<ng-template #denied>
  <p>
    {{ 'enhancedDataServicesApiKey' | translate }}
    {{ 'enhancedDataServicesNoApiKey' | translate }}
  </p>
</ng-template>
<ng-template #traderFollowInstructions>
  <p>{{ 'enhancedDataServicesTraderDataApiKey' | translate }}</p>
</ng-template>
