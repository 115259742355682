import { Component, Input } from '@angular/core';

@Component({
  selector: 'firebird-web-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss'],
})
export class InputTextComponent {
  @Input()
  formControlName!: string;

  @Input()
  id!: string;

  @Input()
  name!: string;

  @Input()
  labelKey!: string;

  @Input()
  placeholderKey!: string;

  @Input()
  disabled!: boolean;

  @Input()
  maxlength!: number;
}
