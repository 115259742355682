export type SinglePermission = string[];

export type MultiplePermissions = string[][];

export type Permissions = Array<string | string[]>;

export type HasViewPermissionDirectivePermissions =
  | Permissions
  | boolean
  | undefined;
