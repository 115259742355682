<div class="blurb-panel-wrapper">
  <div class="actions" *ngIf="showClearFiltersButton">
    <button (click)="clearFilters()" class="clear-filter">Clear Filters</button>
  </div>
  <form [formGroup]="blurbFilterForm">
    <div class="field">
      <p>Location</p>
      <mat-form-field floatLabel="never" class="multi-from-field">
        <mat-label>Select Locations</mat-label>
        <mat-select
          formControlName="locations"
          panelClass="custom-select-loc"
          multiple
          class="loc-select-styling"
        >
          <mat-select-trigger>
            {{ blurbFilterForm.get('locations')?.value?.length }}
            Selected</mat-select-trigger
          >
          <mat-option
            class="loc-option"
            *ngFor="
              let location of locationDataSource;
              trackBy: trackByLocation
            "
            [value]="location.name"
          >
            {{ location.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="field">
      <p>Keyword</p>
      <mat-form-field floatLabel="never" class="search-from-field">
        <mat-label [innerHTML]="keywordSearchLabelText"></mat-label>
        <input #keywordField matInput formControlName="keyWord" />
      </mat-form-field>
    </div>
    <div class="field">
      <p>Authors</p>
      <mat-form-field floatLabel="never" class="multi-from-field">
        <mat-label>Select Author</mat-label>
        <mat-select
          panelClass="custom-select-loc"
          formControlName="experts"
          multiple
        >
          <mat-select-trigger>
            {{ blurbFilterForm.get('experts')?.value?.length }}
            Selected
          </mat-select-trigger>
          <div *ngIf="expertDataSource$ | async as blurbExpert">
            <mat-option
              class="author-option"
              *ngFor="let author of blurbExpert.data; trackBy: trackByAuthor"
              [value]="author"
              [matTooltip]="author"
              matTooltipPosition="right"
            >
              <span>{{ author }}</span>
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
  <div class="actions">
    <button (click)="savePanel()" class="save">Save</button>
    <button (click)="closePanel()" class="cancel">X-Cancel</button>
  </div>
</div>
