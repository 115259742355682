export const weightedDegreeDayLabelsMap: Record<string, string> = {
  'Gas Weighted HDD': 'GWHDD',
  'Pop Weighted CDD': 'PWCDD',
  'Electric Weighted CDD': 'EWCDD',
  'Oil Weighted HDD': 'OWHDD',
  'Electric Weighted HDD': 'EWHDD',
  'Pop Weighted HDD': 'PWHDD',
};

export const modelRunsLabels: Record<string, string> = {
  GFS_OP: 'GFS OP',
  GFS_ENS: 'GFS ENS',
  GEM_OP: 'GEM OP',
  GEM_ENS: 'GEM ENS',
  ECMWF_OP: 'ECMWF OP',
  ECMWF_ENS: 'ECMWF ENS',
};
