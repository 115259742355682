import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import {
  RenewablesNaDefaultLegendState,
  RenewablesNaErcotLegendState,
  WidgetTypes,
} from '../../constants';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  distinctUntilChanged,
  filter,
  map,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  LocationData,
  RenewableRegionData,
  RenewablesEnsSpread,
  RenewablesProducts,
  WIDGET_RANGE_SELECTOR_OPTIONS,
} from 'libs/renewable/src/lib/models/renewables.models';
import { IRenewablesParams } from '../../interfaces';
import { WidgetService } from '../../services/widget.service';
import { RenewablesService } from 'libs/renewable/src/lib/services/renewables.service';
import { EurRenewablesService } from '../../../../../renewable/src/lib/modules/eur-renewables/services/eur-renewables.service';
import { continents } from '@firebird-web/shared-constants';
import { StartEurWidgetLegendState } from '../../../../../renewable/src/lib/constants';
import {
  EUR_LABELS,
  NA_LABELS,
  PRODUCT_TYPES_LABELS,
  UNIT_TYPES,
} from './renewables-panel.constants';
import { MatSelectChange } from '@angular/material/select';
import { DropdownOption } from 'libs/shared/interfaces/src/lib';
import { isProductAvailable } from 'libs/renewable/src/lib/types';
import { IOption } from 'libs/shared/ui/src/lib/components/dropdown/dropdown.component';
import {
  ChartLegendState,
  ChartScaleOptions,
  NaChartResponse,
  WindfarmPool,
  WindfarmPools,
} from 'libs/renewable/src/lib/modules/na-renewables/types';
import { first as _first, isEqual, uniq } from 'lodash';
import { NaRenewablesService } from '../../../../../renewable/src/lib/modules/na-renewables/services/na-renewables.service';
import { RenewablesWidgetPanelDialogData } from '../../types';
import { RenewablesProductsEnum } from '../../../../../renewable/src/lib/enums';
import { NaRenewablesNetworkingService } from '../../../../../renewable/src/lib/modules/na-renewables/services/na-renewables-networking.service';

@Component({
  selector: 'firebird-web-renewables-panel',
  templateUrl: './renewables-panel.component.html',
  styleUrls: ['./renewables-panel.component.scss'],
  providers: [RenewablesService, DatePipe, DecimalPipe],
})
export class RenewablesPanelComponent implements OnInit, OnDestroy {
  public selectedGraphUnit = 'megawatts';
  public selectedRenewablesType$ = new BehaviorSubject<
    RenewablesProducts | undefined
  >(undefined);
  public availableProducts: RenewablesProducts[] = [];
  public get renewableTypes(): DropdownOption<RenewablesProducts>[] {
    return this.availableProducts.map((product) => ({
      value: product,
      labelKey: PRODUCT_TYPES_LABELS[product],
    }));
  }

  public continent$ = new BehaviorSubject<string>('');
  public selectedPool$ = new BehaviorSubject<Pick<
    WindfarmPool,
    'id' | 'name'
  > | null>(null);
  public selectedRange: {
    labelKey: string;
    value: { min: number; max: number };
  } = WIDGET_RANGE_SELECTOR_OPTIONS[1];
  public get labels(): { labelText: string; class: string }[] {
    return this.isNa(this.continent$.getValue()) ? NA_LABELS : EUR_LABELS;
  }
  public graphUnit = UNIT_TYPES;
  public rangeOptions = WIDGET_RANGE_SELECTOR_OPTIONS;
  public region$ = new BehaviorSubject<string>('');
  public ensembleSpreadDropdownOptions: DropdownOption[] = [];
  public selectedEnsembleSpread!: RenewablesEnsSpread;
  public isAggregate = false;
  public naChartData: ChartScaleOptions | null = null;
  public naAllChartLegendStates: ChartLegendState | null = null;
  public isNaChartLegendLoading = false;

  private naChartLegend: ChartLegendState | null = null;
  private selectedRenewableSize = 'full';
  private ensembleSpreadList: RenewablesEnsSpread[] = [];
  public locationData: LocationData<RenewableRegionData[]>[] | null = null;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: RenewablesWidgetPanelDialogData,
    private readonly dialogRef: MatDialogRef<RenewablesPanelComponent>,
    private readonly dashboard: DashboardService,
    private readonly widgetService: WidgetService,
    private readonly eurRenewablesService: EurRenewablesService,
    private readonly renewableService: RenewablesService,
    private readonly renewablesNetworkService: NaRenewablesNetworkingService,
    private readonly naRenewablesService: NaRenewablesService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initData();
    this.getRenewablesGraphEnsembleSpreads();
    this.subscribeToGetNaChartLegend();
    this.getFirstPermittedPool();
    this.subscribeToRenewablesTypeChange();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.selectedPool$.complete();
    this.continent$.complete();
    this.region$.complete();
    this.selectedRenewablesType$.complete();
  }

  public graphUnitChange({ value }: MatSelectChange): void {
    this.selectedGraphUnit = value;
  }

  public ensSpreadChange({ value }: MatSelectChange): void {
    this.selectedEnsembleSpread = { label: value, value };
  }

  public handleLocationChange(event: {
    continent: string;
    region: IOption & isProductAvailable;
    pool: WindfarmPool & { value: string };
    isAggregate: boolean;
  }): void {
    this.isAggregate = event.isAggregate;
    this.continent$.next(event.continent);
    this.region$.next(event.region.value);
    this.selectedPool$.next({
      name: event?.pool?.name,
      id: event?.pool?.id,
    });
  }

  public renewablesTypeChange({ value }: MatSelectChange): void {
    this.selectedRenewablesType$.next(value);
  }

  public rangeChange({ value }: MatSelectChange): void {
    this.selectedRange = { labelKey: value, value };
  }

  public updateNaChartLegendState(legend: ChartLegendState): void {
    this.naChartLegend = legend;
  }

  public onSave(): void {
    const widgetType = this.isNa(this.continent$.getValue())
      ? WidgetTypes.naRenewables
      : WidgetTypes.renewables;
    const { id: poolId = '', name: pool = '' } =
      this.selectedPool$.getValue() ??
      ({} as Pick<WindfarmPool, 'id' | 'name'>);
    const continent = this.continent$.getValue();

    if (this.data.isNew) {
      const widgetObj: IRenewablesParams = {
        ensSpread: this.selectedEnsembleSpread,
        legend: this.getChartLegendStateForSave(continent, this.data.isNew),
        region: this.region$.getValue(),
        continent: this.continent$.getValue(),
        product:
          this.selectedRenewablesType$.getValue() ||
          RenewablesProductsEnum.WIND,
        pool,
        poolId,
        isAggregate: this.isAggregate,
        ensSpreads: this.ensembleSpreadList,
        scale: this.selectedGraphUnit,
        range: {
          label: this.selectedRange.labelKey,
          value: this.selectedRange.value,
        },
        size: this.selectedRenewableSize === 'full' ? 4 : 2,
        type: widgetType,
        gridstack: {},
      };
      this.dashboard.createWidget(widgetObj, widgetType);
      this.widgetService.updateWidgetLocationState({
        type: widgetType,
        region: this.region$.getValue(),
        continent: this.continent$.getValue(),
      });
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    } else {
      this.data.widgetConfig.ensSpread = this.selectedEnsembleSpread;
      this.data.widgetConfig.continent = this.continent$.getValue();
      this.data.widgetConfig.legend = {
        ...this.getChartLegendStateForSave(continent, this.data.isNew),
      };
      this.data.widgetConfig.region = this.region$.getValue();
      this.data.widgetConfig.pool = pool;
      this.data.widgetConfig.poolId = poolId;
      this.data.widgetConfig.isAggregate = this.isAggregate;
      this.data.widgetConfig.continent =
        this.continent$.getValue() ?? continents.EUROPE;
      this.data.widgetConfig.range = {
        label: this.selectedRange.labelKey,
        value: this.selectedRange.value,
      };
      this.dashboard.updateWidgetType(this.data.widgetId, widgetType);
      this.data.widgetConfig.product =
        this.selectedRenewablesType$.getValue() as RenewablesProducts;
      this.data.widgetConfig.scale = this.selectedGraphUnit;
      this.dialogRef.close(this.data.widgetConfig);
    }
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  private initData() {
    this.renewableService.prepareGetLocationsData(true).subscribe((data) => {
      this.locationData = data;
      this.availableProducts = uniq(
        this.locationData
          .filter(({ isPermitted }) => !!isPermitted)
          .flatMap((location) => {
            return this.getAvailableProducts(
              location.continent,
              location.regions
            );
          })
      );
      if (this.data.isNew) {
        this.configureNewWidget();
      } else {
        this.configureExistingWidget();
      }
    });
  }

  private isContinentPermitted(continent: string): boolean {
    return (
      this.locationData
        ?.find((location) => location.continent === continent)
        ?.regions.some((region) =>
          this.isNaRegionForProductPermitted(region)
        ) || false
    );
  }

  private isNaRegionPermitted(
    region: RenewableRegionData,
    type?: RenewablesProducts
  ): boolean {
    if (!type) {
      return (
        !!(region.isPermittedSolar || region.isPermittedWind) &&
        !!(region.isWindAvailable || region.isSolarAvailable)
      );
    }
    if (type === RenewablesProductsEnum.SOLAR) {
      return !!region.isPermittedSolar && !!region.isSolarAvailable;
    }
    if (type === RenewablesProductsEnum.WIND) {
      return !!region.isPermittedWind && !!region.isWindAvailable;
    }
    if (type === RenewablesProductsEnum.TOTAL) {
      return (
        !!(region.isPermittedSolar && region.isPermittedWind) &&
        !!(region.isWindAvailable && region.isSolarAvailable)
      );
    }
    return false;
  }

  private subscribeToRenewablesTypeChange(): void {
    this.selectedRenewablesType$.subscribe(() => {
      if (!this.isContinentPermitted(this.continent$.getValue())) {
        this.setFirstPermittedContinent(this.getPermittedLocations());
        return;
      }

      const selectedRegion = this.locationData
        ?.find((location) => location.continent === this.continent$.getValue())
        ?.regions.find((region) => region.value === this.region$.getValue());

      if (
        !selectedRegion ||
        !this.isNaRegionForProductPermitted(selectedRegion)
      ) {
        this.setFirstPermittedRegion(
          this.getPermittedLocations(),
          this.continent$.getValue()
        );
      }
    });
  }

  private isNaRegionForProductPermitted(region: RenewableRegionData): boolean {
    if (
      this.selectedRenewablesType$.getValue() === RenewablesProductsEnum.TOTAL
    ) {
      return (
        !!(region.isPermittedSolar && region.isPermittedWind) &&
        !!(region.isWindAvailable && region.isSolarAvailable)
      );
    }
    if (
      this.selectedRenewablesType$.getValue() === RenewablesProductsEnum.SOLAR
    ) {
      return !!region.isPermittedSolar && !!region.isSolarAvailable;
    }
    if (
      this.selectedRenewablesType$.getValue() === RenewablesProductsEnum.WIND
    ) {
      return !!region.isPermittedWind && !!region.isWindAvailable;
    }
    return false;
  }

  private getPermittedLocations(): LocationData<RenewableRegionData[]>[] {
    if (!this.locationData) {
      return [];
    }
    return this.locationData
      .map((locations) => this.getAllPermittedRegions(locations))
      .filter((continent) => continent.regions.length > 0);
  }

  private setFirstPermittedContinent(
    permittedLocations: LocationData<RenewableRegionData[]>[]
  ): void {
    if (permittedLocations.length === 0 || !permittedLocations[0].continent) {
      return;
    }
    this.continent$.next(permittedLocations[0].continent);
    this.setFirstPermittedRegion(
      permittedLocations,
      permittedLocations[0].continent
    );
    this.region$.next(permittedLocations[0].regions[0].value);
  }

  private setFirstPermittedRegion(
    permittedLocations: LocationData<RenewableRegionData[]>[],
    continent: string
  ): void {
    const permittedContent = permittedLocations.find(
      (location) => location.continent === continent
    );
    const permittedRegions = permittedContent?.regions?.filter((region) => {
      return this.isNa(continent)
        ? this.isNaRegionPermitted(
            region,
            this.selectedRenewablesType$.getValue()
          )
        : region.isPermitted;
    });
    const permittedRegion = permittedRegions?.[0]?.value ?? '';
    if (!permittedRegion) {
      return;
    }
    this.region$.next(permittedRegion);
  }

  private getAllPermittedRegions(
    location: LocationData<RenewableRegionData[]>
  ): LocationData<RenewableRegionData[]> {
    return {
      continent: location.continent,
      regions: location.regions.filter((region) =>
        this.isNa(location.continent)
          ? this.isNaRegionPermitted(region)
          : region.isPermitted
      ),
    };
  }

  private getAvailableProducts(
    continent: string,
    regions: RenewableRegionData[]
  ): RenewablesProductsEnum[] {
    const allProducts = [
      RenewablesProductsEnum.WIND,
      RenewablesProductsEnum.SOLAR,
      RenewablesProductsEnum.TOTAL,
    ];
    if (continent === continents.EUROPE) {
      return allProducts;
    }
    const isSolarAvailable = regions.some((region) => region.isPermittedSolar);
    const isWindAvailable = regions.some((region) => region.isPermittedWind);

    if (isSolarAvailable && isWindAvailable) {
      return allProducts;
    }

    if (isSolarAvailable) {
      return [RenewablesProductsEnum.SOLAR];
    }

    if (isWindAvailable) {
      return [RenewablesProductsEnum.WIND];
    }

    return [];
  }

  private configureNewWidget(): void {
    this.selectedRenewablesType$.next(_first(this.availableProducts));
    this.setFirstPermittedContinent(this.getPermittedLocations());
  }

  private getChartLegendStateForSave(
    continent: string,
    isNew: boolean
  ): ChartLegendState {
    if (this.isNa(continent) && this.naChartLegend) {
      return this.naChartLegend;
    }

    return isNew ? this.getDefaultLegendState() : this.getSavedLegendState();
  }

  private subscribeToGetNaChartLegend(): void {
    this.selectedPool$
      .pipe(
        combineLatestWith(this.region$, this.continent$),
        filter((props) => {
          const continent = props.at(-1) as string | undefined;

          return !!continent && this.isNa(continent);
        }),
        filter(
          (
            props
          ): props is [Pick<WindfarmPool, 'id' | 'name'>, string, string] =>
            props.every(Boolean)
        ),
        distinctUntilChanged((prevProps, currProps) =>
          isEqual(prevProps, currProps)
        ),
        filter(([pool, region]) => this.isEditNaChartLegend(region, pool?.id)),
        tap(() => {
          this.isNaChartLegendLoading = true;
        }),
        switchMap(([pool, region]) =>
          this.naRenewablesService.getGraphData(
            region,
            pool?.id,
            this.selectedRenewablesType$.getValue() as RenewablesProducts
          )
        ),
        tap(() => {
          this.isNaChartLegendLoading = false;
        }),
        filter((chartData) =>
          this.naRenewablesService.isGraphObservationsAvailable(chartData)
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((data) => {
        const defaultLegendState = this.getDefaultLegendState();

        this.naChartData = (data as NaChartResponse).wind;
        this.naAllChartLegendStates = defaultLegendState;
        this.naChartLegend = defaultLegendState;
        this.cdr.markForCheck();
      });
  }

  private isEditNaChartLegend(
    nextRegion: string | undefined,
    nextPoolId: string | undefined
  ): boolean {
    if (this.data.isNew) {
      return true;
    }

    const {
      widgetConfig: { region, poolId },
    } = this.data;

    return (
      region !== nextRegion ||
      poolId !== nextPoolId ||
      !isEqual(this.data?.widgetContext?.chartData, this.naChartData)
    );
  }

  private getDefaultLegendState(): Record<string, boolean> {
    if (!this.isNa(this.continent$.getValue())) {
      return StartEurWidgetLegendState;
    }

    return this.region$.getValue().includes('ERCOT')
      ? RenewablesNaErcotLegendState
      : RenewablesNaDefaultLegendState;
  }

  private getSavedLegendState(): Record<string, boolean> {
    if (
      this.data.widgetConfig.region !== this.region$.getValue() ||
      this.data.widgetConfig.pool !== this.selectedPool$.getValue()?.name
    ) {
      return this.getDefaultLegendState();
    }

    return this.data.widgetConfig.legend;
  }

  private configureExistingWidget(): void {
    if (!this.data) {
      return;
    }
    this.selectedRange = WIDGET_RANGE_SELECTOR_OPTIONS.filter(
      (rangeOption) =>
        rangeOption.value.max === this.data.widgetConfig.range.value.max &&
        rangeOption.value.min === this.data.widgetConfig.range.value.min
    )[0];
    this.selectedGraphUnit = this.data.widgetConfig.scale;
    this.naChartData = this.data?.widgetContext?.chartData;
    this.naAllChartLegendStates = this.data?.widgetContext?.allLegendStates;
    this.naChartLegend = this.data?.widgetConfig?.legend;
    this.isAggregate = this.data.widgetConfig?.isAggregate ?? false;

    if (
      !this.availableProducts.includes(
        this.data.widgetConfig.product as RenewablesProductsEnum
      )
    ) {
      this.selectedRenewablesType$.next(_first(this.availableProducts));
      this.setFirstPermittedContinent(this.getPermittedLocations());
      return;
    }
    this.selectedRenewablesType$.next(
      this.data.widgetConfig.product as RenewablesProductsEnum
    );
    const availableLocations = this.getPermittedLocations();
    if (
      !this.data.widgetConfig.continent ||
      !availableLocations.find(
        (location) => location.continent === this.data.widgetConfig.continent
      )
    ) {
      this.setFirstPermittedContinent(availableLocations);
      return;
    }
    this.continent$.next(this.data.widgetConfig.continent);

    const availableRegions = availableLocations.find(
      (location) => location.continent === this.data.widgetConfig.continent
    )?.regions;
    if (
      !availableRegions?.find(
        (region) => region.value === this.data.widgetConfig.region
      )
    ) {
      this.setFirstPermittedRegion(
        availableLocations,
        this.data.widgetConfig.continent
      );
      return;
    }
    this.region$.next(this.data.widgetConfig.region);

    this.selectedPool$.next({
      name: this.data.widgetConfig.pool,
      id: this.data.widgetConfig.poolId,
    });
  }

  private getFirstPermittedPool(): void {
    this.selectedRenewablesType$
      .pipe(
        combineLatestWith(this.region$, this.continent$),
        filter(
          ([product, region, continent]) =>
            !!product && !!region && this.isNa(continent)
        ),
        distinctUntilChanged(
          ([prevProduct, prevRegion], [currProduct, currRegion]) =>
            prevRegion === currRegion && prevProduct === currProduct
        ),
        switchMap(([product, region]) =>
          this.renewablesNetworkService.getWindFarmPools(
            region,
            product as RenewablesProducts
          )
        )
      )
      .subscribe((poolsData) => {
        if (!poolsData || !poolsData.aggregates?.length) return;
        const firstAggregate = _first(poolsData.aggregates) as WindfarmPool;
        if (!this.isPoolPermittedBasedOnType(poolsData)) {
          this.selectedPool$.next({
            name: firstAggregate?.name,
            id: firstAggregate?.id,
          });
        }

        this.isAggregate = true;
      });
  }
  private isPoolPermittedBasedOnType(poolsData: WindfarmPools): boolean {
    const selectedPoolId = this.selectedPool$.getValue()?.id;

    return poolsData.aggregates?.some(
      (pool: WindfarmPool) => pool.id === selectedPoolId
    );
  }
  private getRenewablesGraphEnsembleSpreads(): void {
    combineLatest([this.continent$, this.region$])
      .pipe(
        filter(
          ([continent, region]) =>
            //TODO will need a more broad check if we add more than ERCOT to the Total option
            !!region &&
            !!continent &&
            !this.isNa(continent) &&
            region !== 'ERCOT'
        ),
        switchMap(([, region]) =>
          this.eurRenewablesService.getGraphRenewables$(region)
        ),
        map(({ data }) => this.eurRenewablesService.buildEnsembleSpreads(data)),
        takeUntil(this.destroyed$)
      )
      .subscribe((ensembleSpreads) => {
        this.ensembleSpreadList = ensembleSpreads;
        this.ensembleSpreadDropdownOptions =
          this.eurRenewablesService.buildEnsembleSpreadDropdownOptions(
            ensembleSpreads
          );
        this.defineSelectedEnsembleSpread(ensembleSpreads);
      });
  }

  private defineSelectedEnsembleSpread(
    ensembleSpreads: RenewablesEnsSpread[]
  ): void {
    let ensembleSpread = this.data?.isNew
      ? this.selectedEnsembleSpread
      : this.data?.widgetConfig?.ensSpread;

    if (
      !ensembleSpread ||
      !ensembleSpreads.map(({ value }) => value).includes(ensembleSpread.value)
    ) {
      ensembleSpread =
        this.eurRenewablesService.renewablesDefaultEnsembleSpreads;
    }

    this.selectedEnsembleSpread = ensembleSpread;
  }

  private isNa(continent: string): boolean {
    return continent === continents.NORTH_AMERICA;
  }
}
