import { EurRenewablesPrimaryLens } from '../modules/eur-renewables/enums/eur-renewables.enums';
import { RenewablesRunDates } from '../modules/eur-renewables/types';

export const isEurRenewablesForecastDifference = (
  primaryLens: EurRenewablesPrimaryLens
): boolean => primaryLens === EurRenewablesPrimaryLens.FORECAST_DIFFERENCE;

export const isUpgradeToLatestEurRenewablesForecast = (
  forecastList: RenewablesRunDates[],
  selectedForecast: RenewablesRunDates
): boolean => !selectedForecast || !forecastList.includes(selectedForecast);
