<div class="infobox">
  <div class="icon" *ngIf="iconName">
    <firebird-web-icon
      [name]="iconName"
      width="15px"
      height="15px"
    ></firebird-web-icon>
  </div>
  <div>
    <ng-content> </ng-content>
  </div>
  <div class="cta-btn" *ngIf="showButton">
    <firebird-web-button
      [disabled]="false"
      [style]="'quaternary'"
      [labelKey]="labelKey"
    >
    </firebird-web-button>
  </div>
</div>
