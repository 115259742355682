import { Permissions } from '@firebird-web/acl';

export const CLIPBOARD_BLURB_POST_ID_QUERY_PARAM = 'blurbPostID';

export const BLURB_LOCATIONS: Array<{ name: string; permission: Permissions }> =
  [
    { name: 'North America', permission: ['Forecaster_Blurbs', 'NA'] },
    { name: 'Global', permission: ['Forecaster_Blurbs', 'Global'] },
    { name: 'Europe', permission: ['Forecaster_Blurbs', 'EUR'] },
    { name: 'Asia', permission: ['Forecaster_Blurbs', 'ASIA'] },
    { name: 'North America/Mexico', permission: ['Forecaster_Blurbs', 'NA'] },
  ];
