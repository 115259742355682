<div class="button-wrapper">
  <firebird-web-button
    *ngIf="isEditVisible"
    [style]="'additional'"
    [ignoreHover]="true"
  >
    <firebird-web-icon name="edit" width="19px" (click)="edit.emit()">
    </firebird-web-icon>
  </firebird-web-button>
</div>
<div class="button-wrapper">
  <firebird-web-button
    *ngIf="isEditVisible"
    [style]="'additional'"
    [ignoreHover]="true"
    [matMenuTriggerFor]="copyPopup"
    #copyTrigger="matMenuTrigger"
  >
    <firebird-web-icon name="copy" width="16px"> </firebird-web-icon>
  </firebird-web-button>
</div>
<!-- FB-2806: Hide button until share feature is working -->
<!-- <div class="button-wrapper">
  <firebird-web-button [style]="'additional'" [ignoreHover]="true">
    <firebird-web-icon name="send" width="19px"> </firebird-web-icon>
  </firebird-web-button>
</div> -->
<div class="button-wrapper">
  <firebird-web-button
    [style]="'additional'"
    [ignoreHover]="true"
    [matMenuTriggerFor]="deletePopup"
    #deleteTrigger="matMenuTrigger"
  >
    <firebird-web-icon name="delete" width="13px"> </firebird-web-icon>
  </firebird-web-button>
</div>

<mat-menu
  #deletePopup="matMenu"
  class="favorite-location-delete-panel"
  yPosition="above"
>
  <div (click)="$event.stopPropagation()">
    <div class="delete-msg" translate="deleteMsg"></div>
    <div class="button-panel">
      <firebird-web-button
        [type]="'button'"
        [style]="'basic'"
        [name]="'Cancel'"
        [labelKey]="'xcancel' | translate"
        (clickEvent)="deleteTrigger.closeMenu()"
      >
      </firebird-web-button>
      <firebird-web-button
        [type]="'button'"
        [style]="'primary'"
        [name]="'Yes'"
        [labelKey]="'delete' | translate"
        (clickEvent)="deleteList.emit(list)"
      >
      </firebird-web-button>
    </div>
  </div>
</mat-menu>

<mat-menu #copyPopup="matMenu" [class]="getDuplicateClass()" yPosition="above">
  <div (click)="$event.stopPropagation()">
    <div class="header" translate="duplicateFavoriteList"></div>
    <div class="duplicate-msg" translate="duplicateMsg"></div>
    <div *ngIf="isNameAlreadyExists" class="duplicate-error-msg">
      <firebird-web-icon
        name="alert-circle"
        width="20px"
        height="20px"
      ></firebird-web-icon>
      <div translate="duplicateErrorMsg"></div>
    </div>
    <div class="name-wrapper">
      <span translate="name"></span>
      <input
        type="text"
        [(ngModel)]="copyListName"
        (keyup)="copyListNameChange()"
        placeholder="Enter a Name"
      />
    </div>
    <div class="button-panel">
      <firebird-web-button
        [type]="'button'"
        [style]="'basic'"
        [name]="'Cancel'"
        [labelKey]="'xcancel' | translate"
        (clickEvent)="this.isNameAlreadyExists = false; copyTrigger.closeMenu()"
      >
      </firebird-web-button>
      <firebird-web-button
        [type]="'button'"
        [style]="'primary'"
        [name]="'Yes'"
        [labelKey]="'createList' | translate"
        (clickEvent)="copyListHandler({ listName: copyListName, id: list?.id })"
      >
      </firebird-web-button>
    </div>
  </div>
</mat-menu>
