import { Injectable } from '@angular/core';
import { NaRenewablesNetworkingService } from './na-renewables-networking.service';
import { RenewablesService } from '../../../services/renewables.service';
import { WsService } from '../../../../../../ws/src/lib/ws.service';
import { combineLatestWith, filter, map, Observable, startWith } from 'rxjs';
import {
  NA_RENEWABLES_UPDATE_ENGINE_CONTINENT,
  NA_RENEWABLES_UPDATE_ENGINE_MODEL,
  NA_RENEWABLES_UPDATE_ENGINE_SOLAR_DATA_TABLE,
  NA_RENEWABLES_UPDATE_ENGINE_WIND_DATA_TABLES,
} from '../constants';
import { WSModelsMessage } from '@firebird-web/ws';
import { NaChartResponse, WindfarmPools } from '../types';
import { ContinentPickerResult } from '../../../../../../continent-picker/src/lib/types';
import { RenewablesProducts } from '../../../models';
import { RenewablesProduct } from '../../../types';
import { RenewablesProductsEnum } from '../../../enums';
import { isEmpty } from 'lodash';

@Injectable()
export class NaRenewablesService {
  constructor(
    private readonly wsService: WsService,
    private readonly dataService: RenewablesService,
    private readonly networking: NaRenewablesNetworkingService
  ) {}

  public prepareGetWindFarmPools(
    region: string,
    product: RenewablesProducts
  ): Observable<WindfarmPools> {
    return this.networking.getWindFarmPools(region, product);
  }

  public getGraphData(
    regionId: string,
    poolId: string,
    product: RenewablesProducts
  ): Observable<NaChartResponse> {
    return this.networking.getNaGraph(regionId, poolId, product);
  }

  public isGraphObservationsAvailable({ wind }: NaChartResponse): boolean {
    return Object.values(wind).some((chartItem) =>
      chartItem.some(
        ({ modelDisplayName, series }) =>
          modelDisplayName !== 'No Observations Available' || !isEmpty(series)
      )
    );
  }

  public subscribeAndCheckForUpdates(): Observable<WSModelsMessage[]> {
    return this.wsService.wsMessage$.pipe(
      combineLatestWith(
        this.dataService.locationChangeMessage$,
        this.dataService.currentProduct$
      ),
      filter(
        (
          values
        ): values is [
          WSModelsMessage[],
          ContinentPickerResult,
          RenewablesProduct
        ] => {
          const [, location] = values;

          return Boolean(location?.region.value);
        }
      ),
      filter(([messages, location, product]) =>
        this.isUpdateAvailable(messages, location.region.value, product.value)
      ),
      map(([messages]) => messages),
      startWith([])
    );
  }

  public subscribeAndCheckForUpdatesWidget(
    region: string,
    product: string
  ): Observable<WSModelsMessage[]> {
    return this.wsService.wsMessage$.pipe(
      filter((messages) => this.isUpdateAvailable(messages, region, product)),
      startWith([])
    );
  }

  private isUpdateAvailable(
    messages: WSModelsMessage[],
    region: string,
    product: string
  ): boolean {
    if (product === RenewablesProductsEnum.TOTAL) {
      return (
        this.isSolarUpdateAvailable(messages, region) ||
        this.isWindUpdateAvailable(messages, region)
      );
    }
    if (product === RenewablesProductsEnum.SOLAR) {
      return this.isSolarUpdateAvailable(messages, region);
    }
    return this.isWindUpdateAvailable(messages, region);
  }

  private isWindUpdateAvailable(
    messages: WSModelsMessage[],
    region: string
  ): boolean {
    return messages.some(
      ({ model, dataTable, view }) =>
        view === NA_RENEWABLES_UPDATE_ENGINE_CONTINENT &&
        [
          NA_RENEWABLES_UPDATE_ENGINE_MODEL,
          `${NA_RENEWABLES_UPDATE_ENGINE_MODEL}_${region}`,
        ].includes(model) &&
        NA_RENEWABLES_UPDATE_ENGINE_WIND_DATA_TABLES.includes(
          dataTable as string
        )
    );
  }

  private isSolarUpdateAvailable(
    messages: WSModelsMessage[],
    region: string
  ): boolean {
    return messages.some(
      ({ dataTable, view }) =>
        view === region &&
        NA_RENEWABLES_UPDATE_ENGINE_SOLAR_DATA_TABLE === (dataTable as string)
    );
  }
}
