import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromHourlyForecastRunDates from './+state/hourly-forecast-run-dates/hourly-forecast-run-dates.reducer';
import { HourlyForecastRunDatesEffects } from './+state/hourly-forecast-run-dates/hourly-forecast-run-dates.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromHourlyForecastRunDates.HOURLY_FORECAST_RUN_DATES_FEATURE_KEY,
      fromHourlyForecastRunDates.hourlyForecastRunDatesReducer
    ),
    EffectsModule.forFeature([HourlyForecastRunDatesEffects]),
  ],
})
export class HourlyForecastRunDatesStoreModule {}
