<ng-container *ngIf="allowed; else denied">
  <ng-container *ngIf="isSubEntitlements; else withoutDataset">
    <div class="allowed-dataset-label">
      <firebird-web-icon
        width="1.5rem"
        height="1.5rem"
        name="check-circle"
      ></firebird-web-icon>
      <p>{{ 'enhancedDataServicesAccessDataset' | translate }}</p>
    </div>
    <ul>
      <li
        *ngFor="
          let subEntitlement of subEntitlements;
          trackBy: trackBySubEntitlement
        "
      >
        {{ subEntitlement }}
      </li>
    </ul>
  </ng-container>
  <ng-template #withoutDataset>
    <firebird-web-icon
      width="1.5rem"
      height="1.5rem"
      name="check-circle"
    ></firebird-web-icon>
    <p>{{ 'enhancedDataServicesAccess' | translate }}</p>
  </ng-template>
</ng-container>
<ng-template #denied>
  <p>
    {{ 'enhancedDataServicesNoAccess' | translate }}
    <a
      href="mailto:sales@atmosphericg2.com?subject=Interested in trialing SUN API Services"
    >
      sales@atmosphericg2.com
    </a>
  </p>
</ng-template>
