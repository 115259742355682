export interface ExportAsCSVReq {
  continent: string;
  siteId: string;
  tempUnit: string;
  runDate: string;
  secondaryLens: string;
  precipUnit: string;
  windUnit: string;
  day?: number;
}

export interface GenerateFileNameReq {
  applicationName: string;
  cityName: string;
  runDate: string;
}

export interface ExportAsCSVWddAg2ForecastReq {
  continent: string;
  siteId: string;
  tempUnit: string;
  secondaryLens: string;
  showDecimal: boolean;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddModelSingleForecast {
  continent: string;
  siteId: string;
  tempUnit: string;
  isBiasCorrected: boolean;
  secondaryLens: string;
  modelRun: string;
  showDecimal: boolean;
  weighted: string;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddISOCountryAG2Forecast {
  continent: string;
  siteId: string;
  secondaryLens: string;
  tempUnit: string;
  showDecimal: boolean;
  showDiff: boolean;
  showNormal: boolean;
}

export interface ExportAsCSVWddBCFAG2Forecast {
  continent: string;
  siteId: string;
  secondaryLens: string;
  tempUnit: string;
  showDecimal: boolean;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddBCFSingleModelForecast {
  continent: string;
  siteId: string;
  secondaryLens: string;
  modelRun: string;
  showDecimal: boolean;
  parameter: string;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddIsoCountryModelRunTimeForecast {
  continent: string;
  siteId: string;
  parameter: string;
  runDate: string;
  tempUnit: string;
  secondaryLens: string;
  isBiasCorrected: boolean;
  showDecimal: boolean;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddDegreeDayModelRunTimeForecast {
  continent: string;
  siteId: string;
  parameter: string;
  runDate: string;
  tempUnit: string;
  secondaryLens: string;
  isBiasCorrected: boolean;
  showDecimal: boolean;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVBCFModelRunTimeForecast {
  continent: string;
  siteId: string;
  parameter: string;
  runDate: string;
  tempUnit: string;
  secondaryLens: string;
  showDecimal: boolean;
  showNormal: boolean;
  showDiff: boolean;
}

export interface ExportAsCSVWddSingleForecast {
  continent: string;
  siteId: string;
  tempUnit: string;
  isBiasCorrected: boolean;
  modelRun: string;
  showDecimal: boolean;
  parameter: string;
  secondaryLens: string;
  showNormal: boolean;
  showDiff: boolean;
}
