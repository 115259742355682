export type DataSetKeys =
  | 'max'
  | 'min'
  | 'avg'
  | 'maxDiff'
  | 'minDiff'
  | 'avgDiff';

export type ImageDataSet = Record<DataSetKeys, CachedImageData[]>;

export interface BaseImageData {
  pathURL: string;
  thumbPathURL?: string;
}

export interface NamedImageData extends BaseImageData {
  name: string;
}

export interface ExtendedImageData extends NamedImageData {
  issueDate: string;
  priorDate: string;
  dataSource?: string;
}

export type CachedImageData<T = ExtendedImageData> = T & {
  image: HTMLImageElement;
  thumb?: HTMLImageElement;
};

export interface ImageVisibilityData {
  day: string;
  key: DataSetKeys;
  isDelta: boolean;
  isHovered?: boolean;
  isLocked?: boolean;
}

export interface GridGraphicsData<T> {
  name: string;
  images: T[];
}

export interface Viewer {
  data: GridGraphicsData<BaseImageData>[] | BaseImageData[] | null;
  selected: string | null;
}
