<div class="dialog-config">
  <div class="labels">
    <div>Weather Parameter</div>
    <div>Location</div>
    <div>Forecast</div>
    <div *ngIf="isForecastDifference">Compare To</div>
    <div *ngIf="showSecondaryLens()">Climatology</div>
  </div>
  <div class="dropdowns">
    <div class="weather-params">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="filterDropDownValues"
        [value]="selectedFilter"
        (changeEvent)="changeFilter($event)"
        type="variation-five"
      >
      </firebird-web-dropdown>
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="getCorrectPrimaryLensDropdown(selectedFilter)"
        [value]="selectedPrimLens"
        (changeEvent)="primLensChange($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
    <div (click)="locationChange()" class="location">
      <span>
        {{ getContinentById(selectedContinent) | async | titlecase }}
        <firebird-web-icon name="chevron-right" height="11px" width="11px">
        </firebird-web-icon>
        {{ selectedRegion }}
        <firebird-web-icon
          name="chevron-down"
          height="11px"
          width="11px"
        ></firebird-web-icon
      ></span>
    </div>
    <div class="run-date">
      <p *ngIf="!isForecastDifference">
        {{ 'summaryWidgetConfigPanelForecastRunDate' | translate }}
      </p>
      <p *ngIf="isForecastDifference">
        {{
          'summaryWidgetConfigPanelForecastRunDateForecastDifference'
            | translate
        }}
      </p>
    </div>
    <div class="compare-run-date" *ngIf="isForecastDifference">
      <p>
        {{
          'summaryWidgetConfigPanelCompareToRunDateForecastDifference'
            | translate
        }}
      </p>
    </div>
    <div *ngIf="showSecondaryLens()" class="second-lens-container">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="secondaryLensValues"
        [value]="selectedSecondaryLens"
        (changeEvent)="secondaryLensChange($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div>
    <firebird-web-checkbox
      *ngIf="showDecimalCheckbox()"
      labelKey="showDecimals"
      [checked]="showDecimal"
      [inputClass]="'widget'"
      (changeEvent)="toggleDecimal($event)"
    ></firebird-web-checkbox>
    <!-- <div *ngIf="data.isNew" class="size-selector">
      <firebird-web-dropdown
        labelKey="label"
        class="multi-dd-start widget-panel"
        [options]="sumTableSizes"
        [value]="selectedSumTableSize"
        (changeEvent)="sizeChange($event)"
        type="variation-five"
      ></firebird-web-dropdown>
    </div> -->
  </div>
</div>
<div class="close-save">
  <span (click)="onSave()" class="save">Save</span>
  <span (click)="onClose()" class="cancel">X-Cancel</span>
</div>
