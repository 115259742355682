import { ChartRange, IRangeSelectorOption } from './chart.type';
import { RenewablesProductsEnum, TableMapsToggle } from '../enums';
import { IOption } from '../../../../shared/ui/src/lib/components/dropdown/dropdown.component';
import { ContinentData } from '../../../../continent-picker/src/lib/types';
import {
  isProductAvailable,
  isProductPermitted,
  RenewablesProduct,
} from '../types';
import { Permissions } from '@firebird-web/acl';

export interface IRenewablesRegion {
  value: string;
  label: string;
}

export interface IRenewablesSubRegionDTO {
  data: IRenewablesSubRegion[];
  status: string;
  version: string;
}

export interface IRenewablesSubRegion {
  name: string;
  displayName: string;
}

export interface RenewablesEnsSpread {
  value: string;
  label: string;
}

export interface ITimeRange {
  label: string;
  value: ChartRange;
}

export interface IRenewablesGraphScale {
  value: string;
  label: string;
}

export interface RenewablesState {
  subRegion: string;
  product: Record<'label' | 'value', string>;
  rangeOption: IRangeSelectorOption;
  graphScale: IRenewablesGraphScale;
  typeForecast: Record<'label' | 'value', string>;
  ensSpread: RenewablesEnsSpread;
  legendState: boolean[];
  mainLegendState: Record<string, boolean>;
  modelLastUpdates: Record<string, Date>;
  legendEnableState: Record<string, boolean>;
  lastDaySeen: number;
  lastMonthSeen: number;
}

export const GRAPH_SCALES: Array<IRenewablesGraphScale> = [
  {
    value: 'megawatts',
    label: 'Megawatts',
  },
  {
    value: 'percentCapacity',
    label: 'Percent Capacity',
  },
];

export type RenewablesProducts = 'solar' | 'wind' | 'total';

export const PRODUCTS: RenewablesProduct[] = [
  { value: RenewablesProductsEnum.WIND, label: 'Wind' },
  { value: RenewablesProductsEnum.SOLAR, label: 'Solar' },
  { value: RenewablesProductsEnum.TOTAL, label: 'Total' },
];

export const WIND_PRODUCT: RenewablesProduct[] = [
  { value: RenewablesProductsEnum.WIND, label: 'Wind' },
];

export const SOLAR_PRODUCT: RenewablesProduct[] = [
  { value: RenewablesProductsEnum.SOLAR, label: 'Solar' },
];

//TODO "Already there" probably would be a great idea to add HasSolar prop to API and return it together with region data
export const ONLY_WIND_REGIONS = ['NO', 'FI', 'SE', 'TR'];

export const RANGE_SELECTOR_OPTIONS: Array<IRangeSelectorOption> = [
  {
    label: 'Previous 24 Hrs',
    value: {
      min: -1,
      max: 0,
    },
  },
  {
    label: '24 Hrs',
    value: {
      min: 0,
      max: 1,
    },
  },
  {
    label: '48 Hrs',
    value: {
      min: 0,
      max: 2,
    },
  },
  {
    label: '1-7 Days',
    value: {
      min: 0,
      max: 7,
    },
  },
  {
    label: '8-14 Days',
    value: {
      min: 7,
      max: 14,
    },
  },
  {
    label: '1-5 Days',
    value: {
      min: 0,
      max: 5,
    },
  },
  {
    label: '6-10 Days',
    value: {
      min: 5,
      max: 10,
    },
  },
  {
    label: '11-15 Days',
    value: {
      min: 10,
      max: 15,
    },
  },
  {
    label: '1-15 Days',
    value: {
      min: 0,
      max: 15,
    },
  },
  {
    label: 'Full Range',
    value: {
      min: -5,
      max: 15,
    },
  },
];

export const WIDGET_RANGE_SELECTOR_OPTIONS: Array<{
  labelKey: string;
  value: { min: number; max: number };
}> = [
  {
    labelKey: 'Previous 24 Hrs',
    value: {
      min: -1,
      max: 0,
    },
  },
  {
    labelKey: '24 Hrs',
    value: {
      min: 0,
      max: 1,
    },
  },
  {
    labelKey: '48 Hrs',
    value: {
      min: 0,
      max: 2,
    },
  },
  {
    labelKey: '1-7 Days',
    value: {
      min: 0,
      max: 7,
    },
  },
  {
    labelKey: '1-15 Days',
    value: {
      min: 0,
      max: 15,
    },
  },
  {
    labelKey: 'Full Range',
    value: {
      min: -5,
      max: 15,
    },
  },
];

export interface Continent {
  id: string;
  text: string;
  permission?: Permissions;
}

export type LocationData<T> = {
  continent: 'EUR' | 'NA' | 'MEX';
  isPermitted?: boolean;
  regions: T;
};

export type RenewableRegionData = IOption &
  isProductAvailable &
  isProductPermitted;

export type RegionData = {
  id: string;
  displayName: string;
} & isProductAvailable &
  isProductPermitted;

export type LocationPickerDataSources = {
  [key in TableMapsToggle]: ContinentData[];
};
