import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'localTimezone',
  pure: true,
})
export class LocaTimezonePipe implements PipeTransform {
  transform(value: string | number | undefined) {
    if (!value) {
      return value;
    }

    const date = new Date(value);
    const timezoneAbbr = date
      .toLocaleTimeString('en', { timeZoneName: 'short' })
      .split(' ')[2];
    const formattedDate = moment(value).format('MMM D, YYYY hh:mma');

    return `${formattedDate} ${timezoneAbbr}`;
  }
}
