import { Component, Input } from '@angular/core';
@Component({
  selector: 'firebird-web-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss'],
})
export class InfoboxComponent {
  @Input()
  showButton = false;

  @Input()
  labelKey!: string;

  @Input()
  iconName = 'information-circle';
}
