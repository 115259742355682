<header class="header">
  <h3>{{ 'cityLocationSelectionDialogHeading' | translate }}</h3>
  <button
    mat-icon-button
    mat-dialog-close
    type="reset"
    aria-label="Close dialog"
  >
    <firebird-web-icon
      class="close-icon"
      name="cross"
      width="1.25rem"
      height="1.25rem"
    ></firebird-web-icon>
  </button>
</header>
<mat-divider></mat-divider>
<section class="content">
  <div class="location-selection">
    <ng-container *ngIf="!isCustomLocationSelected; else customListMenuBtn">
      <firebird-web-menu-button
        class="continent-menu-btn"
        [disabled]="isLoading"
        [matMenuTriggerFor]="continentMenu"
        [text]="(selectedContinent$ | async)?.name || ''"
      ></firebird-web-menu-button>
      <firebird-web-menu-button
        class="region-menu-btn"
        [disabled]="isLoading"
        [matMenuTriggerFor]="regionMenu"
        [text]="(selectedRegion$ | async) || ''"
      ></firebird-web-menu-button>
    </ng-container>
    <ng-template #customListMenuBtn>
      <firebird-web-menu-button
        class="continent-menu-btn"
        [disabled]="isLoading"
        [matMenuTriggerFor]="continentMenu"
        [text]="(selectedCustomLocationList$ | async)?.listName || ''"
      ></firebird-web-menu-button>
    </ng-template>
  </div>
  <mat-divider></mat-divider>
  <div class="locations" [class.loading]="isLoading">
    <ng-container *ngIf="!isLoading; else loader">
      <ng-container
        *ngIf="!isCustomLocationSelected; else customLocationListTemplate"
      >
        <ul *ngFor="let cityChunk of cityList">
          <li
            role="button"
            tabindex="0"
            [class.active]="isCityActive(city)"
            *ngFor="let city of cityChunk"
            (click)="onCitySelected(city)"
            (keydown.enter)="onCitySelected(city)"
            (keydown.space)="onCitySelected(city)"
          >
            {{ city.city }}
          </li>
        </ul>
      </ng-container>
      <ng-template #customLocationListTemplate>
        <ul *ngFor="let customLocationChunk of customLocationList">
          <li
            role="button"
            tabindex="0"
            [class.active]="isCustomLocationActive(customLocation)"
            *ngFor="let customLocation of customLocationChunk"
            (click)="onCustomLocationSelected(customLocation)"
            (keydown.enter)="onCustomLocationSelected(customLocation)"
            (keydown.space)="onCustomLocationSelected(customLocation)"
          >
            {{ customLocation.displayName }}
          </li>
        </ul>
      </ng-template>
    </ng-container>
    <ng-template #loader>
      <firebird-web-loader></firebird-web-loader>
    </ng-template>
  </div>
</section>

<mat-menu class="locations-menu-panel" #continentMenu="matMenu">
  <ng-container *ngIf="!isWidget">
    <button
      mat-menu-item
      [class.active]="
        (selectedCustomLocationList$ | async)?.id === customList.id
      "
      *ngFor="let customList of customLocationLists$ | async"
      (click)="onCustomLocationChange(customList)"
      [attr.aria-label]="'Custom locations list' + customList.listName"
    >
      {{ customList.listName }}
    </button>
  </ng-container>
  <mat-divider></mat-divider>
  <button
    mat-menu-item
    [class.active]="
      (selectedCustomLocationList$ | async) === null &&
      (selectedContinent$ | async)?.id === continent.id
    "
    *ngFor="let continent of continentList$ | async"
    (click)="onContinentChange(continent)"
    [attr.aria-label]="continent.text + ' continent'"
  >
    <div
      [hasViewPermission]="[continent.id || '']"
      [alignRight]="true"
      key="PoolSet"
    >
      {{ continent.text }}
    </div>
  </button>
</mat-menu>

<mat-menu #regionMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let region of regionList$ | async"
    [class.active]="(selectedRegion$ | async) === region.regionName"
    [attr.aria-label]="
      region.regionName +
      (region.regionName === allCitiesOptionKey ? '' : ' region')
    "
    (click)="onRegionChange(region.regionName)"
  >
    {{ region.regionName }}
  </button>
</mat-menu>
