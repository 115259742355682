<div class="vertical-tabs component">
  <ul
    [ngStyle]="{ width: width }"
    [ngClass]="{
      'primary-layout': type === 'primary',
      'secondary-layout': type === 'secondary'
    }"
  >
    <li
      *ngFor="let menu of menus; let index = index; trackBy: trackByFnGroup"
      (click)="onChangeTab(index)"
      [ngClass]="{
        'selected-primary-tab': menu.isActive && type === 'primary',
        'selected-secondary-tab': menu.isActive && type === 'secondary'
      }"
    >
      {{ menu.labelKey | translate }}
    </li>
  </ul>
</div>
