import { CitiesEntity } from '@firebird-web/cities-store';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { createAction, props } from '@ngrx/store';

export const updateRunDates = createAction(
  '[CompLocation Store] Update Run Dates',
  props<{ runDates: RunDatesEntity[] }>()
);
export const updateRunDatesFailure = createAction(
  '[CompLocation Store] Update Run Dates Failure',
  props<{ error: any }>()
);
export const updateCities = createAction(
  '[CompLocation Store] Update Cities',
  props<{ cities: CitiesEntity[] }>()
);
export const updateCitiesFailure = createAction(
  '[CompLocation Store] Update cities Failure',
  props<{ error: any }>()
);
export const resetCitiesAndRunDates = createAction(
  '[CompLocation Store] Reset Cities and RunDates'
);
export const changeLocationAction = createAction(
  '[CompLocation Store] Change Location',
  props<{ payload: { continent: string; region: string } }>()
);
