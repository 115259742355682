/**
 * Interface for the 'Settings' data
 */
export interface SettingsEntity {
  // TODO: delete
  id: string | number; // Primary ID
  name: string;
}

export type Cities = {
  siteId: string;
  displayName: string;
  regionName: string;
  continent: string;
  continentName: string;
  // TODO: change to isSite on UI and BE sides
  isSIte: number;
};
