import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@firebird-web/shared-config';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { LocationData, RegionData } from '../models';

@Injectable()
export class RenewablesNetworkingService {
  constructor(private readonly http: HttpClient) {}

  public getLocationsData(
    isWidget = false
  ): Observable<LocationData<RegionData[]>[]> {
    const url = `${environment.apiDomain}/api/v1/windcast/regions/${
      isWidget ? 'true' : ''
    }`;
    return this.http.get<LocationData<RegionData[]>[]>(url);
  }

  public getMapsLocationsData(): Observable<LocationData<RegionData[]>[]> {
    const url = `${environment.apiDomain}/api/v1/staticGraphics/windcast/regions`;
    return this.http
      .get<ServerResponse<LocationData<RegionData[]>[]>>(url)
      .pipe(map(({ data }) => data));
  }
}
