import { Tab } from '@firebird-web/shared-interfaces';
import {
  CustomSeries,
  ForecastDataSet,
  IRangeSelectorOption,
} from './load.interfaces';
import { DropdownOption } from 'libs/shared/interfaces/src/lib';
import { ForecastType, ForecastValues } from '../enums';

export const tableDataTypes: Tab[] = [
  { label: 'AG2', key: 'WSI' },
  { label: 'GFS Op', key: 'GFS_OP' },
  { label: 'ECMWF Op', key: 'ECMWF_OP' },
  { label: 'GFS Ens', key: 'GFS_ENS' },
  { label: 'ECMWF Ens', key: 'ECMWF_ENS' },
];

export const ErcotTotalOption = 'ERCOT_TOTAL';

export const FORECAST_OPTIONS: DropdownOption<'LOAD' | 'NET_LOAD'>[] = [
  {
    value: ForecastValues.LOAD,
    labelKey: 'Demand Forecast',
  },
  {
    value: ForecastValues.NET_LOAD,
    labelKey: 'Net Load Forecast',
  },
];

export const ForecastTypeOptions: DropdownOption[] = [
  {
    value: ForecastType.Forecast,
    labelKey: 'Forecast Values',
  },
  {
    value: ForecastType.ForecastDiff,
    labelKey: 'Forecast Differences',
  },
];

export const footerDataLabelMapping: {
  key: keyof ForecastDataSet;
  label: string;
}[] = [
  { key: 'maxLoad', label: 'Peak' },
  { key: 'avgLoad', label: 'Avg' },
  { key: 'offPeakLoad', label: 'Off-Peak Avg' },
  { key: 'peakLoad', label: 'On-Peak Avg' },
];

export const RANGE_SELECTOR_OPTIONS: Array<IRangeSelectorOption> = [
  {
    label: 'Previous 24 Hrs',
    value: {
      min: -1,
      max: 0,
    },
  },
  {
    label: '24 Hrs',
    value: {
      min: 0,
      max: 1,
    },
  },
  {
    label: '48 Hrs',
    value: {
      min: 0,
      max: 2,
    },
  },
  {
    label: '1-7 Days',
    value: {
      min: 0,
      max: 7,
    },
  },
  {
    label: '8-14 Days',
    value: {
      min: 7,
      max: 14,
    },
  },
  {
    label: '1-5 Days',
    value: {
      min: 0,
      max: 5,
    },
  },
  {
    label: '6-10 Days',
    value: {
      min: 5,
      max: 10,
    },
  },
  {
    label: '11-15 Days',
    value: {
      min: 10,
      max: 15,
    },
  },
  {
    label: '1-15 Days',
    value: {
      min: 0,
      max: 15,
    },
  },
  {
    label: 'Full Range',
    value: {
      min: -6,
      max: 15,
    },
  },
];

export const commonYaxis: Highcharts.YAxisOptions = {
  opposite: false,
  labels: {
    style: {
      fontWeight: 'bold',
      color: '#6C7C84',
    },
    formatter: ({ value }) => {
      const axisValue = value as number;
      if (axisValue >= 1000) {
        return `${axisValue / 1000}k`;
      }
      return axisValue.toString();
    },
  },
  title: {
    text: 'Mw',
    align: 'middle',
    rotation: 0,
    margin: 40,
    style: {
      fontSize: '14px',
      fontWeight: '700',
    },
  },
  allowDecimals: false,
};

export const baseChartOptions: Highcharts.Options = {
  plotOptions: {
    series: {
      stickyTracking: false,
      states: {
        hover: {
          enabled: true,
          lineWidth: 5,
        },
      },
    },
  },
  exporting: {
    enabled: true,
    buttons: {
      hover: {
        enabled: false,
      },
      contextButton: {
        symbol: 'url(/assets/icons/export-round-button.svg)',
        x: -15,
        y: 20,
        symbolSize: 40,
        height: 0,
        width: 0,
      },
    },
  },
  rangeSelector: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigator: {
    xAxis: {
      tickInterval: 86400000,
    },
    enabled: true,
    maskInside: false,
    maskFill: 'rgba(255, 255, 255, 0.7)',
    series: {
      type: 'areaspline',
      fillOpacity: 0,
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1.5 },
        stops: [
          [0, 'rgba(117, 189, 188, 1)'],
          [1, 'rgba(117, 189, 188, 0)'],
        ],
      },
      lineWidth: 0,
    },
    adaptToUpdatedData: false,
    opposite: false,
  },
  credits: {
    enabled: false,
  },
  accessibility: { enabled: false },
  chart: {
    animation: false,
    zooming: {
      mouseWheel: {
        enabled: false,
      },
    },
    type: 'spline',
    style: {
      fontFamily: 'Plus Jakarta Sans, sans-serif',
    },
    plotBorderWidth: 1,
  },
  xAxis: {
    ordinal: false,
    minTickInterval: 4 * 60 * 60 * 1000,
    lineColor: '#D9D9D9',
    lineWidth: 2,
    labels: {
      style: {
        fontWeight: 'bold',
        color: '#6C7C84',
      },
    },
    gridLineColor: '#D9D9D9',
    gridLineWidth: 1,
    title: {
      text: '',
      style: {
        color: '#012e59',
        fontWeight: '500',
      },
    },
    type: 'datetime',
    allowDecimals: false,
  },
  yAxis: commonYaxis,
  tooltip: {
    split: false,
    shared: true,
    useHTML: true,
    backgroundColor: '#FFFFFF',
    borderColor: '#FFFFFF',
    className: 'tooltip',
    outside: true,
  },
};

const ISO_COLOR = '#CDCCCC';

export const seriesModelRunMap: { [key: string]: Partial<CustomSeries> } = {
  '3km_GRAF': {
    color: 'rgb(150, 75, 0)',
  },
  WSI: {
    color: '#000000',
    lineWidth: 3,
  },
  MISO: {
    color: ISO_COLOR,
  },
  ERCOT: {
    color: ISO_COLOR,
  },
  CAISO: {
    color: ISO_COLOR,
  },
  NYISO: {
    color: ISO_COLOR,
  },
  ISONE: {
    color: ISO_COLOR,
  },
  PJM: {
    color: ISO_COLOR,
  },
  SPP: {
    color: ISO_COLOR,
  },
  OBS: {
    color: '#2EA330',
    lineWidth: 3,
  },
  OBS_INSTANTANEOUS: {
    color: '#2EA330',
    lineWidth: 2,
  },
  OBS_NET: {
    color: '#2EA330',
    lineWidth: 1,
  },
  GFS_OP: {
    color: '#53B9EC',
  },
  GFS_ENS: {
    color: '#EE8031',
  },
  ECMWF_OP: {
    color: '#1063ED',
    lineWidth: 3,
  },
  ECMWF_ENS: {
    color: '#F70F1B',
  },
};

export const startLegendState = {
  'AG2 Latest': true,
  'AG2 Previous': false,
  'AG2 Evening': false,
  'AG2 Morning': false,
  'MISO Latest': true,
  'ERCOT Latest': true,
  'CAISO Latest': true,
  'NYISO Latest': true,
  'ISONE Latest': true,
  'PJM Latest': true,
  'SPP Latest': true,

  'ECMWF OP': true,
  'ECMWF ENS': true,

  'GFS OP': true,
  'GFS ENS': true,
  OBS: true,
  'OBS INSTANTANEOUS': true,
} as const;

export const baseSeriesTemplate: CustomSeries = {
  id: '',
  name: '',
  type: 'spline',
  yAxis: 0,
  zIndex: 2,
  lineIcon: 'line',
  dashStyle: 'Solid',
  lineWidth: 2,
  visible: false,
  marker: {
    enabled: false,
  },
  showInLegend: false,
  showInNavigator: false,
  data: [],
  svgIcon: `<svg width="100%" height="100%" viewBox="0 0 26 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="0.5" x2="26" y2="0.5" />
    </svg>`,
};
