import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  HOURLY_FORECAST_RUN_DATES_FEATURE_KEY,
  HourlyForecastRunDatesState,
  hourlyForecastRunDatesAdapter,
} from './hourly-forecast-run-dates.reducer';

// Lookup the 'HourlyForecastRunDates' feature state managed by NgRx
export const getHourlyForecastRunDatesState =
  createFeatureSelector<HourlyForecastRunDatesState>(
    HOURLY_FORECAST_RUN_DATES_FEATURE_KEY
  );

const { selectAll, selectEntities } =
  hourlyForecastRunDatesAdapter.getSelectors();

export const getHourlyForecastRunDatesLoaded = createSelector(
  getHourlyForecastRunDatesState,
  // TODO: Return it after run dates refactoring
  (state: HourlyForecastRunDatesState) => state?.loaded
);

export const getHourlyForecastRunDatesError = createSelector(
  getHourlyForecastRunDatesState,
  (state: HourlyForecastRunDatesState) => state.error
);

export const getAllHourlyForecastRunDates = createSelector(
  getHourlyForecastRunDatesState,
  (state: HourlyForecastRunDatesState) => selectAll(state)
);

export const getHourlyForecastRunDatesEntities = createSelector(
  getHourlyForecastRunDatesState,
  (state: HourlyForecastRunDatesState) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getHourlyForecastRunDatesState,
  (state: HourlyForecastRunDatesState) => state.selectedId
);

export const getSelected = createSelector(
  getHourlyForecastRunDatesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
