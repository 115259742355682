<p>{{ 'enhancedDataServicesDocumentation' | translate }}</p>
<ul>
  <li *ngFor="let doc of documentationList; trackBy: trackByLabelKey">
    <a
      target="_blank"
      rel="noopener noreferrer"
      [href]="doc.link"
      [attr.aria-label]="doc?.ariaLabel || ''"
    >
      {{ doc.labelKey | translate }}
    </a>
  </li>
</ul>
