<div class="delimeter"></div>
<div class="alerts-notifications component">
  <h2 class="header">Alerts</h2>
  <div class="delimeter"></div>
  <ng-container *ngIf="(alertList$ | async)?.length; else emptyAlerts">
    <div class="alert-wrapper" *ngFor="let alert of alertList$ | async">
      <div class="alert-notification-row">
        <div class="notification-name-icon">
          <mat-icon
            *ngIf="alert.isInApp"
            color="primary"
            class="notification-icon"
            >notifications</mat-icon
          >
          <a class="notification-name" (click)="openAlert(alert)">{{
            alert.displayName
          }}</a>
        </div>
        <div class="date-wrapper">
          <p class="notification-date">
            {{
              alert?.updateTime?.toString()
                | dateWithTimezone: 'MMM dd, yyyy h:mm a'
            }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyAlerts>
    <p class="empty-alerts-notifications">No Active Alerts</p>
  </ng-template>
  <h2 class="header">Notifications</h2>
  <div class="delimeter"></div>
  <ng-container
    *ngIf="(notificationList$ | async)?.length; else emptyNotification"
  >
    <div class="notification-wrapper">
      <div
        style="display: flex; flex-direction: column"
        *ngFor="let notification of notificationList$ | async"
      >
        <div class="alert-notification-row">
          <div class="notification-name-icon">
            <mat-icon
              *ngIf="notification.isInApp"
              color="primary"
              class="notification-icon"
              >notifications</mat-icon
            >
            <a
              class="notification-name"
              [ngClass]="{ 'no-link': !notification.linkUrl }"
              (click)="openPage(notification.linkUrl)"
              >{{ notification.displayText }}</a
            >
          </div>
          <div class="date-wrapper">
            <p class="notification-date">
              {{
                notification?.eventTime?.toString()
                  | dateWithTimezone: 'MMM dd, yyyy h:mm a'
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #emptyNotification>
    <p class="empty-alerts-notifications">No Active Notifications</p>
  </ng-template>
</div>
