import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Subject,
  timer,
  ReplaySubject,
  startWith,
  first,
  switchMap,
  filter,
  observeOn,
  animationFrameScheduler,
} from 'rxjs';
import { getRunDatesLoaded } from '../+state/run-dates/run-dates.selectors';
import { getHourlyForecastRunDatesLoaded } from '@firebird-web/hourly-forecast-run-dates-store';

@Injectable({
  providedIn: 'root',
})
export class RunDatesRefreshService {
  public midnightUpdateTrigger$ = new Subject<void>();
  public isReloaded$ = new ReplaySubject(1).pipe(startWith(true), first());

  public get isLastRunDateSelected() {
    return localStorage.getItem('isLastRunDateSelected') === 'true';
  }

  public set isLastRunDateSelected(isRunDateSelected: boolean) {
    localStorage.setItem(
      'isLastRunDateSelected',
      isRunDateSelected ? 'true' : 'false'
    );
  }

  constructor(private readonly store: Store) {
    const msPerDate = 24 * 60 * 60 * 1000;
    const now = new Date().getTime();
    const midnight = new Date().setHours(24, 0, 0, 0);
    const msToMidnight = midnight - now;

    timer(msToMidnight, msPerDate).subscribe(() => {
      this.midnightUpdateTrigger$.next();
    });
    this.store
      .select(getRunDatesLoaded)
      .pipe(
        filter((loaded) => loaded),
        switchMap(() => this.isReloaded$),
        observeOn(animationFrameScheduler)
      )
      .subscribe(() => {
        if (this.isLastRunDateSelected) {
          this.midnightUpdateTrigger$.next();
        }
      });

    this.store
      .select(getHourlyForecastRunDatesLoaded)
      .pipe(
        filter((loaded) => loaded),
        switchMap(() => this.isReloaded$),
        observeOn(animationFrameScheduler)
      )
      .subscribe(() => {
        if (this.isLastRunDateSelected) {
          this.midnightUpdateTrigger$.next();
        }
      });
  }
}
