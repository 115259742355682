import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { Notes, Years } from '../interfaces';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { EnhancedDataServicesApiKeys } from '../types';

@Injectable()
export class SettingsApiService {
  constructor(private readonly http: HttpClient) {}

  getNotesYears(): Observable<Years> {
    return this.http
      .get<ServerResponse<Years>>(
        `${environment.apiDomain}/api/v1/releaseNotes/years`
      )
      .pipe(map(({ data }: ServerResponse<Years>) => data));
  }

  getNotes(year: string): Observable<Notes[]> {
    return this.http
      .get<ServerResponse<Notes[]>>(
        `${environment.apiDomain}/api/v1/ReleaseNotes/notes/${year}`
      )
      .pipe(map(({ data }: ServerResponse<Notes[]>) => data));
  }

  public getEnhancedDataServicesApiKeys(): Observable<EnhancedDataServicesApiKeys> {
    return this.http.get<EnhancedDataServicesApiKeys>(
      `${environment.apiDomain}/${apiEndpoints.getEnhancedDataServicesApiKeys}`
    );
  }
}
