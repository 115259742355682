import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import * as RunDatesActions from './run-dates.actions';
import { RunDatesEntity } from './run-dates.models';

export const RUN_DATES_FEATURE_KEY = 'runDates';

export interface RunDatesState extends EntityState<RunDatesEntity> {
  selectedId?: string | number; // which RunDates record has been selected
  loaded: boolean; // has the RunDates list been loaded
  error?: string | null; // last known error (if any)
}

export interface RunDatesPartialState {
  readonly [RUN_DATES_FEATURE_KEY]: RunDatesState;
}

export const runDatesAdapter: EntityAdapter<RunDatesEntity> =
  createEntityAdapter<RunDatesEntity>();

export const initialRunDatesState: RunDatesState =
  runDatesAdapter.getInitialState({
    // set initial required properties
    loaded: false,
  });

const reducer = createReducer(
  initialRunDatesState,
  on(RunDatesActions.loadRunDatesSuccess, (state, { runDates }) =>
    runDatesAdapter.setAll(runDates, { ...state, loaded: true })
  ),
  on(RunDatesActions.clearRunDate, (state) =>
    runDatesAdapter.removeAll({ ...state, loaded: false })
  ),
  on(RunDatesActions.loadRunDatesFailure, (state, { error }) => ({
    ...state,
    error,
  }))
);

export function runDatesReducer(
  state: RunDatesState | undefined,
  action: Action
) {
  return reducer(state, action);
}
