import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnhancedDataServicesDocumentation } from '../../types';

@Component({
  selector: 'firebird-web-enhanced-data-services-documentation-list',
  templateUrl: './enhanced-data-services-documentation-list.component.html',
  styleUrls: ['./enhanced-data-services-documentation-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhancedDataServicesDocumentationListComponent {
  @Input() public documentationList!: EnhancedDataServicesDocumentation[];

  public trackByLabelKey(
    _: number,
    { labelKey }: EnhancedDataServicesDocumentation
  ): string {
    return labelKey;
  }
}
