import { Action, createReducer, on } from '@ngrx/store';

import * as ModelStoreActions from '../model-store.actions';

import { SinglePanelData } from '../model-store.models';
import {
  modelConfigsAdapter,
  modelDataAdapter,
  modelRunsAdapter,
} from '../model-store.adapters';

export const initialModelStoreState: SinglePanelData = {
  selectedConfig: null,
  runs: modelRunsAdapter.getInitialState({ loaded: false }),
  filterConfigs: modelConfigsAdapter.getInitialState({ loaded: false }),
  data: modelDataAdapter.getInitialState(),
  loaded: false,
  imagesLoaded: false,
  inUse: false,
};

const reducer = createReducer(
  initialModelStoreState,
  on(
    ModelStoreActions.changeSinglePanelConfigs,
    (state, { selectedConfig }) => ({
      ...state,
      selectedConfig,
    })
  ),
  on(ModelStoreActions.loadSinglePanelConfigs, (state) => ({
    ...state,
    filterConfigs: { ...state.filterConfigs, loaded: false },
  })),
  on(ModelStoreActions.loadSinglePanelConfigsSuccess, (state, { configs }) => {
    return {
      ...state,
      filterConfigs: modelConfigsAdapter.setAll(configs, {
        ...state.filterConfigs,
        error: null,
      }),
    };
  }),
  on(ModelStoreActions.loadSinglePanelModels, (state, { silentLoading }) => ({
    ...state,
    loaded: silentLoading ? state.loaded : false,
    imagesLoaded: false,
  })),
  on(ModelStoreActions.loadSinglePanelModelsSuccess, (state, { data }) => {
    const models = modelDataAdapter.setAll(data, state.data);
    return {
      ...state,
      data: models,
      loaded: true,
      imagesLoaded: true,
    };
  }),
  on(ModelStoreActions.loadSinglePanelRuns, (state) => ({
    ...state,
    runs: { ...state.runs, loaded: false },
  })),
  on(ModelStoreActions.loadSinglePanelRunsSuccess, (state, { runs }) => {
    return {
      ...state,
      runs: modelRunsAdapter.setAll(runs, {
        ...state.runs,
      }),
      error: null,
    };
  }),
  on(ModelStoreActions.setSinglePanelInUse, (state, { inUse }) => ({
    ...state,
    inUse,
  }))
);

export function singlePanelReducer(
  state: SinglePanelData | undefined,
  action: Action
) {
  return reducer(state, action);
}
