import { IBlurbFilter } from '../interfaces/blurb.interface';

export const INITIAL_INSIGHT_FILTER_STATE: IBlurbFilter = {
  experts: [],
  locations: [],
  keyWord: '',
};

export const DEFAULT_INSIGHT_PAGE_NUMBER = 1;

export const DEFAULT_INSIGHT_PAGE_SIZE = 10;
