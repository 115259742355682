export const environment = {
  production: true,
  sentryEnv: 'dev',
  apiDomain: 'https://devbackend.atmosphericg2.com',
  wsDomain: 'https://devwebsocket.atmosphericg2.com',
  identityServer: 'https://dev-auth.atmosphericg2.com',
  clientid: 'ag2-trader-app',
  wsEnabled: true,
  disableLinksForEuropeRelease: true,
};
