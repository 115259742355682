import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  CompareLocationState,
  COMPARE_LOCATION_DATA,
} from './compare-location.reducer'; // Replace with the actual path

// Create a feature selector for the CompareLocationState
export const selectCompareLocationState =
  createFeatureSelector<CompareLocationState>(COMPARE_LOCATION_DATA);

// Create a selector to get the runDates from the CompareLocationState
export const selectRunDates = createSelector(
  selectCompareLocationState,
  (state: CompareLocationState) => state.runDates
);

// Create a selector to get the cities from the CompareLocationState
export const selectCities = createSelector(
  selectCompareLocationState,
  (state: CompareLocationState) => state.cities
);
