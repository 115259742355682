<div class="renewables-panel-container" *ngIf="!!locationData; else loader">
  <div class="labels-column">
    <div *ngFor="let label of labels" [ngClass]="label.class">
      {{ label.labelText }}
    </div>
  </div>
  <div class="dropdown-column">
    <div class="dropdowns">
      <span *ngIf="renewableTypes.length === 1" class="widget-panel-smaller">
        {{ renewableTypes[0].labelKey }}
      </span>
      <firebird-web-dropdown
        *ngIf="renewableTypes.length > 1"
        type="variation-five"
        class="multi-dd-start widget-panel-smaller"
        labelKey="label"
        width="140px"
        [value]="$any(selectedRenewablesType$ | async)"
        [options]="renewableTypes"
        (changeEvent)="renewablesTypeChange($event)"
      ></firebird-web-dropdown>
    </div>
    <div class="dropdown">
      <firebird-web-renewables-location
        [locationData]="locationData"
        [continent]="$any(continent$ | async)"
        [region]="$any(region$ | async)"
        [pool]="(selectedPool$ | async)?.name || ''"
        [isAggregate]="isAggregate"
        [product]="$any(selectedRenewablesType$ | async)"
        (changeEvent)="handleLocationChange($event)"
      ></firebird-web-renewables-location>
    </div>
    <div class="dropdowns">
      <firebird-web-dropdown
        class="multi-dd-start widget-panel-smaller"
        type="variation-five"
        labelKey="label"
        width="140px"
        [value]="selectedGraphUnit"
        [options]="graphUnit"
        (changeEvent)="graphUnitChange($event)"
      ></firebird-web-dropdown>
    </div>
    <div class="dropdown" *ngIf="(continent$ | async) !== 'NA'">
      <firebird-web-dropdown
        class="multi-dd-start widget-panel-smaller"
        type="variation-five"
        width="170px"
        [value]="selectedEnsembleSpread ? selectedEnsembleSpread.value : ''"
        [options]="ensembleSpreadDropdownOptions"
        [disabled]="
          !selectedEnsembleSpread || !ensembleSpreadDropdownOptions.length
        "
        (changeEvent)="ensSpreadChange($event)"
      ></firebird-web-dropdown>
    </div>
    <div class="range-dropdown">
      <firebird-web-dropdown
        class="multi-dd-start widget-panel-smaller"
        type="variation-five"
        width="100px"
        [value]="selectedRange.value"
        [options]="rangeOptions"
        (changeEvent)="rangeChange($event)"
      ></firebird-web-dropdown>
    </div>
  </div>
</div>
<div class="legend-wrapper" *ngIf="(continent$ | async) === 'NA'">
  <mat-divider class="legend-divider"></mat-divider>
  <ng-container *ngIf="!isNaChartLegendLoading; else legendLoader">
    <firebird-web-widget-na-renewables-chart-legend
      [chartData]="naChartData"
      [scale]="selectedGraphUnit"
      [allLegendStates]="naAllChartLegendStates"
      (emitLegendState)="updateNaChartLegendState($event)"
    ></firebird-web-widget-na-renewables-chart-legend>
  </ng-container>
  <ng-template #legendLoader>
    <firebird-web-loader class="loader"></firebird-web-loader>
  </ng-template>
</div>
<div class="close-save">
  <span (click)="onSave()" class="save">Save</span>
  <span (click)="onClose()" class="cancel">X-Cancel</span>
</div>

<ng-template #loader>
  <div class="loader">
    <firebird-web-loader></firebird-web-loader>
  </div>
</ng-template>
