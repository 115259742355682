import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints as endpoints } from '@firebird-web/shared-constants';
import { DatePipe } from '@angular/common';
import { ServerResponse } from 'libs/shared/interfaces/src/lib/server-response.interface';
import { WSModelsMessage } from '@firebird-web/ws';
import { WsService } from 'libs/ws/src/lib/ws.service';
import { Blurb } from '../../../../../interfaces/news.interface';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import {
  CLIPBOARD_BLURB_POST_ID_QUERY_PARAM,
  DEFAULT_INSIGHT_PAGE_NUMBER,
  DEFAULT_INSIGHT_PAGE_SIZE,
  ONE_TO_FIFTEEN_DAYS_DEFAULT_ROUTE,
} from '../../../constants';
import {
  IBlurbRequestBody,
  IPaginationData,
} from '../../../interfaces/blurb.interface';
import { CommonUtils } from '@firebird-web/shared-utils';
import { isEmpty } from 'lodash';
import { BlurbRequestPayload } from '../../../types';
import { isNilOrEmptyArray } from '../../../utils';

@Injectable()
export class BlurbService {
  blurbs$ = new BehaviorSubject<any[]>([]);
  blurbIsOpened$ = new Subject<boolean>();
  numberOfUnreadBlurbs$ = new BehaviorSubject<number>(0);

  pretendInsightAuthorDropdown = [];

  private readonly _clipboardBlurbId$ = new BehaviorSubject<number | null>(
    null
  );

  constructor(
    private http: HttpClient,
    private readonly wsService: WsService,
    private readonly clipboard: Clipboard,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService
  ) {}

  public getAllInsights(
    payload: BlurbRequestPayload
  ): Observable<{ blurbData: Blurb[]; paginationData: IPaginationData }> {
    return this.triggerOnUpdateExists().pipe(
      switchMap(() =>
        this.http.post<ServerResponse<Blurb[]>>(
          `${environment.apiDomain}${endpoints.getBlurbs}`,
          this.prepareInsightRequestBody(payload),
          {
            observe: 'response',
          }
        )
      ),
      map((response) => {
        const xPagination = response.headers.get('X-Pagination') ?? '';

        return {
          blurbData: response?.body?.data ?? ({} as Blurb[]),
          paginationData: xPagination ? JSON.parse(xPagination) : {},
        };
      })
    );
  }

  public getInsightById(id: number | string): Observable<Blurb> {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}${endpoints.getBlurb}`,
      { id }
    );
    return this.http
      .get<ServerResponse<Blurb>>(endPointUrl)
      .pipe(map(({ data }) => data));
  }

  setBlurbs(blurbs: any) {
    this.blurbs$.next(blurbs);
  }

  public translateDate(theDate: Date, datepipe: DatePipe, isPrint = false) {
    if (isPrint) {
      return datepipe.transform(theDate, 'MMMM d, y, h:mm a');
    }

    const now = new Date();
    const timeDifference = now.getTime() - theDate.getTime();
    if (timeDifference < 60 * 60 * 1000) {
      const minutes = Math.floor(timeDifference / (60 * 1000));
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifference < 24 * 60 * 60 * 1000) {
      const hours = Math.floor(timeDifference / (60 * 60 * 1000));
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else if (timeDifference < 7 * 24 * 60 * 60 * 1000) {
      const days = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    } else {
      return datepipe.transform(theDate, 'MMMM d, y'); // Display date without time
    }
  }

  getExperts() {
    return this.http.get<ServerResponse<any>>(
      `${environment.apiDomain}${endpoints.getBlurbExperts}`
    );
  }

  updateUserLastVisitTime() {
    const endPointUrl = `${environment.apiDomain}${endpoints.updateUserLastVisitTime}`;
    return this.http.post(endPointUrl, {}, { observe: 'response' });
  }

  triggerUpdateNumberOfUnread() {
    this.blurbIsOpened$.next(true);
  }

  getNumberOfUnreadBlurbs() {
    const endPointUrl = `${environment.apiDomain}${endpoints.getNumberOfUnreadBlurbs}`;
    return this.http
      .get<
        ServerResponse<{
          unreadBlurbsCount: number;
          newBlurbsCount: number;
        }>
      >(endPointUrl)
      .pipe(
        map(({ data }) => data?.newBlurbsCount),
        tap((numberOfUnreadBlurbs) =>
          this.numberOfUnreadBlurbs$.next(numberOfUnreadBlurbs)
        )
      );
  }

  public triggerOnUpdateExists(): Observable<WSModelsMessage[]> {
    return this.wsService.wsMessage$.pipe(
      filter((messages) =>
        messages.some(({ dataTable }) => dataTable === 'weather_blurbs')
      ),
      startWith([])
    );
  }

  public copyBlurbToClipboard(id: number | undefined | null): void {
    if (!id) {
      return;
    }

    const { origin } = window.location;

    this.clipboard.copy(
      `${origin}${ONE_TO_FIFTEEN_DAYS_DEFAULT_ROUTE}/?${CLIPBOARD_BLURB_POST_ID_QUERY_PARAM}=${id}`
    );
    this.snackBar.open(this.translateService.instant('copyLink'), 'x', {
      duration: 1500,
    });
  }

  public get clipboardBlurbId(): number | null {
    return this._clipboardBlurbId$.getValue();
  }

  public set clipboardBlurbId(value: number | null) {
    this._clipboardBlurbId$.next(value);
  }

  private prepareInsightRequestBody({
    pageNumber = DEFAULT_INSIGHT_PAGE_NUMBER,
    pageSize = DEFAULT_INSIGHT_PAGE_SIZE,
    experts,
    locations,
    keyWord,
  }: BlurbRequestPayload): IBlurbRequestBody {
    return {
      pageNumber,
      pageSize,
      experts: isNilOrEmptyArray(experts) ? null : experts,
      locations: isNilOrEmptyArray(locations) ? null : locations,
      keyWord: isEmpty(keyWord) ? null : keyWord,
    };
  }
}
