/**
 * Interface for the 'NotificationsStore' data
 */
export interface NotificationsStoreEntity {
  id: string | number; // Primary ID
  name: string;
}

export interface INotificationEmail {
  email: string;
}
