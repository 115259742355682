import { BaseImageData, ExtendedImageData } from './image-data.types';

export interface Issuance<T extends BaseImageData> {
  issueDate: string;
  name: string;
  validDates: [];
  temperatureGraphics?: T[];
  precipitationGraphics?: T[];
  windGraphics?: T[];
  solarGraphics?: T[];
}

export interface VerificationIssuance<T extends BaseImageData> {
  issueDate: string;
  validDates: [];
  threeMonthGraphics: T[];
  twelveMonthGraphics: T[];
}

export interface VerificationImages<T extends BaseImageData> {
  name: string;
  verificationGraphics?: T[];
}
export interface StaticGraphicsData {
  expectedTemperatureGraphicNames?: string[];
  expectedPrecipitationGraphicNames?: string[];
  expectedWindGraphicNames?: string[];
  expectedSolarGraphicNames?: string[];
  expectedClusterGraphicNames: null;
  expectedTimeSeriesGraphicNames: null;
  expectedExtremesGraphicNames: null;
  expectedVerificationGraphicNames: null;
  issuances: Issuance<ExtendedImageData>[];
  timeSeriesIssuances?: Issuance<ExtendedImageData>[];
  clusterIssuances?: Issuance<ExtendedImageData>[];
  verificationIssuances: VerificationIssuance<ExtendedImageData>[];
  extremesIssuances: { [key: string]: Issuance<ExtendedImageData>[] };
}
