import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CustomCellRendererParams } from '../../models/load-daily-cell.interfaces';
import { ForecastType } from '../../enums';

@Component({
  selector: 'firebird-web-load-hourly-cell-component',
  templateUrl: './load-hourly-cell.component.html',
  styleUrls: ['./load-hourly-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadHourlyCellComponent implements ICellRendererAngularComp {
  public readonly forecastTypeDiff = ForecastType.ForecastDiff;

  public displayText!: string;
  public params!: CustomCellRendererParams;
  public capacity: string | number | boolean = false;
  public tableCellBgColor = '';
  public style: Record<string, string> | undefined;
  public selectedForecastType: string;

  get hasPopup(): boolean {
    if (this.selectedForecastType === ForecastType.Forecast && this.capacity) {
      return true;
    }

    return !!(
      this.selectedForecastType === ForecastType.ForecastDiff &&
      (this.capacity || this.capacity === 0)
    );
  }

  public agInit(params: CustomCellRendererParams): void {
    this.params = params;
    const { value, renderType, style, forecastType } = params;

    this.selectedForecastType = forecastType as string;
    this.style = <Record<string, string>>style;
    this.tableCellBgColor =
      renderType === 'load' ? this.findLoadColor(value?.colorCode) : '';
    this.displayText = value?.text;
    this.capacity = value?.tooltip;
  }

  public refresh(): boolean {
    return false;
  }

  private findLoadColor(value: string): string {
    return !value ? 'default-load' : value;
  }
}
