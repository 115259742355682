import {
  EnhancedDataServicesApiKeyEntity,
  EnhancedDataServicesDocumentation,
  EnhancedDataServicesEntityData,
} from '../types';

const ENHANCED_DATA_SERVICES_TRADER_ENTITY_DOCUMENTATION_LIST: EnhancedDataServicesDocumentation[] =
  [
    {
      labelKey: 'enhancedDataServicesTraderDataDocumentation',
      link: '/assets/documentation/WSI Trader API Documentation.pdf',
    },
  ];

const ENHANCED_DATA_SERVICES_CLEANED_HISTORICAL_ENTITY_DOCUMENTATION_LIST: EnhancedDataServicesDocumentation[] =
  [
    {
      labelKey: 'enhancedDataServicesCleanedHistoricalDataDocumentation',
      link: 'https://www.atmosphericg2.com/ag2-cleaned-historical-observations-documentation',
    },
  ];

const ENHANCED_DATA_SERVICES_SUN_API_ENTITY_DOCUMENTATION_LIST: EnhancedDataServicesDocumentation[] =
  [
    {
      labelKey: 'enhancedDataServicesOtherDataServicesCoreDocumentation',
      link: 'https://ibm.co/TWCdac',
    },
    {
      labelKey: 'enhancedDataServicesOtherDataServicesSevereDocumentation',
      link: 'https://ibm.co/TWCswe',
    },
    {
      labelKey: 'enhancedDataServicesOtherDataServicesLightningDocumentation',
      link: 'https://ibm.co/TWClss',
    },
    {
      labelKey: 'enhancedDataServicesOtherDataServicesRenewablesDocumentation',
      link: 'https://ibm.co/TWCren',
    },
    {
      labelKey: 'enhancedDataServicesOtherDataServicesAgricultureDocumentation',
      link: 'https://ibm.co/TWCagr',
    },
    {
      labelKey:
        'enhancedDataServicesOtherDataServicesEnhancedForecastDocumentation',
      link: 'https://ibm.co/TWCefo',
    },
    {
      labelKey:
        'enhancedDataServicesOtherDataServicesHistoryOnDemandDocumentation',
      link: 'https://ibm.co/TWChod',
    },
    {
      labelKey:
        'enhancedDataServicesOtherDataServicesEnhancedConditionsDocumentation',
      link: 'https://ibm.co/TWCecc',
    },
    {
      labelKey:
        'enhancedDataServicesOtherDataServicesProbabilisticForecastDocumentation',
      link: 'https://ibm.co/TWCpfo',
    },
  ];

export const ENHANCED_DATA_SERVICES_ENTITY_CONFIG_MAP: Record<
  EnhancedDataServicesApiKeyEntity,
  Pick<
    EnhancedDataServicesEntityData,
    'title' | 'description' | 'documentationList'
  >
> = {
  trader: {
    title: 'enhancedDataServicesTraderDataTitle',
    description: 'enhancedDataServicesTraderDataDescription',
    documentationList: ENHANCED_DATA_SERVICES_TRADER_ENTITY_DOCUMENTATION_LIST,
  },
  cleanedHistorical: {
    title: 'enhancedDataServicesCleanedHistoricalDataTitle',
    description: 'enhancedDataServicesCleanedHistoricalDataDescription',
    documentationList:
      ENHANCED_DATA_SERVICES_CLEANED_HISTORICAL_ENTITY_DOCUMENTATION_LIST,
  },
  sunApi: {
    title: 'enhancedDataServicesOtherDataServicesTitle',
    description: 'enhancedDataServicesOtherDataServicesDescription',
    documentationList: ENHANCED_DATA_SERVICES_SUN_API_ENTITY_DOCUMENTATION_LIST,
  },
};

export const ENHANCED_DATA_SERVICES_ENTITY_LIST_ORDER: EnhancedDataServicesApiKeyEntity[] =
  ['trader', 'cleanedHistorical', 'sunApi'];
