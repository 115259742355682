import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {
  EnhancedDataServicesApiKeyAccess,
  EnhancedDataServicesApiKeyEntity,
} from '../../types';

@Component({
  selector: 'firebird-web-enhanced-data-services-api-key',
  templateUrl: './enhanced-data-services-api-key.component.html',
  styleUrls: ['./enhanced-data-services-api-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnhancedDataServicesApiKeyComponent {
  @Input() public apiKeys!: string[] | null;
  @Input() public access!: EnhancedDataServicesApiKeyAccess;
  @Input() public entity!: EnhancedDataServicesApiKeyEntity;

  constructor(private readonly clipboard: Clipboard) {}

  public get allowed(): boolean {
    return this.access === 'allowed';
  }

  public get isTraderEntity(): boolean {
    return this.entity === 'trader';
  }

  @HostBinding('class')
  private get hostClasses(): string {
    return [this.access, this.isTraderEntity ? 'follow-instructions' : '']
      .filter(Boolean)
      .join(' ');
  }

  public handleCopyToClipboardButtonClick(apiKey: string): void {
    if (apiKey) {
      this.clipboard.copy(apiKey);
    }
  }

  public trackByApiKey(_: number, apiKey: string): string {
    return apiKey;
  }
}
