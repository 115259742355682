import {
  ENHANCED_DATA_SERVICES_ENTITY_CONFIG_MAP,
  ENHANCED_DATA_SERVICES_ENTITY_LIST_ORDER,
} from '../constants';
import {
  EnhancedDataServicesApiKeys,
  EnhancedDataServicesApiKeyEntity,
  EnhancedDataServicesEntityData,
} from '../types';

export const createEnhancedDataServicesEntityConfigList = (
  apiKeys: EnhancedDataServicesApiKeys
): EnhancedDataServicesEntityData[] =>
  Object.entries(apiKeys)
    .map(([key, value]): EnhancedDataServicesEntityData => {
      const entity = key as EnhancedDataServicesApiKeyEntity;

      return {
        entity,
        access: value.apiKeys?.length ? 'allowed' : 'denied',
        ...ENHANCED_DATA_SERVICES_ENTITY_CONFIG_MAP[entity],
        ...value,
      };
    })
    .sort(
      (a, b) =>
        ENHANCED_DATA_SERVICES_ENTITY_LIST_ORDER.indexOf(a.entity) -
        ENHANCED_DATA_SERVICES_ENTITY_LIST_ORDER.indexOf(b.entity)
    );
