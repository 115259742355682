import { createReducer, on, Action } from '@ngrx/store';
import { merge, first, tail, update, cloneDeep, isArray } from 'lodash';

import * as UserSettingsActions from './user-settings.actions';
import { Units } from '../../models/user-settings.models';
import { NewsFeedSettings } from '../../types/user-settings.types';

export const USERSETTINGS_FEATURE_KEY = 'userSettings';

export interface RouterPersistence {
  [key: string]:
    | RouterPersistence
    | {
        childRoute: string;
        data: any;
      };
}

export interface UserSettingsState {
  loaded: boolean;
  units: Units;
  error?: string | null;
  routerPersistance: RouterPersistence;
  user_id: string;
  homePage: string[] | null;
  newsFeed: NewsFeedSettings | null;
}

export interface UserSettingsPartialState {
  readonly [USERSETTINGS_FEATURE_KEY]: UserSettingsState;
}

export const initialUserSettingsState: UserSettingsState = {
  units: {
    tempUnit: 'F',
    precipUnit: 'in',
    windUnit: 'mph',
  },
  routerPersistance: {},
  loaded: false,
  user_id: '',
  homePage: null,
  newsFeed: null,
};

const reducer = createReducer(
  initialUserSettingsState,
  on(UserSettingsActions.initUserSettings, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(UserSettingsActions.loadUserSettingsSuccess, (state, { userSettings }) => {
    return {
      ...merge(state, userSettings),
      loaded: true,
    };
  }),
  on(UserSettingsActions.loadUserSettingsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(UserSettingsActions.changeTempUnit, (state, { tempUnit }) => ({
    ...state,
    units: {
      ...state.units,
      tempUnit,
    },
  })),
  on(UserSettingsActions.changePrecipUnit, (state, { precipUnit }) => ({
    ...state,
    units: {
      ...state.units,
      precipUnit,
    },
  })),
  on(UserSettingsActions.changeWindUnit, (state, { windUnit }) => ({
    ...state,
    units: {
      ...state.units,
      windUnit,
    },
  })),

  on(
    UserSettingsActions.changeRouterPersistence,
    (state, { path, value, clearPrevState }) => {
      const levels = path.split('/');

      function deepSpread(
        levels: string[],
        levelState: Record<string, any>,
        value: any
      ): Record<string, any> {
        const firstLevel: string | undefined = first(levels);
        const tailLevels: string[] = tail(levels);
        if (!firstLevel) {
          return levelState;
        }
        const secondLevel: string | undefined = first(tailLevels);

        if (!secondLevel) {
          return clearPrevState
            ? { ...levelState, [firstLevel]: { data: value } }
            : merge(levelState, { [firstLevel]: { data: value } });
        }

        return {
          ...levelState,
          [firstLevel]: {
            ...deepSpread(tailLevels, levelState[firstLevel] || {}, value),
          },
        };
      }

      return {
        ...state,
        routerPersistance: deepSpread(levels, state.routerPersistance, value),
      };
    }
  ),
  on(UserSettingsActions.changeActiveRoute, (state, { path }) => {
    const levels = path.split('/');

    function deepSpread(
      levels: string[],
      levelState: Record<string, any>
    ): Record<string, any> {
      const firstLevel: string | undefined = first(levels);
      const tailLevels: string[] = tail(levels);
      if (!firstLevel) {
        return levelState;
      }
      const secondLevel: string | undefined = first(tailLevels);

      if (!secondLevel) {
        return levelState;
      }

      return {
        ...levelState,
        [firstLevel]: {
          ...deepSpread(tailLevels, levelState[firstLevel] || {}),
          childRoute: secondLevel,
        },
      };
    }

    return {
      ...state,
      routerPersistance: deepSpread(levels, state.routerPersistance),
    };
  }),
  on(UserSettingsActions.shareRouterPersistence, (state, { path, data }) => {
    const routerPersistance = cloneDeep(state.routerPersistance);
    const levels = (isArray(path) ? path : [path]).map((level) =>
      level.split('/').filter((level) => !!level)
    );

    for (const level of levels) {
      update(routerPersistance, [...level, 'data'], (existData) =>
        merge(existData, data)
      );
    }

    return {
      ...state,
      routerPersistance,
    };
  }),
  on(UserSettingsActions.setHomepage, (state, { path }) => {
    return {
      ...state,
      homePage: path,
    };
  }),
  on(
    UserSettingsActions.setNewsFeedBlurbFilter,
    (state, { filter: blurb }) => ({
      ...state,
      newsFeed: {
        ...(state?.newsFeed && state.newsFeed),
        filter: {
          ...(state?.newsFeed?.filter && state.newsFeed.filter),
          blurb,
        },
      },
    })
  ),
  on(UserSettingsActions.setNewsFeedBlogFilter, (state, { filter: blog }) => ({
    ...state,
    newsFeed: {
      ...(state?.newsFeed && state.newsFeed),
      filter: {
        ...(state?.newsFeed?.filter && state.newsFeed.filter),
        blog,
      },
    },
  })),
  on(
    UserSettingsActions.setHomepageNthLevel,
    (state, { dedicatedRoutes, path, level }) => {
      if (state.homePage === null) {
        return state;
      }
      if (
        !dedicatedRoutes.every(
          (dedicatedRoute, index) => state.homePage?.[index] === dedicatedRoute
        )
      ) {
        return state;
      }
      const newPath = [...state.homePage];
      newPath[level] = path;
      return {
        ...state,
        homePage: newPath,
      };
    }
  )
);

export function userSettingsReducer(
  state: UserSettingsState | undefined,
  action: Action
) {
  return reducer(state, action);
}
