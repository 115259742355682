import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { INotificationGroups } from '../interfaces/notification-group-info.interface';
import { INotificationGroupChanges } from '../interfaces/notification-group-changes.interface';

@Injectable()
export class NotificationsService {
  constructor(private http: HttpClient) {}

  getNotificationGroupInfo(): Observable<INotificationGroups> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.notificationGroupInfo}`;
    return this.http
      .get<ServerResponse<INotificationGroups>>(endpointUrl)
      .pipe(map(({ data }: ServerResponse<INotificationGroups>) => data));
  }

  updateNotificationGroupInfo(
    notificationGroups: INotificationGroupChanges[]
  ): Observable<void> {
    const endpointUrl = `${environment.apiDomain}/${apiEndpoints.notificationGroupInfo}`;
    return this.http.put<void>(endpointUrl, notificationGroups);
  }

  unsubscribe(notificationGroup: string): Observable<void> {
    const endpointUrl = `${environment.apiDomain}/api/v1/Notification/notification-group-info/${notificationGroup}/unsubscribe`;
    return this.http.put<void>(endpointUrl, {});
  }
}
