import { Injectable } from '@angular/core';
import { environment } from '@firebird-web/shared-config';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonUtils } from '@firebird-web/shared-utils';
import { CitiesEntity } from '../+state/cities/cities.models';
@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  constructor(private http: HttpClient) {}
  getCitiesList(
    continent: string,
    regionId: string
  ): Observable<CitiesEntity[]> {
    const adjustedRegionId = regionId.toLowerCase().includes('regions')
      ? 'All Regions'
      : regionId;
    const encodedRegionId = encodeURIComponent(adjustedRegionId);
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getCitiesList}`,
      { continent, region: encodedRegionId }
    );
    return this.http.get(endPointUrl).pipe(map((resp: any) => resp.data));
  }
}
