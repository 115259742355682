import {
  BaseImageData,
  CachedImageData,
} from '@firebird-web/shared-interfaces';
import { periodStrategies } from '../period-strategies';

export type RemapDisplayName<T> = Omit<T, 'display_name'> & { title: string };

export type ModelCycleResponse = {
  display_name: string;
  diff: number;
};

export type ModelParameterResponse = {
  id: string;
  display_name: string;
  category_id: string;
  is_precip: boolean;
};

export type ModelParameter = RemapDisplayName<ModelParameterResponse>;

export type ModelViewResponse = {
  id: string;
  display_name: string;
  precip_availability: Array<'nonPrecip' | 'precip'>;
  param_availability: string[];
  feature_id: string;
  list_order: number;
  isPermitted: boolean;
};

export type PanelData = {
  model: string;
  index?: number;
  trackId: string;
  data: CachedImageData<ModelData>[];
};

export type SelectedConfigs = {
  models: ModelFilterConfig[];
  parameter: ModelParameter;
  view: ModelView;
  run?: ModelRun;
};

export type ModelPanelConfig = {
  title: string;
  id: string;
  group: string;
  period: PeriodTypes;
  cycles: ModelRun[];
  intervals: ModelIntervalResponse[];
  parameters: ModelParameter[];
  views: ModelView[];
};

export type ModelView = RemapDisplayName<ModelViewResponse>;

export type ModelIntervalResponse = {
  start: number;
  stop: number;
  interval: number;
  model_runs: string[];
  groups: string[];
  interval_type: string | null;
  is_out_of_scope: boolean;
  is_base: boolean;
};

export type BaseInterval = {
  start: number;
  stop: number;
  interval: number;
};

export type ModelRunResponse = {
  id: string;
  display_name: string;
  diff?: number;
};

export type ModelRun = RemapDisplayName<ModelRunResponse>;

export type PeriodTypes = keyof typeof periodStrategies;

export type ModelData = BaseImageData & {
  order: number;
  periodType: PeriodTypes;
  validPeriod: string;
  interval: number;
  validTime: number;
  trackId: string;
  trackIndex?: string;
  isModelInvalid?: boolean;
};

export type ModelPanelConfigResponse = {
  model: string;
  modelGroup: string;
  modelName: string;
  cycles: ModelCycleResponse[];
  intervals: ModelIntervalResponse[];
  parameters: ModelParameterResponse[];
  views: ModelViewResponse[];
};

export type ModelFilterConfig = {
  title: string;
  id: string;
  model: string;
  group: string;
  period: PeriodTypes;
  cycles: ModelRun[];
  intervals: ModelIntervalResponse[];
  parameters: ModelParameter[];
  views: ModelView[];
};
export type WidgetModelFilterConfig = {
  group: string;
  id: string;
  intervals: ModelIntervalResponse[];
  model: string;
  period: PeriodTypes;
  title: string;
};
export type ModelMultiConfig = {
  config: ModelConfig;
  panelIndex: number;
};

export type FavoriteModelDetails = {
  controlId: string;
  selectedConfigs: ModelConfig[] | SelectedConfigs[];
};

export type ModelConfig = {
  model: ModelFilterConfig;
  parameter: ModelParameter;
  view: ModelView;
  run: ModelRun;
  trackId: string;
  initType?: ModelRun;
  index?: number;
  w?: number;
  h?: number;
};

export type ModelConfigIds = {
  model: string;
  modelGroup: string;
  view: string;
  parameter: string;
  periodType: string;
  initType: string;
} & ModelConfigExtraParams;

export type ModelConfigExtraParams = {
  alwaysShowLatest?: boolean;
  controlId?: 'Model-Comparison' | null;
};

export type IntervalLineData = {
  id?: number;
  label?: number | string;
  disabledLabel: boolean;
  disabledInterval: boolean;
  displayLabel?: boolean;
  originalLabel?: number | string;
  isDot?: boolean;
  hoverLabel?: number | string;
  validTime?: number;
  isTemporaryHidden?: boolean;
  isModelInvalid?: boolean;
};

export type ModelButtonsConfig = {
  saveAllPanels: boolean;
  showAllSettingsModal: boolean;
};

export interface FavoriteModelData extends ModelConfig {
  alwaysShow?: boolean;
  delay?: number;
  interval?: number;
  timeDiff?: string;
  editModeModels?: boolean;
}

export interface FavoriteDetails {
  controlId: string;
  listName: string;
  groupName: string;
  models: ModelConfig[] | SelectedConfigs[];
}

export interface FavoriteGroup {
  controlId: string;
  groupName: string;
  id: string;
  editMode: boolean;
  groupId: string;
  listName: string;
  editModeModels?: boolean;
  modelOrder: number | string;
  modelIds: number[] | string[];
  models: FavoriteModelData[];
}

export interface MappedFavoriteGroup {
  controlId: string;
  editMode: boolean;
  groupId: string;
  groupName: string;
  id: number | string;
  listName: string;
  data: FavoriteGroup[];
}

export interface ModelControls {
  alwaysShow: boolean;
  delay: number;
  interval?: number;
}
