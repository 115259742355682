import { createAction, props } from '@ngrx/store';
import { CitiesEntity } from './cities.models';

export const loadCities = createAction(
  '[Cities Store] Load Cities',
  props<{ continent: string | null; region: string | null }>()
);

export const loadCitiesSuccess = createAction(
  '[Cities Store] Load Cities Success',
  props<{ cities: CitiesEntity[] }>()
);

export const loadCitiesFailure = createAction(
  '[Cities Store] Load Cities Failure',
  props<{ error: any }>()
);
