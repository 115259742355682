/**
 * Interface for the 'HourlyForecastRunDates' data
 */
export interface IHourlyForecastRunDatesEntity {
  id: string; // Primary ID
  label: string;
  type: string;
  storageTime?: string;
  initTime?: string;
}
