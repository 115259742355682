<div class="demand-panel-container">
  <div class="location">
    <p class="label">Location</p>
    <div class="region">
      <mat-form-field class="variation-five">
        <mat-select
          (selectionChange)="regionChange($event)"
          [value]="demandPanelState.selectedRegion.locationId"
        >
          <div>
            <mat-option
              *ngFor="let region of demandPanelState.regions"
              [value]="region.locationId"
              [hasViewPermission]="getPermission(region.locationId)"
            >
              {{ region.displayName }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="sub-region">
      <mat-form-field class="variation-five">
        <mat-select
          (selectionChange)="subZoneChange($event)"
          [value]="demandPanelState.selectedSubzone.locationId"
        >
          <div>
            <mat-option
              *ngFor="let zone of demandPanelState.selectedSubZones"
              [value]="zone.locationId"
              [hasViewPermission]="getPermission(zone.locationId)"
            >
              {{ zone.displayName }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<firebird-web-load-legend-selection
  (legendToggled)="legendToggled($event)"
  [legends]="(modifiedLegends$ | async) ?? []"
>
</firebird-web-load-legend-selection>
<div class="close-save">
  <button (click)="onSave()" class="save">Save</button>
  <button (click)="onClose()" class="cancel">X-Cancel</button>
</div>
