import {
  IOverviewFilterTypes,
  IOverviewSubTab,
} from '@firebird-web/shared-interfaces';
import { ForecastTypes } from '@firebird-web/shared-ui';
import { Permissions } from '@firebird-web/acl';

export interface IOverviewTab {
  labelKey: ForecastTypes | string;
  value: string;
  filterTypes?: IOverviewFilterTypes[];
  colorCodes?: string[];
  permission?: Permissions;
  enabled?: boolean;
  subTabs?: IOverviewSubTab[];
}

export interface SiteNameOverralAggregates {
  conus: OverralAggregatesItem;
  overall: OverralAggregatesItem;
  europe: OverralAggregatesItem;
  asia: OverralAggregatesItem;
  mexico: OverralAggregatesItem;
}

export interface OverralAggregatesItem {
  name: string;
  value: string;
}
