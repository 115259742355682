import { Component, Input } from '@angular/core';

@Component({
  selector: 'firebird-web-affordance',
  templateUrl: './affordance.component.html',
  styleUrls: ['./affordance.component.scss'],
})
export class AffordanceComponent {
  @Input() name = 'affordance';

  /**
   * exportData
   */
  public exportData(): void {
    // todo
  }
}
