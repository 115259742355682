import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  InitDataSources,
  WeightedWidgetDetails,
  WeightedWidgetPanelState,
} from '../../interfaces';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { LocationService } from '../../../../../shared/services/src/lib/location.service';
import { WidgetPanelService } from '../../services';
import { filter, first, map, Observable, of, tap } from 'rxjs';
import {
  DataSource,
  DropdownOption,
} from '../../../../../shared/interfaces/src/lib';
import { first as lodashFirst } from 'lodash';
import { DashboardService } from '../../services/dashboard.service';
import {
  buildWeightWidgetState,
  buildWidgetWeightDetails,
  hasModelRunTime,
  prepareDataSource,
} from '../../helpers';
import { WeightedService } from '../../../../../weighted/src/lib/services/weighted.service';
import { continents } from '@firebird-web/shared-constants';
import {
  CategoryValues,
  ContinentNames,
  ForecastValues,
  RunTimeModelValues,
  UnitType,
} from '../../enums';
import {
  CategoryDataSourceList,
  DailyPeriodDataSourceList,
  DefaultLocation,
  DefaultWeightedWidgetPanelState,
  ForecastDataSourceList,
  IWidgetSummaryPanelData,
  IWidgetWeightedLocationData,
  ParameterDataSourceList,
  EURParameterDataSourceList,
  RunTimeModelDataSourceList,
  sumTableSizes,
  WidgetTypes,
} from '../../constants';
import { UserSettingsFacade } from '@firebird-web/user-settings-store';
import { PermissionsService } from 'libs/acl/src/lib/permissions.service';
import { WidgetService } from '../../services/widget.service';

@Component({
  selector: 'firebird-web-weighted-panel',
  templateUrl: './weighted-panel.component.html',
  styleUrls: ['./weighted-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WeightedPanelComponent implements OnInit {
  public readonly categoryValues = CategoryValues;
  public readonly cUnit: string = UnitType.C;
  public readonly forecastValues = ForecastValues;
  public readonly continentNames = ContinentNames;
  public readonly runTimeModel = RunTimeModelValues;
  public readonly dailyPeriodDataSource: DropdownOption[] =
    DailyPeriodDataSourceList;
  public readonly categoryDataSource: DropdownOption[] = CategoryDataSourceList;
  public readonly forecastDataSource: DropdownOption[] = ForecastDataSourceList;
  public readonly tableSizesDataSource: DropdownOption[] = sumTableSizes;
  public readonly runTimeModelDataSource: DropdownOption[] =
    RunTimeModelDataSourceList;
  public climatologyDataSource: DataSource[];
  public runTimeDataSource$: Observable<DropdownOption[]>;
  public parameterDataSource$: Observable<DropdownOption[]>;
  public tempUnit: string = UnitType.F;

  public panelState: WeightedWidgetPanelState = DefaultWeightedWidgetPanelState;

  get isModelSelected(): boolean {
    return this.panelState.selectedForecast === this.forecastValues.model;
  }
  hasMultipleParameters$: Observable<boolean>;
  public weightedRegions = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IWidgetSummaryPanelData,
    private dialogRef: MatDialogRef<WeightedPanelComponent>,
    private locationService: LocationService,
    public readonly userSettings: UserSettingsFacade,
    private widgetPanelService: WidgetPanelService,
    private dashboardService: DashboardService,
    private weightedServices: WeightedService,
    private widgetService: WidgetService,
    private permissionService: PermissionsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initPanelData();
    this.initParameterDataSource();
    this.initRunTimeDataSource();
    this.watchForChangeTempUnit();
    this.addPermissionsToCategory();
  }

  public changeCategory(event: MatSelectChange): void {
    const isCategoryChanged = event.value !== this.panelState.selectedCategory;

    if (this.panelState.selectedCategory !== event.value) {
      this.initRunTimeDataSource(null, event.value);
      this.initParameterDataSource(event.value, null, isCategoryChanged);
    }
    this.panelState.selectedCategory = event.value;

    this.setCorrectLocation(isCategoryChanged);
  }

  public changeForecast(event: MatSelectChange): void {
    this.panelState = {
      ...this.panelState,
      selectedForecast: event.value,
      biasCorrected: event.value === ForecastValues.model,
    };
  }

  public changeClimatology(event: MatSelectChange): void {
    this.panelState.selectedClimatology = event.value;
  }

  public changeDailyPeriod(event: MatSelectChange): void {
    this.panelState.selectedDailyPeriod = event.value;
  }

  public changeSize(event: MatSelectChange): void {
    this.panelState.selectedTableSize = event.value;
  }

  public changeRunTimeModel(event: MatSelectChange): void {
    if (this.panelState.selectedRunTimeModel !== event.value) {
      this.initRunTimeDataSource(event.value);
    }

    this.panelState = {
      ...this.panelState,
      selectedRunTimeData: '',
      selectedRunTimeModel: event.value,
      biasCorrected: event.value === RunTimeModelValues.runTime,
    };
  }

  public changeRunTimeData(event: MatSelectChange): void {
    this.panelState.selectedRunTimeData = event.value;
  }

  public changeParameterValue(event: MatSelectChange): void {
    this.panelState.selectedParameterValue = event.value;
  }

  public changeDiffPrior(event: Event): void {
    this.panelState.diffPriorValue = (event.target as HTMLInputElement).checked;
  }

  public showDecimalValues(event: Event): void {
    this.panelState.showDecimalValue = (
      event.target as HTMLInputElement
    ).checked;
  }

  public changeNormalValue(event: Event): void {
    this.panelState.normalValue = (event.target as HTMLInputElement).checked;
  }

  public changeBiasCorrectedValue(event: Event): void {
    this.panelState.biasCorrected = (event.target as HTMLInputElement).checked;
  }

  public changeLocation(): void {
    const selectedContinentId = this.widgetPanelService.getContinentByName(
      this.data.widgetConfig.continent
    );

    this.locationService
      .openWeightedWidgetLocation(
        this.panelState.selectedCategory,
        this.widgetPanelService.getContinentByName(
          this.panelState.selectedContinent
        )?.id ??
          (selectedContinentId?.id || 'NA')
      )
      .pipe(
        tap((response: IWidgetWeightedLocationData) => {
          this.setPreviousContinentRegion(response);
          return response;
        }),
        first(),
        filter((response) => !!response)
      )
      .subscribe((response: IWidgetWeightedLocationData) => {
        const continentName = this.widgetPanelService.getContinentName(
          response.continentId
        );
        if (this.panelState.selectedContinent !== continentName) {
          this.initParameterDataSource(null, response.continentId);
        }

        this.panelState = {
          ...this.panelState,
          selectedContinent: continentName,
          selectedRegionId: response.regionId,
          selectedRegionName: response.regionName,
        };
        this.cdr.markForCheck();
      });
  }

  public saveWidget(): void {
    const selectedContinentId = this.widgetPanelService.getContinentByName(
      this.panelState.selectedContinent ?? this.data.widgetConfig.continent
    )?.id as string;
    const widgetDetails = buildWidgetWeightDetails(
      selectedContinentId,
      this.panelState,
      this.tempUnit
    );
    const isModelRunTime = hasModelRunTime(
      this.panelState.selectedForecast,
      this.panelState.selectedRunTimeModel
    );

    this.widgetService.updateWidgetLocationState({
      type: WidgetTypes.weighted,
      continent: selectedContinentId,
      region: this.panelState.selectedRegionId,
    });
    if (isModelRunTime) {
      this.widgetPanelService
        .getRunTimeDataSource(
          this.panelState.selectedRunTimeModel,
          this.panelState.selectedCategory,
          selectedContinentId || 'NA'
        )
        .pipe(first())
        .subscribe((runDate: InitDataSources[]) => {
          const latestRunDate = lodashFirst(runDate);

          if (this.data.isNew) {
            this.dashboardService.createWidget(
              {
                ...widgetDetails,
                modelRun: latestRunDate?.id || '',
              },
              WidgetTypes.weighted
            );
          }
          return this.dialogRef.close({
            ...widgetDetails,
            modelRun: latestRunDate?.id || '',
          });
        });
    } else {
      this.saveAgWeightedWidget(widgetDetails);
    }
  }

  public closeWidget(): void {
    this.dialogRef.close();
  }

  public addPermissionsToCategory(): void {
    const isWeightedDegreePermitted = this.permissionService.isPermitted([
      'Weighted_Forecasts',
      'Degree_Day',
    ]);
    const isIsoCountryPermitted = this.permissionService.isPermitted([
      'Weighted_Forecasts',
      'ISO-Country',
    ]);
    CategoryDataSourceList[0].isPermitted = isIsoCountryPermitted;
    CategoryDataSourceList[1].isPermitted = isWeightedDegreePermitted;
  }
  generateDefaultCategory(): string {
    return this.permissionService.isPermitted([
      'Weighted_Forecasts',
      'ISO-Country',
    ])
      ? CategoryValues.iso
      : CategoryValues.degree;
  }
  getCurrentCategory(): string {
    return this.panelState.selectedCategory === 'iso'
      ? 'iso-country-forecast'
      : 'degree-day-forecast';
  }
  private initPanelData(): void {
    this.climatologyDataSource =
      this.widgetPanelService.adaptSecondaryLensDataSource();

    this.panelState = buildWeightWidgetState(
      this.data.isNew,
      this.panelState,
      this.data.widgetConfig
    );

    this.panelState.selectedCategory = this.data.isNew
      ? this.generateDefaultCategory()
      : this.panelState.selectedCategory;

    this.setCorrectLocation();
    this.updateWidgetLocationBasedOnState();

    this.cdr.markForCheck();
  }

  private setPreviousContinentRegion(
    response: IWidgetWeightedLocationData
  ): void {
    if (!response) {
      this.panelState.selectedContinent = this.data.widgetConfig.continent;
      this.panelState.selectedRegionId = this.data.widgetConfig.region;
      this.panelState.selectedRegionName = this.data.widgetConfig.region;
    }
  }

  private saveAgWeightedWidget(details: WeightedWidgetDetails): void {
    if (this.data.isNew) {
      this.dashboardService.createWidget(details, WidgetTypes.weighted);
    }

    return this.dialogRef.close(details);
  }

  private updateWidgetLocationBasedOnState(): void {
    if (this.widgetService.isSameWidgetType(WidgetTypes.weighted)) {
      this.updateLocationForSameWidgetType();
    } else {
      this.updateLocationForDifferentWidgetType();
    }
  }

  private updateLocationForSameWidgetType() {
    const { continent, region } = this.widgetService.widgetLocationState;
    //need to find new region in case user goes between weighted degree days or ISO Country
    //technically the same widget but different regions
    this.getWeightedRegions(continent, this.getCurrentCategory())
      .pipe(first())
      .subscribe((regions) => {
        const newRegion = this.getNewRegion(regions, region || '');
        this.updatePanelState(continent, newRegion.id, newRegion.text);
      });
  }

  private updateLocationForDifferentWidgetType() {
    if (!this.widgetService.widgetLocationState) {
      return;
    }
    const { continent, region } = this.widgetService.widgetLocationState;
    if (!['EUR', 'NA'].includes(continent)) {
      return;
    }

    this.getWeightedRegions(continent, this.getCurrentCategory())
      .pipe(first())
      .subscribe((regions) => {
        const newRegion = this.getNewRegion(regions, region || '');
        this.updatePanelState(continent, newRegion.id, newRegion.text);
      });
  }

  private updatePanelState(
    continent: string,
    regionId: string,
    regionName?: string
  ): void {
    this.panelState = {
      ...this.panelState,
      selectedContinent:
        this.widgetPanelService.getContinentName(continent) || '',
      selectedRegionId: regionId,
      selectedRegionName: regionName || regionId,
    };
    this.cdr.markForCheck();
  }

  private getNewRegion(regions: any, region: string) {
    const foundRegion = regions.find((reg: any) => reg.id === region);
    return foundRegion ? foundRegion : regions[0];
  }
  private watchForChangeTempUnit(): void {
    this.userSettings.selectedTempUnit$
      .pipe(first())
      .subscribe((unit: string) => {
        this.tempUnit = unit;
      });
  }

  private initRunTimeDataSource(
    runTimeModel?: string | null,
    category?: string
  ): void {
    const selectedContinent = this.widgetPanelService.getContinentByName(
      this.panelState.selectedContinent
    );

    this.runTimeDataSource$ = this.widgetPanelService
      .getRunTimeDataSource(
        runTimeModel ?? this.panelState.selectedRunTimeModel,
        category ?? this.panelState.selectedCategory,
        selectedContinent?.id || 'NA'
      )
      .pipe(
        map((source: InitDataSources[]) => {
          return prepareDataSource(source);
        }),
        tap((source: DropdownOption[]) => {
          this.populateRunTimeData(source);
        })
      );
  }

  private initParameterDataSource(
    category?: string | null,
    continent?: string | null,
    categoryChanged?: boolean
  ): void {
    const selectedContinentId = this.widgetPanelService.getContinentByName(
      this.panelState.selectedContinent ?? this.data.widgetConfig.continent
    )?.id as string;

    const dataSources =
      (category ?? this.panelState.selectedCategory) === CategoryValues.degree
        ? this.weightedServices
            .getWeightedDegreeModelForecastDropdown(
              continent ?? selectedContinentId
            )
            .pipe(
              map((source: InitDataSources[]) => {
                return prepareDataSource(source);
              })
            )
        : this.getISOWeightedForecastDropdown(continent ?? selectedContinentId);

    this.parameterDataSource$ = dataSources.pipe(
      tap((source: DropdownOption[]) => {
        this.populateParameterValue(source, categoryChanged);
      })
    );

    this.hasMultipleParameters$ = this.parameterDataSource$.pipe(
      map((dataSource) => !!dataSource && dataSource.length > 1)
    );
  }

  private getISOWeightedForecastDropdown(continent: string) {
    return continent === continents.NORTH_AMERICA
      ? of(ParameterDataSourceList)
      : of(EURParameterDataSourceList);
  }

  private populateRunTimeData(source: DropdownOption[]): void {
    if (
      this.data.isNew ||
      (!this.data.isNew && !this.panelState.selectedRunTimeData)
    ) {
      this.panelState.selectedRunTimeData = lodashFirst(source)
        ?.value as string;
    }
  }

  private populateParameterValue(
    source: DropdownOption[],
    categoryChanged?: boolean
  ): void {
    if (
      this.data.isNew ||
      (!this.data.isNew && !this.panelState.selectedParameterValue) ||
      (!this.data.isNew && categoryChanged) ||
      !source.some(
        (item: DropdownOption) =>
          item.value === this.panelState.selectedParameterValue
      )
    ) {
      this.panelState.selectedParameterValue = lodashFirst(source)
        ?.value as string;
    }
  }

  private setCorrectLocation(categoryChanged?: boolean): void {
    if (!this.data.widgetId || categoryChanged) {
      this.panelState = {
        ...this.panelState,
        selectedRegionId:
          DefaultLocation[this.panelState.selectedCategory][
            this.panelState.selectedContinent
          ],
        selectedRegionName:
          DefaultLocation[this.panelState.selectedCategory][
            this.panelState.selectedContinent
          ],
      };
    }
  }
  private getWeightedRegions(continentId: string, filter: string) {
    return this.widgetPanelService.getWeightedRegions(continentId, filter);
  }
}
