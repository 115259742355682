import { EntityState } from '@ngrx/entity';
import {
  ModelConfig,
  ModelFilterConfig,
  ModelRun,
  PanelData,
  SelectedConfigs,
} from '../models/model-page.types';

interface LoadingStatus {
  loaded: boolean;
  error?: string | null;
}

export interface EntityStateWithLoadingStatus<T>
  extends EntityState<T>,
    LoadingStatus {}

export interface PeriodMatrixData {
  selectedConfigs: SelectedConfigs | null;
  main: EntityState<PanelData>;
  diff: EntityState<PanelData>;
  configs: EntityStateWithLoadingStatus<ModelFilterConfig>;
  runs: EntityStateWithLoadingStatus<ModelRun>;
  loaded: boolean;
  inUse: boolean;
  selectedTimeDiff: string;
  error?: string | null;
}

export interface ComparisonData {
  selectedConfigs: SelectedConfigs | null;
  main: EntityState<PanelData>; // rename to data
  configs: EntityStateWithLoadingStatus<ModelFilterConfig>;
  runs: EntityStateWithLoadingStatus<ModelRun>;
  alwaysShowLatest: boolean;
  loaded: boolean;
  inUse: boolean;
  error?: string | null;
}

export interface MultiPanelData {
  selectedConfigs: ModelConfig[];
  data: EntityState<PanelData>;
  filterConfigs: EntityStateWithLoadingStatus<ModelFilterConfig>;
  runs: EntityStateWithLoadingStatus<ModelRun>;
  loaded: boolean;
  error?: string | null;
  inUse: boolean;
}

export interface SinglePanelData {
  selectedConfig: ModelConfig | null;
  data: EntityState<PanelData>;
  filterConfigs: EntityStateWithLoadingStatus<ModelFilterConfig>;
  runs: EntityStateWithLoadingStatus<ModelRun>;
  loaded: boolean;
  imagesLoaded: boolean;
  error?: string | null;
  inUse: boolean;
}

export interface ModelStoreState {
  periodMatrixData: PeriodMatrixData;
  comparisonData: ComparisonData;
  multiPanelData: MultiPanelData;
  singlePanelData: SinglePanelData;
}
