import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as compareLocation from './+state/comp-location/compare-location.reducer';
import { CitiesService } from '@firebird-web/cities-store';
import { RunDatesService } from '@firebird-web/run-dates-store';
import { CompareLocationEffects } from './+state/comp-location/comp-location.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(
      compareLocation.COMPARE_LOCATION_DATA,
      compareLocation.reducer
    ),
    EffectsModule.forFeature([CompareLocationEffects]),
  ],
  providers: [CitiesService, RunDatesService],
})
export class CompLocationStoreModule {}
