import { createAction, props } from '@ngrx/store';
import { RunDatesEntity } from './run-dates.models';

export const loadRunDatesSuccess = createAction(
  '[RunDates Store] Load RunDates Success',
  props<{ runDates: RunDatesEntity[] }>()
);

export const clearRunDate = createAction('[RunDates Store] Clear run dates');

export const loadRunDatesStart = createAction(
  '[RunDates Store] Load RunDates Start',
  props<{ continent: string; customListContinent?: string }>()
);

export const loadRunDatesFailure = createAction(
  '[RunDates Store] Load RunDates Failure',
  props<{ error: any }>()
);
