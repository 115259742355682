import { createAction, props } from '@ngrx/store';
import { IHourlyForecastRunDatesEntity } from './hourly-forecast-run-dates.models';

export const loadHourlyForecastRunDatesSuccess = createAction(
  '[HourlyForecastRunDates Store] Load HourlyForecastRunDates Success',
  props<{ hourlyForecastRunDates: IHourlyForecastRunDatesEntity[] }>()
);

export const loadHourlyForecastRunDatesFailure = createAction(
  '[HourlyForecastRunDates Store] Load HourlyForecastRunDates Failure',
  props<{ error: any }>()
);
