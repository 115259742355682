import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { CitiesService } from '../../services/cities.service';
import * as CitiesActions from './cities.actions';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
} from 'rxjs';
import { CitiesEntity } from './cities.models';
import * as LocationPickerActions from '@firebird-web/location-picker-store';
import { isEqual } from 'lodash';

@Injectable()
export class CitiesEffects {
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        LocationPickerActions.saveDataForRegion,
        LocationPickerActions.saveDataForCity
      ),
      map(({ location }) => location),
      filter(
        (location): location is any =>
          !!location?.continent && !!location?.region
      ),
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
      switchMap((location) =>
        // TODO: (refactoring) do no trigger for all application (some users don't have access to cities endpoint)
        this.citiesService.getCitiesList(
          // TODO: fix types
          location.continent as string,
          location.region as string
        )
      ),
      switchMap((cities: CitiesEntity[]) => {
        return [CitiesActions.loadCitiesSuccess({ cities })];
      }),
      catchError((error) => of(CitiesActions.loadCitiesFailure(error)))
    )
  );

  loadCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CitiesActions.loadCities),
      filter(({ continent, region }) => !!continent && !!region),
      switchMap(({ continent, region }) =>
        this.citiesService.getCitiesList(continent as string, region as string)
      ),
      switchMap((cities: CitiesEntity[]) => {
        return [CitiesActions.loadCitiesSuccess({ cities })];
      }),
      catchError((error) => of(CitiesActions.loadCitiesFailure(error)))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly citiesService: CitiesService
  ) {}
}
