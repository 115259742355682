export const XAxisConstants: Highcharts.XAxisOptions = {
  ordinal: false,
  minTickInterval: 4 * 60 * 60 * 1000,
  lineColor: '#D9D9D9',
  lineWidth: 1,
  labels: {
    style: {
      fontWeight: 'normal',
      color: '#000',
      fontSize: '10px',
    },
  },
};

export const YAxisConstants: Highcharts.YAxisOptions = {
  opposite: false,
  labels: {
    style: {
      fontSize: '10px',
      fontWeight: '500',
      color: '#000',
    },
    formatter: ({ value }) => {
      const axisValue = value as number;
      if (axisValue >= 1000) {
        return `${axisValue / 1000}k`;
      }
      return axisValue.toString();
    },
  },
  title: {
    text: 'Megawatts',
    align: 'middle',
    rotation: 270,
    margin: 20,
    style: {
      fontSize: '10px',
      color: '#000',
      fontWeight: '400',
    },
  },
};

export const chartToolTipConstants: Highcharts.TooltipOptions = {
  style: {
    color: '#000',
    fontWeight: '400',
    fontSize: '10px',
  },
};

export const LoadLegendsToKeep = [
  'AG2 Latest',
  'AG2 Morning',
  'AG2 Previous Morning',
  'OBS',
  '5min OBS',
];

export const ErcotLoadLegendsToKeep = [
  'AG2 Latest',
  'AG2 Morning',
  'AG2 Previous Morning',
  'OBS',
  '5min OBS',
  'AG2 Net Load Latest',
  'AG2 Net Load Morning',
  'AG2 Net Load Previous Morning',
  'ERCOT Net Load Latest',
  'ERCOT Net Load Morning',
  'ERCOT Net Load Previous Morning',
  'OBS Net Load',
];
