export interface HistoricalPopup {
  maxPrecip: number;
  maxPrecipYear: number;
  maxTempPrecentile: number;
  minTempPrecentile: number;
  forecastMaxTemp: number;
  forecastMinTemp: number;
  forecastPrecip: number;
  precipPercentile: number;
  recordHighMax: number;
  recordHighMaxYear: number;
  recordHighMin: number;
  recordHighMinYear: number;
  recordLowMax: number;
  recordLowMaxYear: number;
  recordLowMin: number;
  recordLowMinYear: number;
  validDate: string;
}

export interface GetDailyForecastValueHistoricalDataReq {
  runDate: string;
  validDate: string;
  siteId: string;
  tempUnit: string;
  precipUnit: string;
  windUnit: string;
}
