import { Action, createReducer, on } from '@ngrx/store';
import * as compareLocationActions from './comp-location.actions';
import { RunDatesEntity } from '@firebird-web/run-dates-store';
import { CitiesEntity } from '@firebird-web/cities-store';

export interface CompareLocationState {
  runDates: RunDatesEntity[];
  cities: CitiesEntity[];
}

export const COMPARE_LOCATION_DATA = 'compareLocationData';

export const initialState: CompareLocationState = {
  runDates: [],
  cities: [],
};

export interface CompareLocationStatePartialState {
  readonly [COMPARE_LOCATION_DATA]: CompareLocationState;
}

export const reducer = createReducer(
  initialState,
  on(compareLocationActions.updateRunDates, (state, { runDates }) => ({
    ...state,
    runDates,
  })),
  on(compareLocationActions.updateCities, (state, { cities }) => ({
    ...state,
    cities,
  }))
);

export function compareLocationDataReducer(
  state: CompareLocationState | undefined,
  action: Action
) {
  return reducer(state, action);
}
