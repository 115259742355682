import {
  IWidgetLocationState,
  IWidgetModelConfig,
  WIDGET_CONTINENT_TO_MODEL_ID,
  WidgetSizes,
} from '../../constants';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardService } from '../../services/dashboard.service';
import {
  ModelConfig,
  ModelFilterConfig,
  ModelView,
  changeSinglePanelConfigs,
  getSinglePanelConfigs,
  getSinglePanelSelectedConfig,
  loadSinglePanelConfigs,
} from '@firebird-web/model-store';
import { Store } from '@ngrx/store';
import { ISingleModelWidgetData, WidgetTypes } from '../../constants';
import { WidgetService } from '../../services/widget.service';
import { WidgetApiService } from '../../services/widget-api.service';
import { combineLatest, filter, first, Subject, tap } from 'rxjs';

@Component({
  selector: 'firebird-web-model-panel',
  templateUrl: './model-panel.component.html',
  styleUrls: ['./model-panel.component.scss'],
})
export class ModelPanelComponent implements OnInit, OnDestroy {
  selectedSize: WidgetSizes = WidgetSizes.half;
  filterConfigs$ = this.store.select(getSinglePanelConfigs);
  widgetId: string;
  destroy$ = new Subject();
  modelConfig: ModelConfig | null = null;
  currentConfig: IWidgetModelConfig | null = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISingleModelWidgetData,
    private dialogRef: MatDialogRef<ModelPanelComponent>,
    private dashboard: DashboardService,
    private widgetApiService: WidgetApiService,
    private widgetServie: WidgetService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.widgetId = this.data?.widgetId;
    this.loadWidgetConfigs();
    this.handleWidgetParamPersitance();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  onClose() {
    this.dialogRef.close();
  }
  filterChange(selectedConfig: ModelConfig) {
    this.widgetApiService
      .getMostUpToDateModelRun(
        selectedConfig.model.model,
        selectedConfig.parameter.id,
        selectedConfig.model.period
      )
      .pipe(first())
      .subscribe((run) => (selectedConfig.run = run));
    this.store.dispatch(
      changeSinglePanelConfigs({
        selectedConfig,
      })
    );

    this.modelConfig = selectedConfig;
  }

  onSave() {
    if (!this.modelConfig) {
      return;
    }
    const widgetModelConfig: IWidgetModelConfig = {
      w: 4,
      h: 33,
      ...this.modelConfig,
      model: {
        group: this.modelConfig.model.group,
        model: this.modelConfig.model.model,
        period: this.modelConfig.model.period,
        id: this.modelConfig.model.id,
        title: this.modelConfig.model.title,
        intervals: this.modelConfig?.model?.intervals ?? [],
      },
      view: {
        id: this.modelConfig.view.id,
        title: this.modelConfig.view.title,
      },
    };
    this.widgetServie.updateWidgetLocationState({
      modelConfig: widgetModelConfig,
      type: WidgetTypes.model,
      continent: this.modelConfig.view.id,
    });
    if (this.data.isNew) {
      // pull out only needed configs for the widget to not bloat the storage
      this.dashboard.createWidget(widgetModelConfig, WidgetTypes.model);
      this.widgetServie.updateWidgetById(this.widgetId, widgetModelConfig);
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    } else {
      this.widgetServie.updateWidgetById(this.data.widgetId, widgetModelConfig);
      this.filterChange(this.modelConfig);
      this.dashboard.saveDashboard();
      this.dialogRef.close();
    }
  }

  private handleNonModelWidget(
    widgetLocationDetails: IWidgetLocationState
  ): void {
    combineLatest([
      this.store.select(getSinglePanelSelectedConfig).pipe(
        filter((config) => !!config),
        tap((config) => (this.currentConfig = config))
      ),
      this.store.select(getSinglePanelConfigs),
    ])
      .pipe(first())
      .subscribe(([selectedConfig, allConfigs]) => {
        this.updateWidgetConfig(
          widgetLocationDetails,
          selectedConfig,
          allConfigs
        );
      });
  }

  private updateWidgetConfig(
    widgetLocationDetails: IWidgetLocationState,
    selectedConfig: IWidgetModelConfig | null,
    allConfigs: ModelFilterConfig[]
  ): void {
    if (!selectedConfig) {
      return;
    }
    const { continent } = widgetLocationDetails;
    const mappedLocId = this.mapWidgetLocationToModelConfig(continent);
    const foundModel = allConfigs.find(
      (model) => model.id === selectedConfig.model.id
    );
    const foundView = this.findCorrectViewBasedOfId(
      mappedLocId,
      foundModel?.views || []
    );

    if (foundView) {
      selectedConfig.view = foundView;
      this.widgetServie.updateWidgetById(this.widgetId, selectedConfig);
    }
  }

  private findCorrectViewBasedOfId(contId: string, views: ModelView[]) {
    return views.find((view) => view.id === contId);
  }

  //ids are different for models than other widgets must map to model ids
  private mapWidgetLocationToModelConfig(widgetContinent: string) {
    return WIDGET_CONTINENT_TO_MODEL_ID[widgetContinent] || 'NA';
  }

  private handleSameWidgetType(selectedConfig: IWidgetModelConfig) {
    this.widgetServie.updateWidgetById(this.widgetId, selectedConfig);
  }

  private loadWidgetConfigs() {
    if (this.data.isNew) {
      this.store.dispatch(loadSinglePanelConfigs());
    } else {
      const selectedConfig: ModelConfig = this.data.widgetConfig;
      this.store.dispatch(changeSinglePanelConfigs({ selectedConfig }));
      this.store.dispatch(loadSinglePanelConfigs()); //means widget is being edited;
    }
  }

  private handleWidgetParamPersitance() {
    if (!this.data.isNew) {
      return;
    }
    const widgetLocationDetails = this.widgetServie.widgetLocationState;
    if (!widgetLocationDetails) {
      this.store.dispatch(loadSinglePanelConfigs());
      return;
    }
    if (widgetLocationDetails.type === WidgetTypes.model) {
      const selectedConfig = widgetLocationDetails.modelConfig;

      if (selectedConfig && selectedConfig.model) {
        this.handleSameWidgetType(selectedConfig as ModelConfig);
      } else {
        this.store.dispatch(loadSinglePanelConfigs());
      }
    } else {
      this.handleNonModelWidget(widgetLocationDetails);
    }
  }
}
