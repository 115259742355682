import { Component, Input } from '@angular/core';

@Component({
  selector: 'firebird-web-input-pwd',
  templateUrl: './input-pwd.component.html',
  styleUrls: ['./input-pwd.component.scss'],
})
export class InputPwdComponent {
  @Input()
  formControlName!: string;

  @Input()
  id!: string;

  @Input()
  name!: string;

  @Input()
  labelKey!: string;

  @Input()
  placeholderKey!: string;

  @Input()
  disabled!: boolean;

  @Input()
  maxlength!: number;

  public showPassword = false;

  public togglePasswordDisplay() {
    this.showPassword = !this.showPassword;
  }
}
