import { DIALOG_DATA } from '@angular/cdk/dialog';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
  combineLatest,
  first,
  map,
  Observable,
  OperatorFunction,
  tap,
} from 'rxjs';
import { DialogComponent, IDialogInput } from '@firebird-web/shared-ui';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ContinentsEntity,
  getAllContinents,
} from '@firebird-web/continents-store';
import {
  createList,
  getContinentsRegions as getRegions,
  saveList,
  setSelectedContinent,
} from '@firebird-web/custom-locations-store';
import { getQueryParam } from '@firebird-web/route-store';
import { MatMenuTrigger } from '@angular/material/menu';
import { CreateListState } from './interfaces';
import { CreateListService } from './services';
import { CustomLocationsService } from 'libs/custom-locations-store/src/lib/services/custom-locations.service';
import { FavoriteList } from 'libs/custom-locations-store/src/lib/models/favorite-locations.types';
import { CommonUtils } from '@firebird-web/shared-utils';
import { ALL_CONTINENTS } from './constants/create-list.constant';

type City = any;
@Component({
  selector: 'firebird-web-create-list',
  templateUrl: './create-list.component.html',
  styleUrls: ['./create-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateListComponent implements OnInit {
  public favorite: City[] = [];
  public list: Partial<FavoriteList> = {};
  public initialList: Partial<FavoriteList> = {};
  public regions$ = this.store.select(getRegions).pipe(
    map((regions) =>
      regions.map((region) => {
        const { regionName, cities } = region;

        return {
          ...region,
          regionName: regionName.toUpperCase(),
          cities: this.buildCitiesList(cities),
        };
      })
    ),
    tap((regions) => {
      this.allCityList = regions.flatMap(({ cities }) => cities);
      this.listsState = {
        ...this.listsState,
        regionExpansionPanelState: regions.reduce((acc, { regionName }) => {
          acc[regionName] = this.listsState.regionExpansionPanelState
            ? this.listsState.regionExpansionPanelState[regionName]
            : false;

          return acc;
        }, {}),
      };
    })
  );

  public continents$ = this.store
    .select(getAllContinents)
    .pipe(this.assignDefaultContinent());
  public selectedContinent: any | undefined;
  public isNameAlreadyExists = false;
  public isErrorMsgVisible = false;
  public listsState: CreateListState = {
    ctrlButtonPressed: false,
    isSingleDrop: true,
    isSingleDropFavorite: true,
    selectedCityList: new Set(),
    selectedFavoriteCityList: new Set(),
    cityListSelection: null,
    regionExpansionPanelState: null,
  };
  public isSaving = false;

  private allCityList: City[] = [];
  private readonly deleteList: (id: number) => void;
  private readonly copyList: (list: Partial<FavoriteList>) => void;

  @ViewChild('closeTrigger', { static: false }) closeTrigger!: MatMenuTrigger;
  @HostListener('window:click', ['$event'])
  handleClick(event: Event) {
    this.createListService.clearSelectedStateFoOutsideClick(
      event,
      this.listsState
    );
  }

  @HostListener('window:keydown', ['$event'])
  private handleKeyDown(event: KeyboardEvent): void {
    const { key, ctrlKey } = event;

    if (key === 'Control' || ctrlKey) {
      this.listsState = {
        ...this.listsState,
        ctrlButtonPressed: true,
      };
    }

    if (
      !this.listsState.selectedFavoriteCityList.size ||
      this.listsState.selectedFavoriteCityList.size > 1
    ) {
      return;
    }

    this.createListService.sortElementOnArrowButton(
      event,
      [...this.listsState.selectedFavoriteCityList],
      this.favorite
    );
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyUp({ key, ctrlKey }: KeyboardEvent): void {
    if (key === 'Control' || ctrlKey) {
      this.listsState = {
        ...this.listsState,
        ctrlButtonPressed: false,
      };
    }
  }

  public constructor(
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly customLocationsService: CustomLocationsService,
    public dialogRef: MatDialogRef<DialogComponent>,
    private createListService: CreateListService,
    @Inject(DIALOG_DATA)
    {
      context,
    }: IDialogInput<{
      list: FavoriteList;
      deleteList: () => void;
      copyList: (list: Partial<FavoriteList>) => void;
    }>
  ) {
    this.list = context?.list || {};
    this.initialList = { ...context?.list };
    this.deleteList = context?.deleteList || (() => undefined);
    this.copyList = context?.copyList || ((_list) => undefined);
  }

  ngOnInit(): void {
    this.favorite = [...(this.list.sites || [])];
    this.isNameAlreadyExists = false;
    this.isErrorMsgVisible = false;
  }

  assignDefaultContinent(): OperatorFunction<
    ContinentsEntity[],
    any[] // ? continent
  > {
    return (source$: Observable<any[]>) => {
      return combineLatest([
        this.store.select(getQueryParam('continent')),
        source$,
      ]).pipe(
        tap(([selectedContinent, continents]) => {
          const continent =
            continents.find(
              ({ continent }) => selectedContinent === continent
            ) || continents.find(({ continent }) => 'NA' === continent);
          const defaultContinent = continents.find(
            (continent) => continent.isPermitted
          );
          this.changeContinent(continent || defaultContinent || ALL_CONTINENTS);
        }),
        map(([, continents]) => continents),
        map((continents) => [...continents, ALL_CONTINENTS])
      );
    };
  }

  changeContinent(continent: ContinentsEntity) {
    this.selectedContinent = continent;
    const selectedContinent = {
      continent: continent.id || 'All',
      continentName: continent.text,
    };
    this.store.dispatch(
      setSelectedContinent({ selectedContinent: selectedContinent })
    );
  }

  getErrorMsg() {
    if (!this.list.listName) {
      return 'emptyListNameErrorMsg';
    }
    if (!this.favorite?.length) {
      return 'emptyListErrorMsg';
    }
    if (this.isNameAlreadyExists) {
      return 'duplicateErrorMsg';
    }
    return '';
  }

  onListDelete($event: number) {
    this.deleteList($event);
    this.dialogRef.close();
  }

  onListCopy(event: Partial<FavoriteList>) {
    this.copyList(event);
  }

  dragStart(city: City): void {
    this.listsState = {
      ...this.listsState,
      isSingleDrop: !this.listsState.selectedCityList.has(city),
      isSingleDropFavorite: !this.listsState.selectedFavoriteCityList.has(city),
    };
  }

  drop(event: CdkDragDrop<City[]>): void {
    this.isErrorMsgVisible = false;
    if (event.previousContainer === event.container) {
      this.createListService.sortItems(
        event,
        this.listsState.isSingleDropFavorite,
        [...this.listsState.selectedFavoriteCityList]
      );
      return;
    }

    if (event.isPointerOverContainer) {
      this.createListService.updateCityListAfterDrop(
        event,
        this.listsState.isSingleDrop,
        this.listsState.selectedCityList
      );
    }
  }

  dropBack(event: CdkDragDrop<City[]>): void {
    if (
      event.previousContainer === event.container &&
      !event.isPointerOverContainer
    ) {
      return;
    }
    const dropFromData = event.previousContainer.data;
    const dropIndex = event.previousIndex;

    if (this.listsState.isSingleDropFavorite) {
      dropFromData.splice(dropIndex, 1);
      return;
    }

    this.createListService.removeElementsAfterDrop(
      event.previousContainer.data,
      this.listsState.selectedFavoriteCityList
    );
  }

  isFavorite(city: City): boolean {
    return this.favorite.some(
      (favoriteCity) => favoriteCity.siteId === city.siteId
    );
  }

  isHighlighted(city: City): boolean {
    return this.listsState.selectedCityList.has(city);
  }

  isFavoriteHighlighted(city: City): boolean {
    return this.listsState.selectedFavoriteCityList.has(city);
  }

  public toggleExpansionPanelState(regionName: string): void {
    this.listsState = {
      ...this.listsState,
      regionExpansionPanelState: {
        ...this.listsState.regionExpansionPanelState,
        [regionName]: this.listsState.regionExpansionPanelState
          ? !this.listsState.regionExpansionPanelState[regionName]
          : false,
      },
    };
  }

  public selectCity({ shiftKey }: MouseEvent, city: City): void {
    if (this.isFavorite(city)) {
      return;
    }

    if (shiftKey) {
      this.selectMultipleCities(city);

      return;
    }

    if (this.listsState.selectedCityList.has(city)) {
      this.listsState.selectedCityList.delete(city);
      return;
    }

    if (this.listsState.ctrlButtonPressed) {
      this.listsState.selectedCityList.add(city);
      return;
    }

    this.listsState.selectedCityList.clear();
    this.listsState.selectedCityList.add(city);
    this.setCityListSelection('start', city);
  }

  public selectFavoriteCity({ shiftKey }: MouseEvent, city: City): void {
    if (shiftKey) {
      this.selectMultipleFavoriteCities(city);

      return;
    }

    if (this.listsState.selectedFavoriteCityList.has(city)) {
      this.listsState.selectedFavoriteCityList.delete(city);
      return;
    }

    if (this.listsState.ctrlButtonPressed) {
      this.listsState.selectedFavoriteCityList.add(city);
      return;
    }

    this.listsState.selectedFavoriteCityList.clear();
    this.listsState.selectedFavoriteCityList.add(city);
    this.setCityListSelection('start', city);
  }

  onClose() {
    const isListNameChanged = this.list.listName !== this.initialList.listName;
    const isListChanged =
      this.initialList.sites?.length !== this.favorite.length ||
      this.favorite.reduce((acc, { siteId }, index) => {
        return acc || this.initialList.sites?.[index]?.siteId !== siteId;
      }, false);
    if (isListNameChanged || isListChanged) {
      this.closeTrigger.openMenu();
      return;
    }
    this.dialogRef.close({
      ...this.list,
      cities: this.favorite,
    });
  }

  onSave() {
    if (!this.list.listName || !this.favorite?.length) {
      this.isErrorMsgVisible = true;
      return;
    }
    if (
      this.initialList?.id &&
      this.initialList?.listName === this.list?.listName
    ) {
      this.save();
    }
    this.customLocationsService
      .isFavoriteListUnique(this.list?.listName || '')
      .pipe(
        first(),
        tap(() => {
          this.isSaving = true;
          this.cdr.markForCheck();
        })
      )
      .subscribe({
        next: (isNotUnique) => {
          this.isNameAlreadyExists = isNotUnique;
          this.isErrorMsgVisible = isNotUnique;

          if (!isNotUnique) {
            this.save();
          }

          this.isSaving = false;
          this.cdr.markForCheck();
        },
      });
  }

  save() {
    const sites = this.favorite.map((city, index) => ({
      siteId: city.siteId,
      listOrder: index,
      isActive: true,
    }));
    const list = {
      ...this.list,
      sites,
    };
    const action = this.list?.id ? saveList({ list }) : createList({ list });
    this.store.dispatch(action);
    this.dialogRef.close({
      ...this.list,
      cities: this.favorite,
    });
  }

  public addToFavorites(city?: City): void {
    this.isErrorMsgVisible = false;

    if (
      (!this.listsState.selectedCityList.size && !city) ||
      this.favorite.includes(city)
    ) {
      return;
    }

    if (city) {
      this.favorite.push(city);
      this.listsState.selectedCityList.delete(city);
      return;
    }

    [...this.listsState.selectedCityList].forEach((city: City) => {
      this.favorite.push(city);
    });

    this.listsState.selectedCityList.clear();
  }

  public removeFromFavorites(city?: City): void {
    this.isErrorMsgVisible = false;
    if (!this.listsState.selectedFavoriteCityList.size && !city) {
      return;
    }

    if (city) {
      this.favorite = this.favorite.filter(
        ({ siteId }) => city.siteId !== siteId
      ); // * mutate here
      this.listsState.selectedFavoriteCityList.delete(city);
      return;
    }

    this.favorite = this.favorite.filter((city: City) => {
      return !this.listsState.selectedFavoriteCityList.has(city);
    }); // * mutate here
    this.listsState.selectedFavoriteCityList.clear();
  }

  private buildCitiesList(cities: City[]): City[] {
    const isAggregateCity = 0;
    const aggregated = cities.filter(
      (city: City) => city.isSIte === isAggregateCity
    );
    const defaultCities = cities.filter(
      (city: City) => city.isSIte !== isAggregateCity
    );

    return [
      ...CommonUtils.sortCityByName(aggregated, 'displayName'),
      ...CommonUtils.sortCityByName(defaultCities, 'displayName'),
    ];
  }

  private selectMultipleCities(city: City): void {
    if (
      !this.listsState.cityListSelection ||
      !this.listsState.regionExpansionPanelState
    ) {
      return;
    }

    this.listsState.selectedCityList.clear();
    this.setCityListSelection('end', city);

    for (const selectedCity of this.createCitySelection(
      this.allCityList,
      city
    )) {
      if (
        this.listsState.regionExpansionPanelState &&
        this.listsState.regionExpansionPanelState[selectedCity.regionName]
      ) {
        this.listsState.selectedCityList.add(selectedCity);
      }
    }
  }

  private selectMultipleFavoriteCities(city: City): void {
    if (!this.listsState.cityListSelection) {
      return;
    }

    this.listsState.selectedFavoriteCityList.clear();
    this.setCityListSelection('end', city);

    for (const selectedCity of this.createCitySelection(this.favorite, city)) {
      this.listsState.selectedFavoriteCityList.add(selectedCity);
    }
  }

  private createCitySelection(cities: City[], selectedCity: City[]): City[] {
    if (!this.listsState.cityListSelection) {
      return [];
    }

    const startIndex = cities.indexOf(this.listsState.cityListSelection.start);
    const endIndex = cities.indexOf(selectedCity);
    const sliceStart = Math.min(startIndex, endIndex);
    const sliceEnd = Math.max(startIndex, endIndex) + 1;

    return cities.slice(sliceStart, sliceEnd);
  }

  private setCityListSelection(key: 'start' | 'end', city: City): void {
    this.listsState = {
      ...this.listsState,
      cityListSelection: {
        ...this.listsState.cityListSelection,
        [key]: city,
      },
    };
  }
}
