<ng-container
  *ngIf="entityConfigList$ | async as entityConfigList; else loader"
>
  <div
    class="entity-block"
    *ngFor="let entityConfig of entityConfigList; trackBy: trackByEntity"
  >
    <h3>{{ entityConfig.title | translate }}</h3>
    <p>{{ entityConfig.description | translate }}</p>
    <div class="columns">
      <firebird-web-enhanced-data-services-access-label
        [access]="entityConfig.access"
        [subEntitlements]="entityConfig.subEntitlements"
      ></firebird-web-enhanced-data-services-access-label>
      <firebird-web-enhanced-data-services-documentation-list
        [documentationList]="entityConfig.documentationList"
      ></firebird-web-enhanced-data-services-documentation-list>
      <firebird-web-enhanced-data-services-api-key
        [entity]="entityConfig.entity"
        [access]="entityConfig.access"
        [apiKeys]="entityConfig.apiKeys"
      ></firebird-web-enhanced-data-services-api-key>
    </div>
  </div>
</ng-container>
<ng-template #loader>
  <firebird-web-loader class="loader"></firebird-web-loader>
</ng-template>
