<div class="delimeter"></div>
<div class="alert-dialog component">
  <p class="posted">
    Posted:
    {{ data.context?.updateTime | dateWithTimezone: 'MMM dd, yyyy h:mm a' }}
    by:
    {{ data.context?.forecaster }}
  </p>
  <h2 class="alert-name">
    {{ data.context?.title }}
  </h2>
  <h3 class="section-header">Summary</h3>
  <div class="delimeter"></div>
  <p class="text-body">
    {{ data.context?.summary }}
  </p>
  <h3 class="section-header">Technical Analysis</h3>
  <div class="delimeter"></div>
  <p class="text-body">
    {{ data.context?.analysis }}
  </p>
</div>
