import { ISumTableConfigOptions } from '../../constants';
import { RunDatesEntity } from '@firebird-web/run-dates-store';

export const primaryLensOptions: ISumTableConfigOptions[] = [
  { id: 'Forecast', text: 'Forecast Values' },
  { id: 'ForecastDifference', text: 'Forecast Differences' },
  { id: 'DepartureFromNorms', text: 'Departure From Norms' },
  { id: 'FriskIndex', text: 'FRisk Index' },
];
export const secondaryLensOptions: ISumTableConfigOptions[] = [
  { id: 'AvgYrThirty', text: 'vs 30yr Avg' },
  { id: 'AvgYrTen', text: 'vs 10yr Avg' },
  { id: 'LastYear', text: 'vs Last Year Obs' },
];

export const tempForecastTypeFilters: ISumTableConfigOptions[] = [
  { id: 'MinMax', text: 'Min/Max' },
  { id: 'Peak', text: 'Off-peak min/On-peak max' },
  { id: 'AverageTemp', text: 'Average' },
  { id: 'DegreeDays', text: 'HDD/CDD' },
  { id: 'GasDay', text: 'Gas Day' },
  { id: 'MinMaxFeelsLike', text: 'Min/Max Feels like' },
  { id: 'MaxDewpoint', text: 'Max Dew Point' },
  { id: 'precipAmount', text: 'Precip Amount' },
  { id: 'probabilityOfPrecip', text: 'Probability of Precip' },
];
export const continentList: ISumTableConfigOptions[] = [
  { text: 'North America', id: 'NA' },
  { text: 'Europe', id: 'EUR' },
  { text: 'Asia', id: 'ASIA' },
  { text: 'Australia', id: 'AUS' },
  { text: 'Mexico', id: 'MEX' },
];

export const paramsObject = {
  filter: '',
  region: '',
  primaryLens: '',
  secondaryLens: '',
  forecastType: '',
  tempUnit: '',
  precipUnit: '',
  windUnit: '',
  continent: '',
  duration: '',
  runDates: [] as RunDatesEntity[],
  runDate: '',
  compareRunDate: '',
  storageTime: '',
  customListId: '',
  listName: '',
  type: '',
  selectedContinents: [] as string[],
};
