import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, map, Observable } from 'rxjs';
import { environment } from '@firebird-web/shared-config';
import { CommonUtils } from '@firebird-web/shared-utils';
import {
  MultiplePermissions,
  Permissions,
  SinglePermission,
} from '@firebird-web/acl';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  usersPermissions: any = {};
  private permissions$: BehaviorSubject<string[] | Record<string, unknown>> =
    new BehaviorSubject(this.usersPermissions);

  constructor(private readonly http: HttpClient) {}

  public initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      const endPointUrl = `${environment.apiDomain}/api/v1/permissions/get-permissions`;
      try {
        firstValueFrom(this.http.get<string[]>(endPointUrl)).then((data) => {
          if (Array.isArray(data)) {
            const splittedPermissions = data.map((item: string) =>
              item
                .split('__')
                .reverse()
                .reduce((acc: Record<string, unknown>, permission: string) => {
                  return {
                    [permission]: Object.keys(acc).length ? { ...acc } : true,
                  };
                }, {})
            );

            const isObject = (item: any) => {
              return item && typeof item === 'object' && !Array.isArray(item);
            };
            const mergeDeep: any = (target: any, ...sources: any[]) => {
              if (!sources.length) return target;
              const source = sources.shift();

              if (isObject(target) && isObject(source)) {
                for (const key in source) {
                  if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    mergeDeep(target[key], source[key]);
                  } else {
                    Object.assign(target, { [key]: source[key] });
                  }
                }
              }
              return mergeDeep(target, ...sources);
            };

            this.usersPermissions = mergeDeep({}, ...splittedPermissions);
            this.permissions$ = new BehaviorSubject(this.usersPermissions);
            resolve();
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching permissions:', error);
        reject();
      }
    });
  }

  getPermissions() {
    return this.usersPermissions || {};
  }

  getPermissionsAsync() {
    return this.permissions$;
  }

  public isViewPermittedAsync(
    permission: Permissions,
    key = 'View'
  ): Observable<boolean> {
    return this.permissions$.pipe(
      map((userPermissions) =>
        this.isPermitted(permission, key, userPermissions)
      )
    );
  }

  public isPermitted(
    permissions: Permissions,
    key = 'View',
    userPermissions = this.getPermissions()
  ): boolean {
    if (!userPermissions?.[key]) {
      return false;
    }

    if (CommonUtils.isMatrix(permissions)) {
      return (permissions as MultiplePermissions).some((permission) =>
        this.isPermissionExist(permission, key, userPermissions)
      );
    }

    return this.isPermissionExist(
      permissions as SinglePermission,
      key,
      userPermissions
    );
  }

  public isPoolSetExist(poolset: string): boolean {
    return !!this.getPermissions().PoolSet?.[poolset];
  }

  private isPermissionExist(
    permissions: SinglePermission,
    key: string,
    userPermissions: Record<string, unknown>
  ): boolean {
    return !!permissions.reduce((acc, item) => {
      if (typeof acc === 'boolean') {
        return acc;
      }

      if (Array.isArray(acc)) {
        return acc.includes(item);
      }

      if (key === 'PoolSet') {
        const keys = Object.keys(acc as Record<string, unknown>);

        return keys
          .filter((k) => k.includes(item))
          .some((k) => (acc as Record<string, unknown>)[k]);
      }

      if (typeof acc === 'object') {
        return acc?.[item as keyof typeof acc] ?? false;
      }

      return acc;
    }, userPermissions?.[key]);
  }

  getFirstAvailableContinent(
    permission: string[],
    key = 'View',
    userPermissions = this.getPermissions()
  ): string {
    const permittedRegionsObg = permission.reduce(
      (acc: unknown, item: string) => {
        return acc?.[item as keyof typeof acc] || ({} as object);
      },
      (userPermissions as Record<string, unknown>)?.[key]
    );

    return Object.keys(permittedRegionsObg as object)[0] ?? '';
  }
}
