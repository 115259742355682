<ng-container
  *ngIf="!isTotalRow && cellValue && !isHourRenderType; else withoutPopover"
>
  <div
    class="heat-map-cell"
    [ngClass]="[
      renderType,
      cellBgColorCode ? cellBgColorCode : '',
      isTotalRow ? 'totalRow' : ''
    ]"
    [firebirdWebPopover]="{
      templateRef: popoverRef,
      data: { type: 'variation-one', trigger: 'mouseover' }
    }"
  >
    <p *ngIf="isHourRenderType && isTotalRow; else withoutTranslate">
      {{ cellText | translate }}
    </p>
    <ng-template #withoutTranslate>
      <p>{{ cellText }}</p>
    </ng-template>
  </div>
</ng-container>

<ng-template #withoutPopover>
  <div
    class="heat-map-cell"
    [ngClass]="[
      renderType,
      cellBgColorCode ? cellBgColorCode : '',
      isTotalRow ? 'totalRow' : ''
    ]"
  >
    <p *ngIf="isHourRenderType && isTotalRow; else withoutTranslate">
      {{ cellText | translate }}
    </p>
    <ng-template #withoutTranslate>
      <p>{{ cellText }}</p>
    </ng-template>
    <div *ngIf="isLastRow && isWeekend" class="weekend-border"></div>
  </div>
</ng-template>

<ng-template #popoverRef>
  <div *ngIf="!isTotalRow" class="popover-wrapper">
    <ng-container
      *ngIf="primaryLens === forecastPrimaryLens; else differenceCapacity"
    >
      Percentage of capacity: {{ percentForecastDiff }} %
    </ng-container>

    <ng-template #differenceCapacity>
      % Forecast Difference: {{ percentForecastDiff }} %
    </ng-template>
  </div>
</ng-template>
