import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as RunDatesSelectors from './run-dates.selectors';
import { filter, map, Observable } from 'rxjs';
import { RunDatesEntity } from '@firebird-web/run-dates-store';

@Injectable()
export class RunDatesFacade {
  constructor(private readonly store: Store) {}

  public getFirstRunDate<T = RunDatesEntity | string>(
    extractId = false
  ): Observable<T> {
    return this.store.pipe(select(RunDatesSelectors.getFirstRunDate)).pipe(
      filter(
        (latestRunDate): latestRunDate is RunDatesEntity => !!latestRunDate
      ),
      map((latestRunDate) =>
        extractId ? latestRunDate.id.toString() : latestRunDate
      )
    ) as Observable<T>;
  }
}
