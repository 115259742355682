import { PeriodStrategies } from './../period-strategies';
import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import {
  BaseInterval,
  IntervalLineData,
  ModelData,
  ModelIntervalResponse,
  ModelRun,
  PeriodTypes,
} from '../models/model-page.types';
import { excludedRuns } from '../constants/constants';

@Injectable()
export class DataHelperService {
  constructor(private readonly datePipe: DatePipe) {}

  public getRun(run: ModelRun, runs: ModelRun[]) {
    const { title: runName, diff } = run;

    switch (true) {
      case runName === 'Latest Run':
        return runs[0];

      case runName === 'Latest Run (00Z/12Z only)':
        return this.findFirst00z12zRun(runs);

      case runName.includes('Latest Run (00Z/12Z only) - '):
        return this.getSpecific00Z12ZRun(runs, diff);

      case runName.includes('Previous Run') || runName.includes('Latest Run -'):
        return this.getRunByAdjustedDate(runs, diff);

      default:
        return runs.find(({ title }) => title.includes(runName));
    }
  }

  private findFirst00z12zRun(runs: ModelRun[]): ModelRun | undefined {
    return runs.find(
      ({ title }) => !excludedRuns.some((excluded) => title.includes(excluded))
    );
  }

  private getSpecific00Z12ZRun(
    runs: ModelRun[],
    diff: number | undefined
  ): ModelRun | undefined {
    const filteredRuns = runs.filter(
      ({ title }) => !excludedRuns.some((excluded) => title.includes(excluded))
    );
    return diff === -12 ? filteredRuns[1] : filteredRuns[2];
  }

  private getRunByAdjustedDate(
    runs: ModelRun[],
    diff: number | undefined
  ): ModelRun | undefined {
    const latestRunDate = new Date(runs[0].id);
    latestRunDate.setHours(latestRunDate.getHours() + (diff || 0));

    const runId = this.datePipe.transform(latestRunDate, 'yyyy-MM-dd HH:mm:ss');
    return runId ? runs.find(({ id }) => id === runId) : runs[0];
  }

  getBaseInterval(existedBaseIntervals: (ModelIntervalResponse | undefined)[]) {
    return existedBaseIntervals.reduce<BaseInterval>(
      (acc, intervalItem) => {
        if (!intervalItem) {
          return acc;
        }
        const start =
          !acc.start || acc.start > intervalItem.start
            ? intervalItem.start
            : acc.start;
        const stop =
          !acc.stop || acc.stop < intervalItem.stop
            ? intervalItem.stop
            : acc.stop;
        const interval =
          !acc.interval || acc.interval < intervalItem.interval
            ? intervalItem.interval
            : acc.interval;

        return { start, stop, interval };
      },
      { start: 0, stop: 0, interval: 0 }
    );
  }

  fillDataGaps(
    data: ModelData[],
    baseInterval?: BaseInterval,
    modelPeriod?: PeriodTypes
  ): ModelData[] {
    if (!baseInterval || ['PERIOD', 'WEEK'].includes(modelPeriod || '')) {
      return data;
    }
    const existedIntervals = data.map(({ interval }) => interval);
    const minInterval = baseInterval?.start ?? Math.min(...existedIntervals);
    const maxInterval = baseInterval?.stop ?? Math.max(...existedIntervals);

    const length = Math.ceil(
      (maxInterval - minInterval) / baseInterval.interval + 1
    );
    const expectedIntervals = Array.from(
      { length },
      (_, i) => i * baseInterval.interval
    );

    return expectedIntervals.map((expectedInterval) => {
      const timePerOrder = data[1]
        ? (data[1].validTime - data[0].validTime) /
          (data[1].interval - data[0].interval)
        : 0;
      const startOrder = data[0].order;

      return (
        data.find(({ interval }) => expectedInterval === interval) ||
        ({
          order: expectedInterval,
          periodType: modelPeriod,
          validPeriod: '',
          interval: expectedInterval,
          validTime:
            data[0].validTime - timePerOrder * (startOrder - expectedInterval),
          isModelInvalid: true,
          pathURL: '',
        } as ModelData)
      );
    });
  }

  getIntervalLineData(
    data: ModelData[][],
    baseInterval: BaseInterval,
    periodConfig?: PeriodStrategies[keyof PeriodStrategies],
    takeForIndex = 1
  ): IntervalLineData[] {
    if (!periodConfig) {
      return [];
    }
    const startTime = Math.min(
      ...data
        .map((i) => i.find(({ validTime }) => !!validTime)?.validTime || 0)
        .filter((validTime) => !!validTime)
    );

    const sortedData = [...data].sort(
      (a, b) =>
        (b.find(({ validTime }) => !!validTime)?.validTime || 0) -
        (a.find(({ validTime }) => !!validTime)?.validTime || 0)
    );

    const intervalLineDataArray = sortedData
      .map((i) => periodConfig.getIntervalData(i, baseInterval))
      .flat()
      .filter(({ validTime }) => !validTime || validTime >= startTime);

    const existedIntervalIds = new Set(
      intervalLineDataArray.map(({ id }) => id)
    );
    return Array.from(existedIntervalIds)
      .sort((a, b) => (a > b ? 1 : -1))
      .filter((_, i) => i % takeForIndex === 0)
      .map((uniqueId) => {
        return intervalLineDataArray
          .filter(({ id }) => id === uniqueId)
          .reduce<IntervalLineData>(
            (acc, item) => {
              const validTime = acc.validTime
                ? Math.max(acc.validTime, item.validTime || 0)
                : item.validTime;

              return {
                id: item.id,
                label: item.label,
                isModelInvalid: item?.isModelInvalid || false,
                disabledLabel: item.disabledLabel && acc.disabledLabel, // &&
                disabledInterval: item.disabledInterval && acc.disabledInterval, // &&
                validTime,
              };
            },
            {
              disabledLabel: true, // true
              disabledInterval: true, // true
            }
          );
      });
  }
}
