import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiEndpoints } from '@firebird-web/shared-constants';
import { environment } from '@firebird-web/shared-config';
import { map, Observable } from 'rxjs';
import { CommonUtils } from '@firebird-web/shared-utils';
import { ServerResponse } from '@firebird-web/shared-interfaces';
import { RunDatesDTO } from '../+state/run-dates/run-dates.models';

@Injectable()
export class RunDatesService {
  constructor(private readonly http: HttpClient) {}

  getRunDatesList(
    continentId: string,
    customListContinent = ''
  ): Observable<RunDatesDTO[]> {
    const endPointUrl = CommonUtils.populateURLTemplate(
      `${environment.apiDomain}/${apiEndpoints.getRunDates}`,
      {
        continentId: encodeURIComponent(continentId),
      }
    );
    let params = new HttpParams();

    if (customListContinent) {
      params = params.set('customListContinent', customListContinent);
    }

    return this.http
      .get<ServerResponse<RunDatesDTO[]>>(endPointUrl, { params })
      .pipe(map(({ data }) => data));
  }
}
